/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { AppImages,checkAuth, constant } from "../../utils/constant";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import Header from "../../views/layouts/header";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import { DragDropContext } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import DeleteConfirmation from "./DeleteConfirmation";
const reorderColumnList = (sourceCol, startIndex, endIndex) => {
    const newTaskIds = Array.from(sourceCol.taskIds);
    const [removed] = newTaskIds.splice(startIndex, 1);

    newTaskIds.splice(endIndex, 0, removed);

    const newColumn = {
        ...sourceCol,
        taskIds: newTaskIds,
    };

    return newColumn;
};

const VehicleLead = (props) => {

    const [isDataUpdating,setIsDataUpdating] = useState(false)
    const [sourceDragecollum,setSourceDragecollum] = useState("")
    const [destinationDragecollum,setDestinationDragecollum] = useState("")
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [id, setId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const initialData = {
        tasks: {},
        columns: {
            maches: { id: "maches", title: "Your Vehicles", taskIds: [] },
            contactmade: {
                id: "contactmade",
                title: "Contact Made",
                taskIds: [],
            },
            qualification: {
                id: "qualification",
                title: "Qualification",
                taskIds: [],
            },
           // incomeid: { id: "incomeid", title: "Income/ID", taskIds: [] },
            approval: { id: "approval", title: "Complete Deal", taskIds: [] },
        },
        columnOrder: [
            "maches",
            "contactmade",
            "qualification",            
            "approval",
        ],
    };

   
    const [state, setState] = useState(initialData);


    // first time load
    useEffect(() => {
        let interval = setTimeout(() => {
            getAllMachLeadData();
        }, 300);
        return () => clearInterval(interval);
    }, []);

    // feth all data
    const getAllMachLeadData = async () => {
        setIsDataUpdating(true) 
        setShimmerEffect("shimmerEffect");

        axios({
            method: "GET",
            url: constant.GETALLMACHVEHICLELEAD,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");              
                setState(JSON.parse(response.data));
                setSourceDragecollum("");
                setDestinationDragecollum("");
                setIsDataUpdating(false);
               
            })
            .catch((error) => {
                console.log(error)
                setShimmerEffect("");
                setIsDataUpdating(false)  
            });
    };

    // update data for changeposition by dragging
    const updateAllLead = async (destinationid,taskids,type,dragid,index) => {
        setShimmerEffect("shimmerEffect");
     
		  axios({
				method: 'POST',
				url: constant.UPDATEALLVEHICLELEAD,
				params: {
					ids: destinationid,
					status: taskids,
					type: type,
					dragid: dragid,
					index: index,
				},
				headers: {
				  Authorization: localStorage.getItem('user_token'),
				},
			  })
			   .then((response) => {                
                
					//  console.log('response ', response);
					if(response.data.status === true){  				
                        getAllMachLeadData();
					}               
				})
				.catch((error) => {                  
					console.log('error call ', error);                  
				});   

    };

    // whne fragging started
    const onDragEnd = (result) => {
        setIsDataUpdating(true) 
        const { destination, source } = result;

        // If user tries to drop in an unknown destination
        if (!destination){
            setIsDataUpdating(false) 
            return;
        } 

        // if the user drags and drops back in the same position
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            setIsDataUpdating(false) 
            return;
        }
        // If the user drops within the same column but in a different positoin
        const sourceCol = state.columns[source.droppableId];
        const destinationCol = state.columns[destination.droppableId];
       
        setSourceDragecollum(sourceCol.id);
        setDestinationDragecollum(destinationCol.id);
        
        if (sourceCol.id === destinationCol.id) {
            const newColumn = reorderColumnList(
                sourceCol,
                source.index,
                destination.index
            );

            const newState = {
                ...state,
                columns: {
                    ...state.columns,
                    [newColumn.id]: newColumn,
                },
            };
            const endTaskIds = Array.from(destinationCol.taskIds);

            // alert("---1---");
            // alert(endTaskIds);
            // alert(destinationCol.id);
            setState(newState);
            
        const draggableId1 = result.draggableId;
        const index =result.destination.index;
            setIsDataUpdating(true)
            updateAllLead(endTaskIds,destinationCol.id,'same',draggableId1,index)
            return;
        }

        // If the user moves from one column to another
        const startTaskIds = Array.from(sourceCol.taskIds);
        const [removed] = startTaskIds.splice(source.index, 1);
        const newStartCol = {
            ...sourceCol,
            taskIds: startTaskIds,
        };

        const endTaskIds = Array.from(destinationCol.taskIds);
        endTaskIds.splice(destination.index, 0, removed);
        const newEndCol = {
            ...destinationCol,
            taskIds: endTaskIds,
        };

        const newState = {
            ...state,
            columns: {
                ...state.columns,
                [newStartCol.id]: newStartCol,
                [newEndCol.id]: newEndCol,
            },
        };
        // alert("----2----");
        // alert(destinationCol.id);
        // alert(endTaskIds);
        updateAllLead(endTaskIds,destinationCol.id,'deffrent',0,0)
        setState(newState);
    };
   
    
       // Handle the displaying of the modal based on type and id
  const showDeleteModal = (id) => {
    setId(id);
    setDisplayConfirmationModal(true);
  };

 // Hide the modal
 const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  //   Handle the actual deletion of the item
  const submitDelete = (id) => {
    
    setIsLoading(true);
    const USERTOKEN = localStorage.getItem("user_token");
    axios({
        method: "GET",
        url: constant.REMOVECRMLEAD,
        headers: {
            Authorization: USERTOKEN,
        },
        params: {
            id: id,
        },
    })
        .then((response) => {
            setIsLoading(false);
            if (response.data.status === true) {
               
                getAllMachLeadData();

                
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setDisplayConfirmationModal(false);
            }
        })
        .catch((error) => {
             console.log("error call ", error);
        });

    setDisplayConfirmationModal(false);
  }

    return (
        <>
            <Header />

            <div className="contentCoverbox newsetfooter_addclass">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="marketplace_search">
                        <>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <div className="DragDropContextDiv">
                                    <div justify="space-between" px="4rem">
                                        {/* <h1>{`DEMO` + JSON.stringify(state?.columnOrder)}</h1> */}

                                        {state?.columnOrder !== null &&
                                            state?.columnOrder !== undefined &&
                                            state?.columnOrder.map(
                                                (columnId) => {
                                                    const column =
                                                        state.columns[columnId];
                                                    const tasks =
                                                        column.taskIds.map(
                                                            (taskId) =>
                                                                state.tasks[
                                                                    taskId
                                                                ]
                                                        );
                                                    return (
                                                        <Column
                                                            key={column.id}
                                                            tasks={tasks}
                                                            column={column}
                                                            isUpdating={isDataUpdating}
                                                            sourceDragecollum={sourceDragecollum}
                                                            destinationDragecollum={destinationDragecollum}
                                                            onSelectItem={(item)=>showDeleteModal(item)}
                                                        />
                                                    );
                                                    
                                                }
                                            )}
                                    </div>
                                </div>
                            </DragDropContext>
                        </>
                    </div>
                </div>
                <Footer />
            </div>
            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal}  id={id}   />
            <ToastContainer />
        </>
    );

    // }
};

export default VehicleLead;

const Column = (props) => {
    return (
        

        <div className={`collumndiv`}> 

            
            <div className="setcolumnTitle">
                <span>{props.column.title}</span>
            </div>
            {/* && (props.sourceDragecollum == props.column.id || props.destinationDragecollum == props.column.id) */}
             <div className={`scrollmaindiv ${(props?.isUpdating ) ? 'custloading' : '' }`}>

            <Droppable droppableId={props.column.id}>
                {(droppableProvided, droppableSnapshot) => (
                    <div
                        px="1.5rem"
                        className="dropebale"
                        flex={1}
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                    >
                        {props.tasks.map((task, index) => (
                            <Draggable
                                isDragDisabled={props?.isUpdating}
                                key={task.id}
                                draggableId={`${task.id}`}
                                index={index}
                               
                            >
                                {(draggableProvided, draggableSnapshot) => (
                                    <div
                                        mb="1rem"
                                        h="700px"
                                        bg="card-bg"
                                        rounded="3px"
                                        p="1.5rem"
                                        outline="2px solid"
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                    >

{task.type === 'cusotmerlead' ? (
           <div className="colSetContent">
                   
           
           {(task.lead.name !== "" && task.lead.name != null ) && ( <><span> Name:</span><p>&nbsp;{task.lead.name}</p> </> )}
           
           {(task.lead.email !== "" && task.lead.email != null) && ( <><span> Email:</span><p>&nbsp;{task.lead.email}</p></> )}

           {(task.lead.phone !== "" && task.lead.phone != null) && ( <><span> Phone:</span><p>&nbsp;{task.lead.phone} </p></> )}
           
           {(task.lead.province !== "" && task.lead.province != null ) && ( <><span> Province:</span><p>&nbsp;{task.lead.province} </p></> )}
          
           {(task.lead.city !== "" && task.lead.city != null) && ( <><span> City:</span><p>&nbsp;{task.lead.city} </p></> )}
          
           {(task.lead.adress !== "" && task.lead.adress != null) && ( <><span> Address:</span><p>&nbsp;{task.lead.adress} </p></> )}
          
           {/* <span> Type:</span><p>&nbsp;Customer</p> */}
           
           
           <Link className="viewbtn" to={`/lead/customers/${task.lead.id}`}>
            View Detail
            </Link>
                    

           {/* {task.status !== "" ? ( <><span> STATUS : </span> <p>{task.status}</p> </> ): (<></>)} */}

            <div className="removeiconsetdrag">
                <button onClick={() => {props.onSelectItem(task?.id)}}>
                <img className="profileimage" src={AppImages.removecross.default }/>
                </button>
            </div>
                      
       </div>

          ) : (
           
            <div className="colSetContent">
            {/* <span> ID : </span> {task.id}
            <br /> */}
           
           
            
           {(task.vehiclelead.name !== "" && task.vehiclelead.name != null ) && ( <><span>Name:</span><p>&nbsp;{task.vehiclelead.name} </p></> )}
           
           {(task.vehiclelead.email !== "" && task.vehiclelead.email != null) && ( <><span>Email:</span><p>&nbsp;{task.vehiclelead.email} </p></> )}

           {(task.vehiclelead.phone !== "" && task.vehiclelead.phone != null) && ( <><span>Phone:</span><p>&nbsp;{task.vehiclelead.phone} </p></> )}
           <>
           <span>
            Make/Model/Year:
            </span> 
            <p> 
           {(task.vehiclelead.make !== "" && task.vehiclelead.make != null) &&
            (  
             <>&nbsp;{task.vehiclelead.make}</>
            )}

            {(task.vehiclelead.model !== "" && task.vehiclelead.model != null) &&
            (  
             <> {task.vehiclelead.model}</>
            )}

            {(task.vehiclelead.year !== "" && task.vehiclelead.year != null) &&
            (  
             <> {task.vehiclelead.year}</>
            )}
             </p> 
            
            </>

            {(task.vehiclelead.kms !== "" && task.vehiclelead.kms != null) && ( <><span> Kms:</span><p>&nbsp;{task.vehiclelead.kms} </p></> )}
            {(task.vehiclelead.vin !== "" && task.vehiclelead.vin != null) && ( <><span> Vin:</span><p>&nbsp;{task.vehiclelead.vin} </p></> )}
            
           {/* <span>Type:</span><p>&nbsp;Vehicle</p> */}


           <Link className="viewbtn" to={`/wonlead`}>
           View Detail  
           </Link>
           <div className="removeiconsetdrag">
                <button onClick={() => {props.onSelectItem(task?.id)}}>
                 <img className="profileimage" src={AppImages.removecross.default }/>
                    
                </button>
            </div>
        </div>
          )}
                                        
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </div>
                )}
            </Droppable>
            </div>
        </div>
    );
};
