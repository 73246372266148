import React, { useEffect, useState } from "react";
import { Link, Route, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { constant } from "../../utils/constant";
// class login extends Component{
const LeftSidebar = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const route_is = splitLocation[1];
    const rtarr = [
        "contact_information",
        "account_balance",
        "transaction_history",
        "account_settings",
        "dealership_information",
        "referandearn",
        "notificationsettings",
        "crm",        
    ];

    

    const params = useParams();
    // let wallet = 0;
    const [wallet, setWallet] = useState(
        JSON.parse(localStorage.getItem("header_data")).wallet
    );
    const [is_main, setIsmain] = useState(0);

    useEffect(() => {
        updateHederInfo();
    }, []);

    const isFound = rtarr.some((element) => {
        if (element == route_is) {
            return true;
        }
        return false;
    });

    const updateHederInfo = async (e) => {
        const res = await axios({
            method: "GET",
            url: constant.GETPROFILEINFO,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                if (response.data.status == true) {
                    var header_data = {
                        name: response.data.data.name,
                        matched: response.data.data.matched,
                        fulfilled: response.data.data.fulfilled,
                        wallet: response.data.data.wallet,
                    };
                    //console.log("CALL TO THAI CHE");
                    localStorage.setItem(
                        "header_data",
                        JSON.stringify(header_data)
                    );
                    localStorage.setItem(
                        "userData",
                        JSON.stringify(response.data)
                    );
                    //  wallet =   response.data.data.wallet;
                    setWallet(response.data.data.wallet);
                    setIsmain(response.data.data.is_main_account);
                } else {
                    //console.log(response);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // if(JSON.parse(localStorage.getItem("userData")) == null){

    //     } else {
    //         let header_data = JSON.parse(localStorage.getItem("header_data"));
    //         const userdata1 = localStorage.getItem("userData");
    //         const userdata =JSON.parse(userdata1);
    //         setTimeout((console.clear()), 1000);
    //         //wallet =   header_data.wallet;
    //         //is_main =  userdata.data.is_main_account;
    //         // console.log("wallet ="+wallet)
    //     }

    return (
        <>
            <div className="sidebarmenu sidebar is-closed">
                <div className="availablebalance_text">
                    <p>Available balance</p>
                    <h3>${wallet}</h3>
                </div>
                <ul>
                    <span className="menuHeading">Applications</span>

                    
                    <li className={isFound == true ? "active" : ""}>
                                <Link to="/crm">CRM </Link>
                            </li>
                    
                    

                    <li
                        className={
                            route_is == "available_customers" ||
                            (route_is == "lead" && params.type == "marketplace")
                                ? "active"
                                : ""
                        }
                    >
                        <Link to="/available_customers">
                            Available Customers
                        </Link>
                        {/* <a href="/open_marketplace">Open Marketplace</a> */}
                    </li>
                    <li
                        className={
                            route_is == "current_customers" ||
                            (route_is == "lead" && params.type == "customers")
                                ? "active"
                                : ""
                        }
                    >
                        <Link to="/current_customers">My Customers</Link>
                        {/* <a href="/current_customers">Current Customers</a> */}
                    </li>
                    {/* <li className={(route_is == "all_leads" || (route_is == 'lead' && params.type == 'all')) ? "active" : ""}>
                        <Link to="/all_leads">All Leads</Link>                          
                        </li> */}

                    {/* <li
                        className={
                            route_is == "aiassistant" ||
                            (route_is == "lead" && params.type == "aiassistant")
                                ? "active"
                                : ""
                        }
                    >
                        <Link to="/aiassistant">AI Assistant</Link>
                    </li> */}

                    <span className="menuHeading menumarginset">Auction</span>
                    <li className={route_is == "crmvehicle" ? "active" : ""}>
                                <Link to="/crmvehicle">CRM Vehicles</Link>
                    </li>

                    <li className={route_is == "vehiclelead" ? "active" : ""}>
                        <Link to="/vehiclelead">Available Vehicles </Link>
                    </li>

                    <li className={route_is == "wonlead" ? "active" : ""}>
                        <Link to="/wonlead"> My Vehicles </Link> 
                        {/* Won Leads */}
                    </li>
                </ul>
                <div className="account_textbox">
                    <span className="menuHeading">Settings</span>
                    <ul>
                        <li className={isFound == true ? "active" : ""}>
                            <Link to="/dealership_information">My Account</Link>
                        </li>
                        {/* <li className={route_is == "notifications" ? "active" : ""}>
                                    <Link to="/notifications">Notifications</Link>                                                               
                            </li> */}

                        {is_main == 1 ? (
                            <li className={route_is == "user" ? "active" : ""}>
                                <Link to="/user/list">Users</Link>
                            </li>
                        ) : null}

                     
                    </ul>
                </div>
                <div className="ask_btndiv">
                {/* <Link to="/aiassistant">Ask Hopper!</Link> */}
                <Link to="/generateleadlink">My Forms</Link>
                </div>
            </div>
        </>
    );
};

export default LeftSidebar;
