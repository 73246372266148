import {Navigate } from 'react-router-dom';
import axios from "axios";
import React,{ useEffect, useState } from "react";
import { AppImages, constant } from "../../utils/constant";
import Header from "../layouts/header";
import Loader from "../layouts/loader";
import { Toastify } from '../../utils/Common';
import { ToastContainer } from 'react-toastify';

// import { verifyOtp } from './api';
// import Input from './Input';

// export default function GoogleSignup({ enabled }) {
const GoogleSignup = props =>{ 

    const [QRCode, setQRCode] = useState('');
    const [secret, setSecret] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [shimmerEffect, setShimmerEffect] = useState('shimmerEffect');

    
    useEffect(()=>{
      getProfile();
    },[]);

    const getProfile = (e) => {
      axios({
          method: 'GET',
          url: constant.GETQRCODE,
          headers: {
            Authorization: localStorage.getItem('user_token'),
          },
        }).then((response) => {
          setQRCode(response.data.QR_Image);
          setSecret(response.data.secret);
          setShimmerEffect('');
      }).catch(err => {
          // checkAuth(err);
      });
    }
  

    const addSecrectKey = async() => {
      setIsLoading(true);
      const res = await axios({
        method: 'POST',
        url: constant.GOOGLELOGIN,
        data:{
          'secret':secret,
        },
        headers: {
          Authorization: localStorage.getItem('user_token'),
        },
      }).then((response) => {
        if(response.data.status == true){
          localStorage.setItem("userData", JSON.stringify(response.data));
          setIsRedirect(true);
        }else {
          Toastify("Something went wrong please try again",'warn');
          setIsLoading(false);
        }
      }).catch(err => {
        Toastify("Something went wrong please try again",'error');
        setIsLoading(false);
        console.log(err);
      });
    }

    

  
    return <>
            <Header/>
              <div className="contentCoverbox setfooter_addclass">
                {
                  isRedirect == true ? 
                  <Navigate to="/verify" /> : null
                }

                  <div className="vertical_logobox">
                      <img src={ AppImages.logo_white }  />
                  </div>
                  <div className="rught_sidebox">
                      <div className="create_detailsbox">
                          <div className="create_innerboxdiv">
                              <h2 className={shimmerEffect}>Two factor authentication.</h2>
                              <p className={shimmerEffect}>Set up Google Authenticator</p>
                              <p className={shimmerEffect}>Download Google Authenticator using App Store and Play Store</p>
                              <p className={shimmerEffect}>Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code { secret }</p>

                              <div className='text-center'
                                dangerouslySetInnerHTML={{__html:QRCode}}
                              />                              
                              <div className="createaccount_btn">
                                <a href={void 0} className={shimmerEffect} onClick={()=>addSecrectKey()}>
                                    Login
                                    {isLoading == true ? (
                                    <Loader />
                                ) : null}
                                </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <ToastContainer />
              
          </>

    // return (<>
    //     <div>
    //       {QRCode}
    //     </div>
    //   </>
    // );
  }

export default GoogleSignup;
