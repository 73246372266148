import io from "socket.io-client";
import { constant } from "../utils/constant";

export class SocketConst {
    static mySocketRef;
}
export const initiateSocket = () => {
    if (constant.mode == constant.ApplicationMode.local) return;

    if (SocketConst.mySocketRef == null) {
        const auth = JSON.parse(localStorage.getItem("userData"));

        const URL = `${constant.SOCKET_BASE_URL}?user_id=${auth?.data?.id}&token=${auth?.token}&type=user`;
        if (auth?.data?.id != "NaN" && auth?.data?.id != "")
            SocketConst.mySocketRef = io(URL);
        // SocketConst.mySocketRef = io(`https://foremostdigital.dev:1576/?user_id=1&type=user`);
    }
};

export const getBid = (onResult) => {
    SocketConst.mySocketRef?.on("getBid", (data) => {
        console.log(`Socket (getBid)`, `${JSON.stringify(data)}`);
        return onResult(data);
    });
};

export const getSingleBid = (onResult) => {
    SocketConst.mySocketRef?.on("singleBid", (data) => {
        console.log(`Socket (singleBid)`, `${JSON.stringify(data)}`);
        return onResult(data);
    });
};

export const onDisconnect = (onResult) => {
    SocketConst.mySocketRef?.on("onDisconnect", (data) => {
        console.log(`Socket (getBid)`, `${JSON.stringify(data)}`);
        return onResult(data);
    });
};
