import React,{ Component, useEffect, useState } from "react";
import {Link, Navigate } from 'react-router-dom';
import axios from "axios";
import { AppImages, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './../layouts/loader'
import { Toastify, ErrorManage } from "../../utils/Common";

const ForgotPassword = props =>{ 

    const [title, setTitle] = useState("Dealerhop | Forgot Password");
    const [email, setEmail] = useState('');
    const [errMsgEmail, setErrMsgEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        document.title = title;
    },[title])

    const submitClick = async() => {
        var i = 0;
        setErrMsgEmail('');

        if(email.length == 0 ){
            i++;
            setErrMsgEmail('This field is required.');
        }

        if(i == 0){
            setIsLoading(true)
            const res = await axios({
                method: 'POST',
                url: constant.FORGOTPASSWORD,
                data:{
                    'email':email,
                },
              }).then((response) => {
                setIsLoading(false);
                if(response.data.status == true){
                    Toastify(response.data.message,'success');
                    setErrMsgEmail('');
                    setEmail('');
                } else {
                    // ErrorManage(response);
                    // Toastify(response.data.message,'warn');  
                    setErrMsgEmail(response.data.message);  
                    // console.log(response.data);
                }
            }).catch(err => {
                Toastify("Something went wrong please try again",'error');
                console.log(err);
            });
        }
    } 

        return (
            <>
                <div className="vertical_logobox">
                    <img src={ AppImages.logo_white }  />
                   
                </div>
                <div className="rught_sidebox">
                    <div className="create_detailsbox">
                        <div className="create_innerboxdiv">
                            <h2>Forgot Password</h2>
                            <div className="account_inputbox">
                                <p>Email Address* </p>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email Address"
                                    value={email}
                                    onChange={(e) =>
                                        setEmail(
                                            e.target.value
                                        )
                                    }
                                />

                                <span className="text-danger">
                                    {errMsgEmail}
                                </span>
                            </div>
                            
                            <div className="createaccount_btn">
                                <Link to="" onClick={()=>submitClick()}>
                                    Reset Password
                                    {isLoading ? (
                                        <Loader />
                                    ) : (
                                       null
                                    )}
                                </Link>
                            </div>
                        </div>
                        <div className="alreadyaccount_boxset">
                            <p>
                                Already have an account?
                                <Link to="/login"> Login Here</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>
        );
}

export default ForgotPassword;