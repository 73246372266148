import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppImages, checkAuth, constant } from "../../utils/constant";
import axios from "axios";
import InputMask from "react-input-mask";
import {toast } from "react-toastify";
import Header from "../layouts/header";
import Loader from "../layouts/loader";
import { Modal, Button } from "react-bootstrap";
class AccountBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 3500,
            card_number: "",
            card_holder_name: "",
            exp_date: "",
            cvv: "",
            err_card_number_msg: "",
            err_card_holder_name_msg: "",
            err_exp_date_msg: "",
            err_cvv_msg: "",
            message: "",

            isLoading: false,
            isSkipLoading: false,
            redirect: false,
            set_show: false,
            error: 0,
        };
        
        this.onChangehandler = this.onChangehandler.bind(this);
        this.onchnageAmount = this.onchnageAmount.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    //on chnage event
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    onchnageAmount = (e) => {
        this.setState({ amount: e });
    };

    onSkipPaymenttHandler =(e) =>{
        
        const USERTOKEN = localStorage.getItem("user_token");
        this.setState({ isSkipLoading: true });
        axios({
            method: "GET",
            url: constant.SKIPPAYMENT,            
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                this.setState({ isSkipLoading: false });
                if (response.data.status === true) {
                    this.setState({ redirect: true });
                    var header_data = {
                        name: response.data.name,
                        matched: 0,
                        fulfilled: 0,
                        wallet: response.data.total_credits,
                    };
                    localStorage.setItem(
                        "header_data",
                        JSON.stringify(header_data)
                    );
                    localStorage.setItem(
                        "userData",
                        JSON.stringify(response.data)
                    );
                }
            })
            .catch((error) => {
                this.setState({ isSkipLoading: false });
                console.log("error call ", error);
            });
        
      
    }

     handleShow = (e) => {
       
       
        this.setState({ set_show: true });
    };
    handleClose = (e) => {
       
       
        this.setState({ set_show: false });
    };
	
	
    
    onAccountBalanceHandler = (e) => {
        e.preventDefault();
        this.setState({
            err_card_number_msg: "",
            err_card_holder_name_msg: "",
            err_exp_date_msg: "",
            err_cvv_msg: "",
        });

        this.setState({ error: 0 });

        if (this.state.card_number === "") {
            this.setState({ error: 1 });
            this.setState({
                err_card_number_msg: "The card number field is required",
            });
        }

        if (this.state.card_holder_name === "") {
            this.setState({ error: 1 });
            // error++;
            this.setState({
                err_card_holder_name_msg:
                    "The card holder name field is required",
            });
        }

        if (this.state.exp_date === "") {
            this.setState({ error: 1 });
            // error++;
            this.setState({
                err_exp_date_msg: "The expiry date field is required",
            });
        }
        if (this.state.cvv === "") {
            this.setState({ error: 1 });
            this.setState({
                err_cvv_msg: "The cvv field is required",
            });
        }

        setTimeout(() => {
            if (this.state.error === 0) {
                // const USERTOKEN =  constant.USERTOKEN;
                const USERTOKEN = localStorage.getItem("user_token");
                this.setState({ isLoading: true });
                

                axios({
                    method: "POST",
                    url: constant.MAKEPAYMENT,
                    params: {
                        amount: this.state.amount,
                        card_number: this.state.card_number,
                        card_holder_name: this.state.card_holder_name,
                        exp_date: this.state.exp_date,
                        cvv: this.state.cvv,
                    },
                    headers: {
                        Authorization: USERTOKEN,
                    },
                })
                    .then((response) => {
                        checkAuth(response);
                        console.log("response ", response);
                        this.setState({ isLoading: false });
                        if (response.data.status === true) {
                            this.setState({ redirect: true });
                            var header_data = {
                                name: response.data.name,
                                matched: 0,
                                fulfilled: 0,
                                wallet: response.data.total_credits,
                            };
                            localStorage.setItem(
                                "header_data",
                                JSON.stringify(header_data)
                            );
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(response.data)
                            );

                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            //    var keys = `err_${Object.keys(response?.data?.message)}_msg`
                            var keys = Object.keys(response?.data?.message);

                            this.setState({
                                [keys]: response?.data?.message[keys],
                            });
                        }
                    })
                    .catch((error) => {
                        checkAuth(error);
                        this.setState({ isLoading: false });
                        console.log("error call ", error);
                    });
            }
        }, 200);
    };

    render() {
        document.title = "Dealerhop | Account Balance";
        
        if (this.state.redirect) {
            return <Navigate to="/available_customers" replace={true} />;
        }

        return (
            <>
                <Header />
                <div className="contentCoverbox setfooter_addclass">
                    <div className="vertical_logobox">
                       
                        <img src={ AppImages.logo_black }  />
                    </div>
                    <div className="rught_sidebox">
                        <div className="create_detailsbox">
                            <div className="create_innerboxdiv">
                                <h2>Account Balance</h2>
                                <p className="accountbalance_textbox">
                                    Your current account balance is $0.00.
                                    Please add money into your account to match
                                    with customers.
                                </p>
                                <div className="balance_textbox">
                                    <input
                                        type="hidden"
                                        name="amount"
                                        value={this.state.amount}
                                        onChange={this.onChangehandler}
                                    />
                                    <ul>
                                        <li
                                            className={
                                                this.state.amount === 3500
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <a
                                                href={void 0}
                                                onClick={() =>
                                                    this.onchnageAmount(3500)
                                                }
                                            >
                                                $3,500
                                            </a>
                                        </li>
                                        <li
                                            className={
                                                this.state.amount == 5000
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <a
                                                href={void 0}
                                                onClick={() =>
                                                    this.onchnageAmount(5000)
                                                }
                                            >
                                                $5,000
                                            </a>
                                        </li>
                                        <li
                                            className={
                                                this.state.amount == 7500
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <a
                                                href={void 0}
                                                onClick={() =>
                                                    this.onchnageAmount(7500)
                                                }
                                            >
                                                $7,500
                                            </a>
                                        </li>
                                    </ul>
                                    <h3>Your Credit Card Details</h3>
                                    <p>
                                        Your credit card details are stored
                                        securely by Stripe.
                                    </p>
                                </div>

                                <div className="account_inputbox">
                                    <p>Credit Card Number*</p>
                                    {/* <input type="text" name="card_number" placeholder="Enter Credit Card Number" onChange={this.onChangehandler}/> */}
                                    {/* <input type="text" name="card_number" placeholder="Enter Credit Card Number" onChange={this.onChangehandler}/> */}
                                    <InputMask
                                        {...this.props}
                                        mask="9999 9999 9999 9999"
                                        maskChar=" "
                                        name="card_number"
                                        placeholder="Enter Credit Card Number"
                                        onChange={this.onChangehandler}
                                    />
                                    <span className="text-danger">
                                        {this.state.err_card_number_msg}
                                    </span>
                                </div>
                                <div className="account_inputbox">
                                    <p>Name On Credit Card*</p>
                                    <input
                                        type="text"
                                        name="card_holder_name"
                                        placeholder="Enter Name On Credit Card"
                                        onChange={this.onChangehandler}
                                    />
                                    <span className="text-danger">
                                        {this.state.err_card_holder_name_msg}
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>Expiry Date* </p>
                                            {/* <input type="text" name="exp_date" placeholder="Enter Expiry Date" onChange={this.onChangehandler}/> */}

                                            {/* <input type="text" name="exp_date" placeholder="Enter Expiry Date" onChange={this.onChangehandler}/> */}
                                            <InputMask
                                                {...this.props}
                                                mask="99/99"
                                                maskChar=" "
                                                name="exp_date"
                                                placeholder="MM/YY"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.err_exp_date_msg}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>CVV*</p>
                                            {/* <input type="text" name="cvv" placeholder="Enter CVV" onChange={this.onChangehandler}/> */}
                                            <InputMask
                                                {...this.props}
                                                mask="9999"
                                                maskChar=" "
                                                name="cvv"
                                                placeholder="123"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.err_cvv_msg}
                                            </span>
                                            <span className="text-danger">
                                                {this.state.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="createaccount_btn">

                             
                                    {/* /account_balance */}

                                    <Link
                                        to=""
                                        onClick={this.onAccountBalanceHandler}
                                    >
                                        ${this.state.amount.toLocaleString()}-
                                        Make A Payment
                                        {this.state.isLoading ? (
                                            <Loader />
                                        ) : (
                                            <></>
                                        )}
                                    </Link>

                                    <h6 className="skip_txt"
                                        to=""
                                        onClick={this.onSkipPaymenttHandler}
                                    >
                                        Skip For Now      
                                        {this.state.isSkipLoading ? (
                                            <Loader />
                                        ) : (
                                            <></>
                                        )}                                  
                                    </h6>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <span style={{ marginTop: "100px" }}>
                <Modal
                    show={this.state.set_show}
                    onHide={this.handleClose}
                    style={{ marginTop: "40px" }}
                >
                  
                    <Modal.Body>
                        <>
                            <div className="account_inputbox">
                                <p>Are you sure skip payment?</p>                              
                            </div>

                            

                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            No
                        </Button>
                        <Button
                            className="btn btn-success"
                            onClick={this.onSkipPaymenttHandler}
                        >
                            Yes {
                                this.state.isLoading == true ? (
                                    <Loader />
                                ) : null
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
            </>
        );
    }
}

export default AccountBalance;
