/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import { Component, useEffect,useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { checkAuth, constant } from "../../utils/constant";
import Footer from "../layouts/footer";
import AIFooter from "../layouts/aifooter";
import LeftSiderbar from "../layouts/left-sidebar";
import Header from "../layouts/header";
import AIHeader from "../layouts/aiHeader";
import FadeLoader from "react-spinners/FadeLoader";

// class CurrentCustomers extends Component{
const Fulfilled = (props) => {
    const [title, setTitle] = useState("Dealerhop | Fulfilled");
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [inputText, setInputText] = useState("");
    const [responseText, setResponseText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    let [color, setColor] = useState("#00824f");
    const [listResult,setListResult] = useState([])
    const bottomRef = useRef(null);
    const [userImage, setUserImage] = useState("");

    

    useEffect(() => {
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
        var  userdata2 = localStorage.getItem("userData");
      var userdata2 = JSON.parse(userdata2);     
        setUserImage(userdata2.data.image);
    }, []);

    const scrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
        }
      };

    const sendChat = async () => {
        
       

        setIsLoading(true);
        scrollToBottom();
        axios({
            method: "POST",
            url: constant.CHAT,
            data: {
                prompt: inputText,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                setIsLoading(false);
                checkAuth(response);
                setShimmerEffect("");
                console.log("response ", response);
                scrollToBottom();
                if (response.data.status == true) {

                    var item = {
                        que: inputText,
                        ans: response.data.data
                    }

                     setListResult([...listResult,item])   
                    // setResponseText(response.data.data);
                     var temp = `${responseText} </br> ${response.data.data}`
                     
        setResponseText(temp);
        
        setTimeout(() => {
            scrollToBottom();
        }, 200);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                checkAuth(error);
                setShimmerEffect("");
                console.log("error call ", error);
            });
    };

    const userdata1 = localStorage.getItem("userData");
    const userdata = JSON.parse(userdata1);

    let step = 0;
    let is_approved_by_admin = 0;

    // console.log(userdata)
    if (userdata) {
        step = userdata.data.step;
        is_approved_by_admin = userdata.data.is_approved_by_admin;
    }

    console.log(is_approved_by_admin);
    if (is_approved_by_admin == 0) {
        return <Navigate to="/profilestatus" />;
    }

    return (
        <>
            
            <AIHeader />
            <div className="contentCoverbox openai_topmandiv">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="openai_mandivbox">
                    <div className="headtxt">
                            <span>
                            <strong> Ask Hopper!</strong> Be clear, specific, use proper grammar, and avoid using slang or jargon while providing context.
                            </span>
                            <img                        
                                    src={
                                        require("../../assets/svg/powerbyopenai.svg")
                                            .default
                                    }
                                />
                        </div>
                        <div className="chatbox_main openapi_main">
                    
                        

                            <div className="response_message" ref={bottomRef}>
                                {responseText != null && responseText != "" ? (
                                    <p className="response_text">
                                        <span>Feedback: </span>
                                    </p>
                                ) : null}
                                { listResult?.map(item => <>

                                <div className="openaiprofile">
                                   
                                    
                                    {userImage != "" ?
                                   
                                   <img                        
                                    src={userImage}  />

                                    :
                                    <img                        
                                    src={
                                        require("../../assets/svg/nouser.svg")
                                            .default
                                    }
                                />
                                    }
                                    
                                 <p className="response_que">{`${item?.que}`}</p>
                                </div>
                                
                                 <div className="openaiprofile">
                                    
                                 <img                        
                                    src={
                                        require("../../assets/svg/feedback.svg")
                                            .default
                                    }
                                />
                                 <p className="response_ans">{`${item?.ans}`}</p>
                                 </div>
                                </>)}
                               
                                <FadeLoader
                                    color={color}
                                    loading={isLoading}
                                    size={60}
                                    aria-label="Loading..."
                                    data-testid="loader"
                                />
                            </div>
                           
                        </div>
                    </div>
                    <div className="askhopper_btndiv">
                        <span>
                        <img                        
                                    src={
                                        require("../../assets/svg/search.svg")
                                            .default
                                    }
                                />
                        
                        </span>
                        <textarea
                            id="inputText"
                            name="inputText"
                            rows="2"
                            cols="50"
                            placeholder="Type your request here..."
                            onChange={(e) => {
                                setInputText(e.target.value);
                            }}
                        ></textarea>
                        <button
                            className="btn custome_chat_btn"
                            onClick={() => sendChat()}
                        >
                            {/* Send */}
                            Ask Hopper!
                        </button>
                    </div>
                </div>
                {/* <Footer /> */}
                {/* <AIFooter /> */}
                <div className="aifooter">
                    <p>Hopper is powered by OpenAI and ChatGPT which may produce inaccurate information about people, places or facts </p>
                </div>
            </div>
        </>
    );
};

export default Fulfilled;
