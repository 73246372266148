const NoLead = (props) => {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    return (
        // numbers.map((number,index) =>
        //    <label key={index} className="checkcontainer">
        //        <p className={`${props?.shimmer}`}>Calgary</p>
        //     </label>

        // )

        <div class="custam_checkdiv">
            <form>
                <h3 className={`${props?.shimmer}`} style={{ width: "300px",marginBottom: "25px" }}>
                    Select cities
                </h3>
                <div class="toppings-list">
                    {numbers.map((number, index) => (
                        <label className="checkcontainer">
                            <p
                                className={`${props?.shimmer}`}
                                style={{ width: "150px" }}
                            >
                                &nbsp; &nbsp;
                            </p>
                            <input
                                className={`${props?.shimmer}`}
                                type="checkbox"
                                id=""
                                name=""
                                value="1"
                                checked=""
                            />
                            <span class="checkmark"></span>
                        </label>
                    ))}
                </div>
                <button
                    className={`checksubmitbtn ${props?.shimmer}`}
                    type="submit"
                >
                    Update
                </button>
            </form>
        </div>
    );
};
export default NoLead;
