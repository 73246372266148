import React, { useEffect, useState } from "react";

import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import SubHeader from "../../views/my_account/header";
import axios from "axios";
import Header from "../layouts/header";
import Loader from "./../layouts/loader";
import { AppImages, checkAuth, constant } from "../../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Nodata from "../layouts/nodatafound";

import Paper from "@material-ui/core/Paper";
// import IconButton from "@material-ui/core/IconButton";
import MapboxAutocomplete from "react-mapbox-autocomplete";

import $ from "jquery";

// class Dealerinfo extends Component{
const Dealerinfo = (props) => {
    const [title, setTitle] = useState("Dealerhop | Dealership Information");

    const [email, setEmail] = useState("");
    const [err_email, setErrEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isTestLoading, setTestLoading] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [isNoData, setIsNoData] = useState(true);
    const [crmEmailList, setcrmEmailList] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
    }, []);
    useEffect(() => {
        getProfile();
        getCrmEmail();
        document.title = title;
    }, [title]);

    const getProfile = async (e) => {
        const res = await axios({
            method: "GET",
            url: constant.GETPROFILE,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                if (response.data.status === true) {
                    setTimeout(() => {
                        $(".react-mapbox-ac-input").val(
                            response.data.data.dealership_location
                        );
                    }, 100);
                } else {
                }
            })
            .catch((err) => {
                checkAuth(err);
                setShimmerEffect("");
                console.log(err);
            });
    };

    const getCrmEmail = async (e) => {
        const crmemail_res = await axios({
            method: "GET",
            url: constant.GETCRMEMAILS,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                if (response.data.status === true) {
                    setIsNoData(false);
                    setcrmEmailList(response.data.data);
                } else {
                }
            })
            .catch((err) => {
                setIsNoData(false);
                checkAuth(err);
                setShimmerEffect("");
                console.log(err);
            });
    };

    function onDealerAddRoting(e) {
        e.preventDefault();
        let error = 0;

        setErrEmail("");

        if (email === "") {
            error++;
            setErrEmail("This field is required");
        }

        if (error === 0) {
            const USERTOKEN = localStorage.getItem("user_token");
            setIsLoading(true);
            axios({
                method: "POST",
                url: constant.ADDCRMEMAIL,
                params: {
                    email: email,
                },
                headers: {
                    Authorization: USERTOKEN,
                },
            })
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.status === true) {
                        getProfile();
                        getCrmEmail();
                        var header_data = {
                            name: response.data.name,
                            matched: response.data.matched,
                            fulfilled: response.data.fulfilled,
                            wallet: response.data.wallet,
                        };

                        localStorage.setItem(
                            "header_data",
                            JSON.stringify(header_data)
                        );
                        setEmail("");
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        setErrEmail(response.data.message);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("error call ", error);
                });
        }
    }

    const sendTestLeadEmail = async (e, flag) => {
        const USERTOKEN = localStorage.getItem("user_token");
        setTestLoading(true);
        axios({
            method: "GET",
            url: constant.SENDTESTCRMEMAIL,
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                setTestLoading(false);
                if (response.data.status === true) {
                   

                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                setTestLoading(false);
                console.log("error call ", error);
            });
    };
    const crmEmailDelete = async (e, flag) => {
        console.log(e);
        const USERTOKEN = localStorage.getItem("user_token");
        axios({
            method: "POST",
            url: constant.REMOVECRMEMAIL,
            data: {
                id: e.id,
                flag: flag,
            },
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                if (response.data.status == true) {
                    getCrmEmail();
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log("error call ", error);
            });
    };

    return (
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass1">
                <LeftSiderbar />
                <div className="rightsidecontent set_newbgcolor">
                    <SubHeader />
                    <div className="myaccount_mandiv">
                        <div className="create_detailsbox">
                            <div className="create_innerboxdiv">
                                <div className="connect_titlediv">
                                    <h2> Connect CRM </h2>
                                    <span>
                                        Save your ADF routing address below.{" "}
                                    </span>
                                </div>
                                <div className="account_inputbox">
                                    <p className={shimmerEffect}></p>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Enter CRM email address"
                                        className={shimmerEffect}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />

                                    <span className="text-danger">
                                        {err_email}
                                    </span>
                                </div>

                                <div className="createaccount_btn">
                                    <a
                                        href={void 0}
                                        onClick={(e) => onDealerAddRoting(e)}
                                    >
                                        {" "}
                                        Add Routing Address
                                        {isLoading === true ? <Loader /> : null}
                                    </a>
                                </div>

                                <div className="history_detilsbox">
                                    <h3>Current ADF Connections</h3>
                                    {crmEmailList.length > 0 ? (
                                        <>
                                            {crmEmailList.map((item, index) => {
                                                return (
                                                    <CEMCell
                                                        item={item}
                                                        key={index}
                                                        onDelete={(item) =>
                                                            crmEmailDelete(
                                                                item,
                                                                "deleted"
                                                            )
                                                        }
                                                    />
                                                );
                                            })}
                                            <button
                                                className="sendleadbtn"
                                                onClick={(item) => {
                                                    if (!isTestLoading) {
                                                        sendTestLeadEmail(item);
                                                    }
                                                }}
                                            >
                                                Send Test Lead
                                                {isTestLoading && <Loader />}
                                                
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            {isNoData ? (
                                                [1, 2, 3, 4, 5, 6].map(
                                                    (index) => (
                                                        <ul key={index}>
                                                            <li className="shimmerEffect">
                                                                <span>
                                                                    James@email.com
                                                                </span>
                                                            </li>
                                                            <li className="shimmerEffect">
                                                                <span></span>
                                                            </li>
                                                        </ul>
                                                    )
                                                )
                                            ) : (
                                                <Nodata />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            <ToastContainer />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Dealerinfo;

const CEMCell = (props) => {
    return (
        <ul className="crmlisting">
            <li>
                <p>
                    {props?.item?.email}{" "}
                    <span>
                        <button
                            onClick={() => {
                                props?.onDelete(props?.item);
                            }}
                        >
                            <img
                                className="taskiconset"
                                src={AppImages.trashicon.default}
                            />
                        </button>
                    </span>
                </p>
            </li>
        </ul>
    );
};
