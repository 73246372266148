import React,{ Component, useEffect, useState } from "react";
import {Link, Navigate, useNavigate } from 'react-router-dom';
import axios from "axios";
import { AppImages, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './../layouts/loader'
import { Toastify } from "../../utils/Common";


const ResetPassword = props => {


    const queryParams = new URLSearchParams(window.location.search);
    

    const [title, setTitle] = useState("Dealerhop | Reset Password");
    const [token, setToken] = useState(queryParams.get('token'));
    const [email, setEmail] = useState(queryParams.get('email'));
    const [password, setPassword] = useState('');
    const [errMsgPassword, setErrMsgPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [errMsgPasswordConfirm, setErrMsgPasswordConfirm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{
        document.title = title;
    },[title])
    
    const submitClick = async() => {
        var i = 0;      
    
        setErrMsgPassword('');
        setErrMsgPasswordConfirm('');
        if(password.length == 0 ){
            i++;
            setErrMsgPassword('This field is required.');
        }

        if(password_confirm.length == 0 ){
            i++;
            setErrMsgPasswordConfirm('This field is required.');
        }

        if(i == 0){
            setIsLoading(true)
            const res = await axios({
                method: 'POST',
                url: constant.RESETPASSWORD,
                data:{
                    'password':password,
                    'email':email,
                    'token':token,
                    'password_confirm':password_confirm,
                },
              }).then((response) => {
                setIsLoading(false);

                if(response.data.status == true){
                    Toastify(response.data.message,'success');
                    setErrMsgPassword('');
                    setErrMsgPasswordConfirm('');

                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("userData", JSON.stringify(response.data));
                    localStorage.setItem("user_token", "Bearer " + response.data.token);
                    var header_data={
                        name: response.data.data.name,
                        matched: response.data.data.matched,
                        fulfilled: response.data.data.fulfilled,
                        wallet: response.data.data.wallet,
                    };
              
                    localStorage.setItem("header_data",JSON.stringify(header_data));

                    setIsRedirect(true);

                } else {
                    // ErrorManage(response);
                    // Toastify(response.data.message,'warn'); 
                    const errors = Object.entries(response.data.message); 
                    errors.forEach((error) => {
                        const [key, value] = error;
                        if(key == "password" || key == "email"){
                            setErrMsgPassword(value);
                        }
                        if(key == "password_confirm"){
                            setErrMsgPasswordConfirm(value);
                        }
                    });

                    function ucwords (str) {
                        return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                            return $1.toUpperCase();
                        });
                    }    
                }
            }).catch(err => {
                Toastify("Something went wrong please try again",'error');
                console.log(err);
            });
        }
    } 


   
        return (
            <>
                <div className="vertical_logobox">
                <img src={ AppImages.logo_white }  />
                </div>
                <div className="rught_sidebox">
                    <div className="create_detailsbox">
                        <div className="create_innerboxdiv">
                            <h2>Reset Password</h2>
                            <div className="account_inputbox">
                                <p>Password* </p>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(
                                            e.target.value
                                        )
                                    }
                                />

                                <span className="text-danger">
                                    {errMsgPassword}
                                </span>
                            </div>
                            <div className="account_inputbox">
                                <p>Confirm Password* </p>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Enter confirm password"
                                    value={password_confirm}
                                    onChange={(e) =>
                                        setPasswordConfirm(
                                            e.target.value
                                        )
                                    }
                                />
                                <span className="text-danger">
                                    {errMsgPasswordConfirm}
                                </span>
                            </div>

                            <div className="createaccount_btn">
                                <Link to="" onClick={()=>submitClick()}>
                                    Reset Password
                                    {isLoading ? (
                                        <Loader />
                                    ) : (
                                       null
                                    )}
                                </Link>
                            </div>
                        </div>
                        <div className="alreadyaccount_boxset">
                            <p>
                                Already have an account? 
                                <Link to="/login"> Login</Link>
                            </p>
                        </div>
                    </div>
                </div>
                {
                    isRedirect ? 
                    <Navigate to="/" />
                    :null
                }
                <ToastContainer />
            </>
        );
}

export default ResetPassword;