import React, { Componen, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import SubHeader from "../../views/my_account/header";
import axios from "axios";
import { checkAuth, constant } from "../../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../views/layouts/header";
import { Modal, Button, Form } from "react-bootstrap";
import Loader from "../layouts/loader";
import NoCity from "../../views/my_account/no-city";
import Nodata from "../layouts/nodatafound";


// class AccountSetting extends Component{
const AccountSetting = () => {
    // render() {
    const [title, setTitle] = useState("Dealerhop | Account Settings");
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [city, setCityData] = useState([]);
    const [isNoData, setIsNoData] = useState(true);
    const [selectedValue, setSelectedValue] = useState([]);
    const [ids, setIds] = useState([]);
    useEffect(() => {
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
    }, []);

    useEffect(() => {
        getProfile();
        getAllCity();
        document.title = title;
    }, []);

    const handleChecked = (e) => {
        console.log("ITEM", e.target.value);
        const value = parseInt(e.target.value);
        const checked = e.target.checked;
        var tempids = [...ids];
        if (tempids.includes(value)) {
            var index = tempids.indexOf(value);
            tempids.splice(index, 1);
        } else {
            tempids?.push(value);
        }
        tempids?.sort();
        console.log(tempids);   

        setIds(tempids);

        // if(checked){
        //     setSelectedValue([...ids, value])
        // }else{
        //     setSelectedValue(ids.filter( (e) => (e !== value) ))
        // }
    };
    const handaleSubmit = (e) => {
        e.preventDefault();

       // console.log(ids.toString());

        const USERTOKEN = localStorage.getItem("user_token");
        axios({
            method: "POST",
            url: constant.UPDATECITIES,
            params: {
                cities_id: ids.toString(),
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                
                if (response.data.status == true) {                   
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log("error call ", error);
            });   


    };
       const getProfile = async () => {
        setShimmerEffect("shimmerEffect");
        // const USERTOKEN =  localStorage.getItem('user_token');
        axios({
            method: "GET",
            url: constant.GETPROFILE,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                console.log("response ", response);
                if (response.data.status == true) {
                   // setEmail(response.data.data.email);
                }
                setShimmerEffect("");
            })
            .catch((error) => {
                setShimmerEffect("");
                console.log("error call ", error);
            });
    };

    /* GET CITY */

    const getAllCity = async () => {
        setIsNoData(true);
        setShimmerEffect("shimmerEffect");
        // const USERTOKEN =  localStorage.getItem('user_token');
        axios({
            method: "GET",
            url: constant.GETALLCITY,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
               
                // console.log("response ", response);
                if (response.data.status == true) {
                    setCityData(response.data.data);

                    var tempIds = [];
                    response.data.data.map((item, index) => {
                        if (item.is_selected) {
                            tempIds.push(item?.id);
                        }
                    });
                    setIds(tempIds);
                    setIsNoData(false);
                    setSelectedValue([...selectedValue, ids]);
                }
                setShimmerEffect("");
            })
            .catch((error) => {
                setShimmerEffect("");
                console.log("error call ", error);
            });
    };
    /* GET CITY */

    return (
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass1">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <SubHeader />

                    <div className="custam_checkdiv">
                    {city.length > 0 ? (
                        <form onSubmit={handaleSubmit}>
                            <h3>Select cities</h3>
                            <div className="toppings-list">
                            {city.length > 0 ? (

                                city.map((item, index) => {
                                    return (
                                        <label key={`${index}`} className="checkcontainer">
                                            {item.city}
                                            <input
                                                type="checkbox"
                                                id={`${item.city}`}
                                                name="city_id"
                                                value={`${item.id}`}
                                                defaultChecked={
                                                    item.is_selected
                                                }
                                                onChange={handleChecked}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    );
                                })


                                ) : (
                                 <>
                                  {isNoData ? (
                                        <NoCity shimmer={shimmerEffect} />
                                    ) : (
                                        <Nodata />
                                    )}
                                 </>
                            )}

                            </div>

                            <button className="checksubmitbtn" type="submit">Update</button>
                        </form>
                        ) :
                        
                        <>
                                  {isNoData ? (
                                        <NoCity shimmer={shimmerEffect} />
                                    ) : (
                                        <Nodata />
                                    )}
                                 </>
                        
                        }
                    </div>
                </div>
                <Footer />
            </div>

            <ToastContainer />
        </>
    );
    // }
};

export default AccountSetting;
