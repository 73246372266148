import React,{ Component } from "react";


class AIfooter extends Component{
    render() {
        return (
            <>
                <div className="footer_logodiv aifooter">
                <p>Hopper is powered by OpenAI and ChatGPT which may produce inaccurate information about people, places or facts </p>
                </div>
                

            </>

        )
    }
}

export default AIfooter;