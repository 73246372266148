import React from "react";
import { useEffect, useState } from "react";

export const TimmerView = (props) => {
  const start = new Date();
  const end = new Date(props?.date);
  const [leftTime, setLeftTime] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      
      const utcDate = new Date(start.toUTCString().slice(0, -4));
      
      if(end > utcDate){
        
        setLeftTime(`` + msToTime(Math.abs(utcDate - end)));
      }else{
        setLeftTime(``);
         
      }
      
      
    }, 1000);

    return () => clearTimeout(timer);
  });

  const msToTime = (duration) => {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60))),
      days = Math.floor(duration / (1000 * 60 * 60) / 24);

    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    props?.onRenderUi({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });

    if(hours >= 48){       
          return ""; 
     }  
    return hours + ":" + minutes + ":" + seconds;

  };

  return (
    
    leftTime != "" ? (
        <div  className="timeleft_text">
        <p> Time left to match:           
        <span> {leftTime}</span>
        </p>    
        </div>
      )
       :
        null
    
     
  );
};
