import React,{ useEffect, useState } from "react";
import {  Navigate } from "react-router-dom";
import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import SubHeader from '../../views/my_account/header';
import axios from "axios";
import { checkAuth, constant } from "../../utils/constant";
import Header from "../layouts/header";
import Nodata from "../layouts/nodatafound";
import LoadMore from "../leads/loadMore";
// class TransactionHistory extends Component {
const TransactionHistory = (props) => {
    const [title, setTitle] = useState("Dealerhop | Transaction History");
    const [histrylist,setHistrylist] = useState([]);
    const [isNoData,setIsNoData] = useState(true)
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         histrylist: [],
    //         isNoData: true,
    //     };

    //     this.getContactData();
    // }
    useEffect(() => {
        setTimeout(() => {            
            document.querySelector('body').scrollTo(0,0);
        }, 100);
      }, []);
    useEffect(()=>{
        document.title = title;
        var interval = setTimeout(() => {
                        getContactData();
                    }, 300);
        return () => clearInterval(interval);
    },[])

    const getContactData = async () => {
        setIsLoading(true);
        const USERTOKEN = localStorage.getItem("user_token");
        axios({
            method: "GET",
            url: constant.GETTRANECTIONHISTORY,
            params: {
                page: page,
            },
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                checkAuth(response);
                setIsNoData(false);
                setIsLoading(false);

                if (response.data.status === true) {
                    setLastPage(response.data.last_page)
                    setCurrentPage(response.data.current_page)
                    setHistrylist([...histrylist,...response.data.data]);
                    setPage(1+response.data.current_page);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    const userdata1 = localStorage.getItem("userData");
        const userdata = JSON.parse(userdata1);

        if (userdata.data.is_main_account === 0) {
            return <Navigate to="/" />;
        }


    return (
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass1">
                <LeftSiderbar />
                <div
                    className="rightsidecontent">
                    <SubHeader />
                    <div className="myaccount_mandiv">
                        <div className="transactionhistory_mandiv">
                            <div className="history_titlebox">
                                <h3>Transaction History </h3>
                                <p>
                                    Below is the history of the amount you
                                    deposited in your account and payments
                                    made to match with customers.
                                </p>
                            </div>
                            <div className="history_detilsbox">
                                {histrylist.length > 0 ? (
                                    histrylist.map(
                                        (item, index) => {
                                            return (
                                                <HistoryCell
                                                    item={item}
                                                    key={index}
                                                />
                                            );
                                        }
                                    )
                                ) : (
                                    <>
                                        { isNoData ? (
                                            [1, 2, 3, 4, 5, 6].map(
                                                (index) => (
                                                    <ul key={index}>
                                                        <li className="shimmerEffect">
                                                            <p>
                                                                15 Nov,2022
                                                            </p>
                                                        </li>
                                                        <li className="shimmerEffect">
                                                            <span>
                                                                James
                                                            </span>
                                                        </li>
                                                        <li className="shimmerEffect">
                                                            <span>
                                                                - $175.00
                                                            </span>
                                                        </li>
                                                    </ul>
                                                )
                                            )
                                        ) : (
                                            <Nodata />
                                        )}
                                    </>
                                )}
                            </div>
                            <LoadMore
                                currentPage={currentPage}
                                lastPage={lastPage}
                                isLoading={isLoading}
                                onClick={() => getContactData()}
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default TransactionHistory;


 const HistoryCell = props => {
    
    return (
        <ul>
               <li><p>{props?.item?.date}</p></li>
               <li><span>{props?.item?.title}</span></li>
               <li>  <span className={(props?.item?.tansaction_type ==='Deposit') ? 'deposit_color' :''}>{(props?.item?.tansaction_type ==='Deposit') ? '' :'-'} ${props?.item?.amount}</span></li>
       </ul>
    )
}