import { Button } from "react-bootstrap";
import Loader from "../layouts/loader";

const LoadMore = (props) => {
    return (
        <>
            {
                props?.currentPage != props?.lastPage ? (
                    <div className="text-center">

                        <Button className="btnLoadMore" onClick={props.onClick}>
                            Load More
                            {
                                props?.isLoading == true?
                                <Loader />
                                :
                                <></>
                            }
                        </Button>
                    </div>
                ) :
                 <></>
            }
        </>
    );
};

export default LoadMore;
