import axios from "axios";
import { Component, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { checkAuth, constant } from "../../utils/constant";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import Header from "../../views/layouts/header";
import Nodata from "../layouts/nodatafound";
import NoLead from "./no-lead";
import LoadMore from "./loadMore";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";

// class CurrentCustomers extends Component{
const CurrentCustomers = (props) => {
    const [title, setTitle] = useState("Dealerhop | Current Customers");
    const [customerLeads, setCustomerLeads] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isNoData, setIsNoData] = useState(true);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [province, setProvince] = useState([]);
    const [search_province, setSearchProvince] = useState([]);
    const [default_province, setDefaultProvince] = useState("");

    useEffect(() => {
        getProvince();
    }, [province == null]);
    // useEffect(()=>{
    //     getCustomerLeadData();
    // },[])
    useEffect(() => {
        // :point_down:️ scroll to top on page load
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
    }, []);

    useEffect(() => {
        document.title = title;
        let interval = setTimeout(() => {
            getCustomerLeadData();
        }, 300);
        return () => clearInterval(interval);
    }, [searchText, search_province]);

    const leardSearch = async (e) => {
        setSearchText(e);
        setPage(1);
        setCustomerLeads([]);
        setCurrentPage(0);
        setLastPage(0);
        getCustomerLeadData();
    };

    //
    const handleChange = (event) => {
        setPage(1);
        if (event.target.value == "Select Province") {
            setSearchProvince("");
        } else {
            setSearchProvince(event.target.value);
            setDefaultProvince(event.target.value);
        }
    };

    

    const hideLead = (id) => {
       
       axios({
        method: "POST",
        url: constant.HIDELEAD,
        params: {           
            id: id,
        },
        headers: {
            Authorization: localStorage.getItem("user_token"),
        },
    })
        .then((response) => {
            if (response.data.status == true) {
                setTimeout(() => {
                    setPage(1)
                    setCustomerLeads(response.data.data);
                }, 100);
               
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((error) => {
            
            console.log("error call ", error);
        }); 
      };
    

    const getCustomerLeadData = async () => {
        setShimmerEffect("shimmerEffect");
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.CURRENTCUSTOMERSLEADS,
            params: {
                search: searchText,
                search_province: search_province,
                page: page,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);

                // console.log("response ", response);
                if (response.data.status == true) {
                    if (page == 1) {
                        setCustomerLeads(response.data.data);
                    } else {
                        setCustomerLeads([
                            ...customerLeads,
                            ...response.data.data,
                        ]);
                    }
                    setCurrentPage(response.data.current_page);
                    setLastPage(response.data.last_page);
                    setPage(1 + response.data.current_page);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    const getProvince = async () => {
        setShimmerEffect("shimmerEffect");
        // setIsLoading(true);
        // setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETPROVINCE,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                // setIsNoData(false);
                // setIsLoading(false);
                if (response.data.status == true) {
                    setProvince(response.data.data);
                    console.log("is_true ==>",response.data.is_true);
                    if(response.data.my_province !== "" && response.data.is_true === 1){
                        setSearchProvince(response.data.my_province);
                        setDefaultProvince(response.data.my_province);
                        
                    }
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                // setIsNoData(false);
                // setIsLoading(false);
                console.log("error call ", error);
            });
    };


   


    const userdata1 = localStorage.getItem("userData");
    const userdata = JSON.parse(userdata1);

    let step = 0;
    let is_approved_by_admin = 0;

    // console.log(userdata)
    if (userdata) {
        step = userdata.data.step;
        is_approved_by_admin = userdata.data.is_approved_by_admin;
    }

    console.log(is_approved_by_admin);
    if (is_approved_by_admin == 0) {
        return <Navigate to="/profilestatus" />;
    }

    return (
        <>
            <Header />
            <div className="contentCoverbox newsetfooter_addclass">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="marketplace_search">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="input-group">
                                    <img
                                        src={
                                            require("../../assets/svg/Iconly-Light-Search.svg")
                                                .default
                                        }
                                    />
                                    <input
                                        className="form-control border-end-0 border rounded-pill"
                                        type="search"
                                        id="example-search-input"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) =>
                                            leardSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input-group select_province_tag">
                                    <select
                                        className="form-control border-end-0 border"
                                        onChange={handleChange}
                                        value={default_province}
                                    >
                                        <option>Select Province</option>
                                        {province.map((item, index) => {
                                            return <option key={index}>{item}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="marketplace_boxdiv">
                        <div className="row">
                            {customerLeads.length > 0 ? (
                                customerLeads.map((item, index) => {
                                    return (
                                        <CustomerLeadCell
                                            item={item}
                                            key={index}
                                            onClickEvent={id=>{
                                                hideLead(id)
                                            }}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    {isNoData ? (
                                        <NoLead shimmer={shimmerEffect} />
                                    ) : (
                                        <Nodata />
                                    )}
                                </>
                            )}
                        </div>
                        <LoadMore
                            currentPage={currentPage}
                            lastPage={lastPage}
                            isLoading={isLoading}
                            onClick={() => getCustomerLeadData()}
                        />
                    </div>
                </div>
                <Footer />
                <ToastContainer />
            </div>
        </>
    );
};

export default CurrentCustomers;

const CustomerLeadCell = (props) => {
   

    return (
        <div className="col-lg-4">
           
            {/* {props?.item?.vehicle_make != null &&
            props?.item?.vehicle_make != "" ? (
                <div className="newbtn_set">
                    <h4>Make</h4>
                    <p>{props?.item?.vehicle_make}</p>
                </div>
            ) : null}

            {props?.item?.vehicle_type != null &&
            props?.item?.vehicle_type != "" ? (
                <div className="newbtn_set newbtn_set1">
                    <h4>Body Style</h4>
                    <p>{props?.item?.vehicle_type}</p>
                </div>
            ) : null} */}


            <div className="match_boxmandiv">
                {/* <div className="newbtn_set">
                {(props?.item?.vehicle_type != null && props?.item?.vehicle_type != "") 
                    ?
                    <p>Make: <span> {props?.item?.vehicle_make}</span></p>
                    :
                    null
                    }
                    
                    {(props?.item?.vehicle_make != null && props?.item?.vehicle_make != "") 
                    ?
                    <p>Body Style: <span> {props?.item?.vehicle_type}</span></p>
                    :
                    null
                    }
                </div> */}

                <h3>
                    {props?.item?.first_name} {props?.item?.last_name}
                </h3>
                {/* <span> */}
                {/* {props?.item?.date_submited}  */}
                {/* ( {props?.item?.day_on_site} ) */}
                {/* </span> */}

                <div className="inner_matchtext">
                    {/* <p>${props?.item?.price}</p> */}

                    {props?.item?.vehicle_make != null &&
                    props?.item?.vehicle_make != "" ? (
                        <p>
                            <span>Make:</span>
                            {props?.item?.vehicle_make}
                        </p>
                    ) : null}

                    {props?.item?.vehicle_type != null &&
                    props?.item?.vehicle_type != "" ? (
                        <p>
                            <span>Body Style:</span>
                            {props?.item?.vehicle_type}
                        </p>
                    ) : null}

                    {props?.item?.delivery_type != null &&
                    props?.item?.delivery_type != "" ? (
                        <p>
                            <span>Delivery Type:</span>
                            {props?.item?.delivery_type}
                        </p>
                    ) : null}

                    {props?.item?.budget != null &&
                    props?.item?.budget != "" ? (
                        <p>
                            <span>Payment:</span>
                            {props?.item?.budget}
                        </p>
                    ) : null}

                    {/* {props?.item?.vehicle_type != null &&
                    props?.item?.vehicle_type != "" ? (
                        <p>
                            <span>Body Style:</span>
                            {props?.item?.vehicle_type}
                        </p>
                    ) : null} */}

                    {/* {props?.item?.vehicle_make != null &&
                    props?.item?.vehicle_make != "" ? (
                        <p>
                            <span>Make:</span>
                            {props?.item?.vehicle_make}
                        </p>
                    ) : null} */}

                    {props?.item?.condition != null &&
                    props?.item?.condition != "" ? (
                        <p>
                            <span>New or Used:</span>
                            {props?.item?.condition}
                        </p>
                    ) : null}

                    {props?.item?.price_range != null &&
                    props?.item?.price_range != "" ? (
                        <p>
                            <span>Price Range:</span>
                            {props?.item?.price_range}
                        </p>
                    ) : null}
                </div>
                <div className="inner_matchtext">
                    {props?.item?.address != null &&
                    props?.item?.address != "" ? (
                        <p>
                            <span>Address:</span>

                          {props?.item?.is_showadd === 1 ? props?.item?.address : '***'}
                        </p>
                    ) : null}

                    {props?.item?.housing_type != null &&
                    props?.item?.housing_type != "" ? (
                        <p>
                            <span>Housing Type:</span>
                            {props?.item?.housing_type}
                        </p>
                    ) : null}

                    {props?.item?.housing_payment != null &&
                    props?.item?.housing_payment != "" ? (
                        <p>
                            <span>Housing Payment:</span>
                            {props?.item?.housing_payment}
                        </p>
                    ) : null}

                    {props?.item?.province != null &&
                    props?.item?.province != "" ? (
                        <p>
                            <span>Province:</span>
                            {props?.item?.province}
                        </p>
                    ) : null}

                    {props?.item?.city != null && props?.item?.city != "" ? (
                        <p>
                            <span>City:</span>
                            {props?.item?.city}
                        </p>
                    ) : null}

                    {props?.item?.income_length != null &&
                    props?.item?.income_length != "" ? (
                        <p>
                            <span>Income Length:</span>
                            {props?.item?.income_length}
                        </p>
                    ) : null}
                    {props?.item?.monthly_income != null &&
                    props?.item?.monthly_income != "" ? (
                        <p>
                            <span>Monthly Income:</span>
                            {props?.item?.monthly_income}
                        </p>
                    ) : null}
                </div>
                <div className="inner_matchtext">
                    {props?.item?.employment_type != null &&
                    props?.item?.employment_type != "" ? (
                        <p>
                            <span>Employment:</span>
                            {props?.item?.employment_type}
                        </p>
                    ) : null}

                    {props?.item?.position_or_title != null &&
                    props?.item?.position_or_title != "" ? (
                        <p>
                            <span>Title:</span>
                            {props?.item?.position_or_title}
                        </p>
                    ) : null}

                    {props?.item?.dob != null && props?.item?.dob != "" ? (
                        <p>
                            <span>Date Of Birth:</span>
                            {props?.item?.dob}
                        </p>
                    ) : null}

                    {props?.item?.credit_estimate != null &&
                    props?.item?.credit_estimate != "" ? (
                        <p>
                            <span>Credit Estimate:</span>
                            {props?.item?.credit_estimate}
                        </p>
                    ) : null}

                    {props?.item?.dl_notify != null &&
                    props?.item?.dl_notify != "" ? (
                        <p>
                            <span>Drivers License:</span>
                            {props?.item?.dl_notify}
                        </p>
                    ) : null}

                    {props?.item?.notes != null && props?.item?.notes != "" ? (
                        <div className="notMainDiv">
                            <span>Notes:</span>
                            <p>{props?.item?.notes}</p>
                        </div>
                    ) : null}
                </div>
                <div className="viewProfilebtn">
                    <Link to={`/lead/customers/${props?.item?.id}`}>
                        View Profile
                    </Link>

                    <button className="link_a_btn" href={void 0} onClick={()=>props.onClickEvent(props?.item?.id)}>
                                                Hide                                         
                                            </button>
                    
                </div>
            </div>
        </div>
    );
};
