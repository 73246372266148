import { Alert, Button } from "react-bootstrap";
import React, { Component,useEffect,useState} from "react";
import { Link,Navigate,useParams } from "react-router-dom";
import axios from "axios";
import Loader from './../layouts/loader'
import { constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paper from "@material-ui/core/Paper";
import MapboxAutocomplete from "react-mapbox-autocomplete";

const RefRegister = () => {
    const params = useParams();
    const [refferal_data, RefferalData] = useState([]);
    
    useEffect(() => {

        
        localStorage.setItem("is_refferal_user", 1); 
        var refferal_data=
            {
                 "referral_dealerhip_name":params.dealershipname,
                  "referal_from_user":params.dealerid
            };        
          localStorage.setItem("referral_data",JSON.stringify(refferal_data));
        });

        return <Navigate  to="/register" />;

}

export default RefRegister;
