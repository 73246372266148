import React,{ useState, useEffect, Component } from "react";
import {Link} from 'react-router-dom';
import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import SubHeader from '../../views/my_account/header';
import axios from "axios";
import ReactDOM from "react-dom/client";
import { checkAuth, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import Header from "../layouts/header";
import Loader from "../layouts/loader";
class ContactInfoUpdate extends Component{
  
    constructor(props) {
        
        super(props);
        this.state = {
        
         phone: "",
         dealership_phone: "",
         dealership_first_name: "",
         dealership_last_name: "",              
         err_phone_msg: "",
         err_dealership_phone_msg: "",
         err_dealership_first_name_msg: "",
         err_dealership_last_name_msg: "",
         shimmerEffect:"shimmerEffect",
          
         isLoading: false,
         redirect: false,
         title: "Dealerhop | Contact Information",
          
        };
        
        this.onChangehandler =this.onChangehandler.bind(this);
        this.getContactData();
      }


      //on chnage event 
      onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        
        
      };
  getContactData = async () => {
  
    const USERTOKEN =  localStorage.getItem('user_token');       
  axios({
        method: 'GET',
        url: constant.GETCONTACTINFO,
        headers: {
          Authorization: localStorage.getItem('user_token'),
        },
      })
       .then((response) => {
            checkAuth(response);
            console.log('response ', response);
            this.setState({shimmerEffect:''});                                            
            if(response.data.status == true){  
                
            //    this.setState({redirect:true})        
            this.setState({phone:response.data.data.phone});                            
            this.setState({dealership_phone:response.data.data.dealership_phone});                            
            this.setState({dealership_first_name:response.data.data.dealership_first_name});                            
            this.setState({dealership_last_name:response.data.data.dealership_last_name});                                            
                
            }
            
        })
        .catch((error) => {        
            checkAuth(error);
            this.setState({shimmerEffect:''});                                            
            console.log('error call ', error);                  
        });
}


 onContactInfoHandler = (e) => {
        
    let error = 0;
     e.preventDefault();
     this.setState({
         err_phone_msg: "",
         err_dealership_phone_msg: "",
         err_dealership_first_name_msg: "",
         err_dealership_last_name_msg: "",
       }); 

     if(this.state.phone ==""){
         error++;
         this.setState({
             err_phone_msg: "This field is required",
           });                    
     }        
     if(this.state.dealership_phone==""){
         error++;
         this.setState({
             err_dealership_phone_msg: "This field is required",
         });                    
     }
     if(this.state.dealership_first_name==""){
         error++;
         this.setState({
             err_dealership_first_name_msg: "This field is required",
           });                    
     }
     if(this.state.dealership_last_name==""){
         error++;
         this.setState({
             err_dealership_last_name_msg: "This field is required",
           });                    
     }
   
      
     
    
     if(error == 0){
         
         
         // return <Navigate  to="/step/account_balance" />;
       
       const USERTOKEN =  localStorage.getItem('user_token');
       
       this.setState({isLoading:true});

       axios({
             method: 'POST',
             url: constant.UPDATECONTACTINFO,
             params: {
                phone: this.state.phone,
                dealership_phone: this.state.dealership_phone,
                dealership_first_name: this.state.dealership_first_name,
                dealership_last_name: this.state.dealership_last_name,  
             },
             headers: {
               Authorization: USERTOKEN,
             },
           })
            .then((response) => {
                checkAuth(response);

                this.setState({isLoading:false});

                
                 if(response.data.status == true){  
                    //  this.setState({redirect:true})                      
                      

                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                 }

             })
             .catch((error) => { 
                checkAuth(error);
                this.setState({isLoading:false});
                console.log('error call ', error);                  
             });
   
     }     
 
 }

render(){

    document.title = this.state.title;

   return (
                <>
                <Header/>
                <div className="contentCoverbox setfooter_addclass1">
                        <LeftSiderbar/>
                        <div className="rightsidecontent">
                            <SubHeader/>
                            <div className="myaccount_mandiv">
    
                                 <div className="create_detailsbox">
                            <div className="create_innerboxdiv">                            
                                <h2 className="borderBottumSet">Your Contact Info.</h2>                            
                                <div className="account_inputbox">
                                    <p className={this.state.shimmerEffect}>Your Mobile Number*</p>
                                    <input type="text" name="phone" value={this.state.phone} className={this.state.shimmerEffect} placeholder="Enter Mobile Number" onChange={this.onChangehandler} />
                                <span className="text-danger">{this.state.err_phone_msg}</span>
                                </div>
                                <div className="account_inputbox">
                                    <p className={this.state.shimmerEffect}>Dealership Phone Number*</p>
                                    <input type="text" name="dealership_phone" className={this.state.shimmerEffect} value={this.state.dealership_phone} placeholder="Enter Dealership Phone Number" onChange={this.onChangehandler}/>
                                <span className="text-danger">{this.state.err_dealership_phone_msg}</span>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p className={this.state.shimmerEffect}>First Name* </p>
                                            <input type="text" name="dealership_first_name" className={this.state.shimmerEffect} value={this.state.dealership_first_name} placeholder="Enter First Name" onChange={this.onChangehandler}/>

                                        <span className="text-danger">{this.state.err_dealership_first_name_msg}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p className={this.state.shimmerEffect}>Last Name*</p>
                                            <input type="text" className={this.state.shimmerEffect} name="dealership_last_name" value={this.state.dealership_last_name} placeholder="Enter Last Name" onChange={this.onChangehandler}/>
                                        <span className="text-danger">{this.state.err_dealership_last_name_msg}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="createaccount_btn">
                                    <Link to="" onClick={this.onContactInfoHandler}>Update
                                        {
                                            this.state.isLoading ?
                                            <Loader/>
                                            : <></> 
                                        }
                                    </Link>
                                </div>
                            </div>
                        </div>
    
                            </div>
                        </div>
                        <Footer/>
                    </div>
    
                    <ToastContainer />
                </>
    
            )
}
}


// class ContactInfoUpdate extends Component{
//     render() {
//         return (
//             <>
//             <div className="contentCoverbox setfooter_addclass">
//                     <LeftSiderbar/>
//                     <div className="rightsidecontent" style={{
//                             borderTop: '1px solid #D1D1D1',
//                             background: '#F1F1F1',
//                         }}>
//                         <Header/>
//                         <div className="myaccount_mandiv">

//                              <div className="create_detailsbox">
//                         <div className="create_innerboxdiv">
//                             <h2>Your Contact Info.</h2>
//                             <div className="account_inputbox">
//                                 <p>Your Mobile Number*</p>
//                                 <input type="text" name="phone" placeholder="Enter Mobile Number" />
//                             </div>
//                             <div className="account_inputbox">
//                                 <p>Dealership Phone Number*</p>
//                                 <input type="text" name="dealership_phone" placeholder="Enter Dealership Phone Number" />
//                             </div>
//                             <div className="row">
//                                 <div className="col-md-6">
//                                     <div className="account_inputbox">
//                                         <p>First Name* </p>
//                                         <input type="text" name="first_name" placeholder="Enter First Name" />
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className="account_inputbox">
//                                         <p>Last Name*</p>
//                                         <input type="text" name="last_name" placeholder="Enter Last Name" />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="createaccount_btn">
//                                 <Link to="">Update</Link>
//                             </div>
//                         </div>
//                     </div>

//                         </div>
//                     </div>
//                     <Footer/>
//                 </div>

               
//             </>

//         )
//     }
// }

export default ContactInfoUpdate;