import React,{ Componen,useState,useEffect} from "react";
import {Link} from 'react-router-dom';
import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import SubHeader from '../../views/my_account/header';
import axios from "axios";
import { AppImages, checkAuth, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../views/layouts/header';
import { Modal, Button, Form } from "react-bootstrap";
import Loader from "../layouts/loader";
// class AccountSetting extends Component{
const AccountSetting =()=> {
    // render() {

        const [title, setTitle] = useState("Dealerhop | Account Settings");
        const [email, setEmail] = useState(''); 
		const [show, setShow] = useState(false);
		
        const [old_password, setCurruntPassword] = useState('');
		const [password, setPassword] = useState('');
		const [password_confirmation, setConfirmationPassword] = useState('');

        const [err_old_password, setErrOldPassword] = useState('');
		const [err_password, setErrPassword] = useState('');
		const [err_password_confirmation, setErrConfirmationPassword] = useState('');
        const [shimmerEffect, setShimmerEffect] = useState('shimmerEffect');
        const [isLoading, setIsLoading] = useState(false);
        const [selectedFile, setSelectedFile] = useState(null);
        const [isUploading, setIsUploading] = useState(false);
        const [userImage, setUserImage] = useState("");
        useEffect(() => {
            setTimeout(() => {            
                document.querySelector('body').scrollTo(0,0);
            }, 100);
          }, []);

		useEffect(()=>{
			setCurruntPassword('');
            setPassword('');
            setConfirmationPassword('');
			getProfile();
            document.title = title;
		},[]);
  
	const handleShow = () => {
        setCurruntPassword('');
        setPassword('');
        setConfirmationPassword('');
        setShow(true);
    };
	const handleClose = () => setShow(false);
	
    
    const changePassword = () => {
        setErrOldPassword('');
		setErrPassword('');
		setErrConfirmationPassword('');
        var i = 0;
        
        if(old_password.length == 0 ){
            i++;
            setErrOldPassword('This field is required');
        }

        if(password.length == 0 ){
            i++;
            setErrPassword('This field is required');
        }

        if(password_confirmation.length == 0 ){
            i++;
            setErrConfirmationPassword('This field is required');
        }

        if((password.length != 0 && password_confirmation.length != 0) && password != password_confirmation){
            setErrConfirmationPassword('');
            i++;
            setErrConfirmationPassword('The password confirmation does not match');
        }

        if(i == 0){
            setIsLoading(true);
            axios({
                method: "POST",
                url: constant.CHNAGEPASSWORD,
                params:{
                    old_password:old_password,
                    password:password,
                    password_confirmation:password_confirmation
                },
                headers: {
                    Authorization: localStorage.getItem('user_token'),
                },
            })
                .then((response) => {
                    setIsLoading(false);
                    checkAuth(response);
                    // console.log("response ", response.data.data);
                    if (response.data.status == true) {
                        setShow(false);
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    
                    
                    if(response.data.status == false){
                        if(response.data.message.old_password.length != 0){
                            setErrOldPassword(response.data.message.old_password)
                        }
                    }
                })
                .catch((error) => {
                    checkAuth(error);
                    setIsLoading(false);
                    console.log("error call ", error);
                });
        }

    };


	function updateEmail(e) {
		e.preventDefault();

		
		const USERTOKEN =  localStorage.getItem('user_token');       
		  axios({
				method: 'POST',
				url: constant.UPDATEEMAIL,
				params: {
					email: email,
				},
				headers: {
				  Authorization: localStorage.getItem('user_token'),
				},
			  })
			   .then((response) => {                
					console.log('response ', response);
					if(response.data.status == true){  				
					  setEmail(email);  

					  toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

					}               
				})
				.catch((error) => {                  
					console.log('error call ', error);                  
				});
			
			
	
	
	}




	const getProfile = async () => {  
        setShimmerEffect('shimmerEffect');
        // const USERTOKEN =  localStorage.getItem('user_token');    
      axios({
            method: 'GET',
            url: constant.GETPROFILE,
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
          })
           .then((response) => {          
               
               console.log('response ', response);
               if(response.data.status == true){  				
                   setEmail(response.data.data.email);  
                   setUserImage(response.data.data.image);
                }
                setShimmerEffect('');
            })
            .catch((error) => {                  
                setShimmerEffect('');
                console.log('error call ', error);                  
            });
		}
       

        const handleFileChange = (event) => {
          setSelectedFile(event.target.files[0]);
          handleImageUpload(event.target.files[0])
           
        };
        
        const handleImageUpload = async (selectedFile) => {
         
          setIsUploading(true);
          if (!selectedFile) {
            setIsUploading(false);
            toast.error("Please select an image to upload.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
          }
        
          try {
            const formData = new FormData();
            formData.append("image", selectedFile);
          
            axios.post(constant.CHNAGEPROFILEPIC, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: localStorage.getItem('user_token')
                },
              }).then((response) => {
                     
                      if (response.data.status) {
                        getProfile();
                        setUserImage(response.data.image);
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                      
                      } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                                             
                      }

                      setIsUploading(false);
                    })
                    .catch((error) => {    
                        setIsUploading(false);                 
                      throw error;
                    });              
           
          } catch (error) {
            console.error("Error uploading image:", error);
            setIsUploading(false);
          }
        };
        

	return (
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass1">
                <LeftSiderbar />
                <div
                    className="rightsidecontent">
                    <SubHeader />
                    <div className="myaccount_mandiv">
                        <div className="transactionhistory_mandiv">
                            <div className="history_titlebox setaccount_textbox">
                                <h3>Account Settings</h3>
                                <p>
                                    Update your email address or password
                                    anytime.
                                </p>
                            </div>

                            <div className="email_inputbox">
                                <div className="account_inputbox">
                                    <p className={shimmerEffect}>Profile Photo</p>
                                 
                                  {isUploading == true ? 
                                  
                                  // eslint-disable-next-line jsx-a11y/alt-text
                                  <img className="profileimage" src={AppImages.loadergif } />
                                 
                                  :
                                  <>
                                  {userImage != "" ?
                                   
                                   // eslint-disable-next-line jsx-a11y/alt-text
                                   <img className={`profileimage ${shimmerEffect}`} src={userImage} />
                                    :
                                    // eslint-disable-next-line jsx-a11y/alt-text
                                    <img className={`profileimage ${shimmerEffect}`} src={AppImages.nouser.default } />
                                    }
                                    </>

                                }                                    
                                    
                                 <div>
                                 <label className="choosefile_label"> Choose file
                                   
                                  <input type="file" accept="image/*" onChange={handleFileChange} />
                                    </label>                                     
                                    {/* <Link to="" onClick={handleImageUpload}>
                                    
                                Upload Image
                                </Link> */}
                                    </div>
                                </div>
                                
                            </div>

                            <div className="email_inputbox">
                                <div className="account_inputbox">
                                    <p className={shimmerEffect}>Email Address</p>
                                    <input
                                        type="text"
                                        name="email"
                                        className={shimmerEffect}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        value={email}
                                        placeholder="Enter Email Address"
                                    />
                                </div>
                                <Link to="" onClick={updateEmail}>
                                    Change Email Address
                                </Link>
                            </div>
                            <div className="password_textbox">
                                <h3>Password</h3>
                                <p>
                                    It’s important to keep your password
                                    up-to-date.
                                </p>
                                <Link to="" onClick={handleShow}>
                                    Change Password
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <span style={{ marginTop: "100px" }}>
                <Modal
                    show={show}
                    onHide={handleClose}
                    style={{ marginTop: "40px" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <div className="account_inputbox">
                                <p>Current Password</p>
                                <input
                                    type="password"
                                    name="old_password"
                                    onChange={(e) => {
                                        setCurruntPassword(e.target.value);
                                    }}
                                    value={old_password}
                                    placeholder="Enter current password"
                                />

                                <span className="text-danger">
                                    {err_old_password}
                                </span>
                            </div>

                            <div className="account_inputbox">
                                <p>New Password</p>
                                <input
                                    type="password"
                                    name="password"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    value={password}
                                    placeholder="Enter new password"
                                />

                                <span className="text-danger">
                                    {err_password}
                                </span>
                            </div>

                            <div className="account_inputbox">
                                <p>Confirm Password</p>
                                <input
                                    type="password"
                                    name="password_confirmation"
                                    onChange={(e) => {
                                        setConfirmationPassword(e.target.value);
                                    }}
                                    value={password_confirmation}
                                    placeholder="Enter confirm password"
                                />
                                <span className="text-danger">
                                    {err_password_confirmation}
                                </span>
                            </div>
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btn btn-success"
                            onClick={changePassword}
                        >
                            Save {
                                isLoading == true ? (
                                    <Loader />
                                ) : null
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
            <ToastContainer />
        </>
    );
    // }
}

export default AccountSetting;