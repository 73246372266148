import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { AppImages, constant } from "../../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./../layouts/loader";

const Login = (props) => {
    const params = props?.route?.params;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [errMsgEmail, setErrMsgEmail] = useState("");
    const [errMsgPwd, setErrMsgPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const [directMainPage, setDirectMainPage] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let token = queryParams.get("token");
        localStorage?.clear();
        if (token) checkAuth();
    }, []);

    const checkAuth = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let token = queryParams.get("token");

        if (!token) return;
        setIsLoading(true);
        axios
            .post(constant.CHECKAUTHENTICATION, { token })
            .then((response) => {
                setIsLoading(false);
                if (response.data.status) {
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem(
                        "userData",
                        JSON.stringify(response.data)
                    );
                    localStorage.setItem(
                        "user_token",
                        "Bearer " + response.data.token
                    );

                    const header_data = {
                        name: response.data.data.name,
                        matched: response.data.data.matched,
                        fulfilled: response.data.data.fulfilled,
                        wallet: response.data.data.wallet,
                    };

                    localStorage.setItem(
                        "header_data",
                        JSON.stringify(header_data)
                    );
                    localStorage.setItem("login_verify", "false");

                    if (response.data.data.app_mode === "production") {
                        setMsg(response.data.message);
                        setRedirect(true);
                    } else {
                        setMsg(response.data.message);
                    }

                    RedirectApp(false);

                    // toast.success(response.data.message, {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });
                } else {
                    handleErrorResponse(response);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
    };

    const handleErrorResponse = (response) => {
        if (
            response.data.status === false &&
            response.data.success === undefined
        ) {
            if (email === "") {
                setErrMsgEmail(response.message);
            } else if (password === "") {
                setErrMsgPwd(response.data.message);
            } else {
                setErrMsg(response.data.message);
            }
        } else if (
            response.data.status === false &&
            response.data.success === false
        ) {
            setErrMsg(response.data.message);
            setTimeout(() => {
                setErrMsg("");
            }, 2000);

            toast.success(response.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const onSignInHandler = (e) => {
        e.preventDefault();
        setErrMsgEmail("");
        setErrMsgPwd("");
        setErrMsg("");

        if (email === "") {
            setErrMsgEmail("This field is required");
            return;
        }
        if (password === "") {
            setErrMsgPwd("This field is required");
            return;
        }

        setIsLoading(true);

        axios
            .post(constant.LOGIN, { email, password })
            .then((response) => {
                setIsLoading(false);
                if (response.data.status) {
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem(
                        "userData",
                        JSON.stringify(response.data)
                    );
                    localStorage.setItem(
                        "user_token",
                        "Bearer " + response.data.token
                    );

                    const header_data = {
                        name: response.data.data.name,
                        matched: response.data.data.matched,
                        fulfilled: response.data.data.fulfilled,
                        wallet: response.data.data.wallet,
                    };

                    localStorage.setItem(
                        "header_data",
                        JSON.stringify(header_data)
                    );
                    localStorage.setItem("login_verify", "false");

                    if (response.data.data.app_mode === "production") {
                        setMsg(response.data.message);
                        setRedirect(true);
                       
                    } else {
                        setMsg(response.data.message);
                    }
                    RedirectApp(response.data.data.app_mode === "production");

                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    handleErrorResponse(response);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [redirectPage, setRedirectPage] = useState("");
    const RedirectApp = (isLive) => {
        const login = localStorage.getItem("isLoggedIn");
        if (!login) return;

        const userData = JSON.parse(localStorage.getItem("userData"));
        const step = userData ? userData.data.step : 0;
        const is_skip_payment = userData ? userData.data.is_skip_payment : 0;
       
       
        if(isLive){
            setRedirectPage("/verify");
        }else if (step == 1 || step == 2) {
            setRedirectPage("/step/contact_info");
        } else if (
            (step == 3 && is_skip_payment === 0) ||
            (step === 3 && is_skip_payment === 1)
           
        ) {
            setRedirectPage("/available_customers");
        } else if(step == 4) {
            setRedirectPage("/available_customers");
        }

        

        return;

        if (redirect) {
            return <Navigate to="/verify" />;
        }

        if (step === 1 || step === 2) {
            return <Navigate to="/step/contact_info" />;
        } else if (step === 3 && is_skip_payment === 0) {
            return <Navigate to="/available_customers" />;
        } else if (step === 3 && is_skip_payment === 1) {
            return <Navigate to="/available_customers" />;
        } else if (step === 4) {
            return <Navigate to="/available_customers" />;
        }
    };

    return redirectPage != "" ? (
        <>
            <Navigate to={redirectPage} />
        </>
    ) : (
        <>
            <div className="vertical_logobox">
                <img src={AppImages.logo_white} alt="Logo" />
            </div>
            <div className="rught_sidebox">
                <div className="create_detailsbox">
                    <div className="create_innerboxdiv">
                       
                        <h2>Login</h2>
                        <div className="account_inputbox">
                            <p>Email Address* </p>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span className="text-danger">{msg}</span>
                            {!email ? (
                                <span className="text-danger">
                                    {errMsgEmail}
                                </span>
                            ) : null}
                        </div>
                        <div className="account_inputbox">
                            <p>Password* </p>
                            <input
                                type="password"
                                name="password"
                                placeholder="Enter Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {!password ? (
                                <span className="text-danger">{errMsgPwd}</span>
                            ) : null}
                            <span className="text-danger">{errMsg}</span>
                        </div>
                        <p className="resetpassword_div">
                            Forgot Password?
                            <Link to="/forgot_password"> Reset Password</Link>
                        </p>
                        <div className="createaccount_btn">
                            <Link to="#" onClick={onSignInHandler}>
                                Login Now
                                {isLoading ? <Loader /> : null}
                            </Link>
                        </div>
                    </div>
                    <div className="alreadyaccount_boxset">
                        <p>
                            Already have an account?
                            <Link to="/register"> Register Here</Link>
                        </p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default Login;
