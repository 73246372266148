import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { constant } from "./constant";
export const Toastify = (msg,type) => {
    
    if (type == "success") {
        toast.success(msg == null || msg == undefined ? "" : msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }


    if (type == "warn") {
        toast.warn(msg == null || msg == undefined ? "" : msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    if (type == "error") {
        toast.error(msg == null || msg == undefined ? '' : msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}


export const ErrorManage = response => {
    const errors = Object.entries(response.data.errors); 
    errors.forEach((error) => {
        const [key, value] = error;
        
        var originalIndex = (ucwords(key.replace("_", " ")).replace(" ", ""));
        var index = 'setErrMsg'+originalIndex;
        console.log(index);
        index[originalIndex](value[0])
    });

    function ucwords (str) {
        return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    }
}

export const check2faManage = (onGetValue) => {
//    return async () => {
        axios.get(constant.GOOGLECHECK2FA, {
            headers: {
                Authorization: localStorage.getItem("user_token"),
            }
        })
        .then((response) => {      
            if(response.status == 200){
                if(response.data.message[0] == "An unknown error has occurred. Please try again."){
                   onGetValue("/google/verify")
                }
            }
        })
        .catch((error) => {
            console.log(error)
        });
//    }
}