import {  useEffect, useState } from "react";
import { checkAuth, constant } from "../../utils/constant";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import Header from "../../views/layouts/header";
import axios from "axios";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Nodata from "../layouts/nodatafound";
import NoLead from "./no-lead";
import LoadMore from "./loadMore";
import { ToastContainer, toast } from "react-toastify";
import { TimmerVehicleView } from "../../utils/DateVehicleTimeUtils";
import SimpleImageSlider from "react-simple-image-slider";
import { Modal } from "react-bootstrap";

import {
    getSingleBid,
    getBid,
    initiateSocket,
    onDisconnect,
    SocketConst,
} from "../../socketUtils/MySocket";

// class VehicleLead extends Component{
const VehicleLead = (props) => {
    const [title, setTitle] = useState("Dealerhop | All Leads");
    const [allVehicleLead, setAllVehicleLead] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [isNoData, setIsNoData] = useState(true);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [province, setProvince] = useState([]);
    const [search_province, setSearchProvince] = useState([]);
    const [setallbids, setAllBids] = useState(null);
    const [show, setShow] = useState(false);
    const [err_amount, errAmount] = useState("");
    const [timezone, setTimezone] = useState("");
    const [room, setRoom] = useState("");
    const [setdetailmodelshow, setDetailModelShow] = useState(false);
    // const [setamount, setAmount] = useState(0);
    const [leadData, setLeadData] = useState(null);
    const [singleLeadData, setSingleLeadData] = useState(null);
    const [singleLeadData1, setSingleLeadData1] = useState(null);
    const [singleLeadData2, setSingleLeadData2] = useState(null);
    const [pics, setPics] = useState(null);
    const [default_province, setDefaultProvince] = useState("");

    useEffect(() => {
        getProvince();
    }, [province === null]);

    useEffect(() => {
        document.title = title;
        let interval = setTimeout(() => {
            getVehicleLeadData();
        }, 300);
        return () => clearInterval(interval);
    }, [searchText, search_province]);

    const handleChange = (event) => {
        setPage(1);
        if (event.target.value === "Select Province") {
            setSearchProvince("");
        } else {
            setSearchProvince(event.target.value);
            setDefaultProvince(event.target.value);
        }
    };

    const leardSearch = async (e) => {
        setSearchText(e);
        setPage(1);
        // setAllVehicleLead([]);
        setCurrentPage(0);
        setLastPage(0);
        getVehicleLeadData();
    };

    const showShowDetail = (id) => {
        axios({
            method: "GET",
            url: constant.VEHICLELEADDETAIL,
            params: {
                vehicle_lead_id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                // console.log("response ", response);
                if (response.data.status === true) {
                    setLeadData(response.data.data);
                    setSingleLeadData(response.data.single_lead_data);
                    setSingleLeadData1(response.data.single_lead_data_1);
                    setSingleLeadData2(response.data.single_lead_data_2);
                    setPics(response.data.pics);

                    setTimeout(() => {
                        setDetailModelShow(true);
                    }, 100);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");

                console.log("error call ", error);
            });
    };

    const handleDetailModelClose = () => setDetailModelShow(false);

    const showBids = (id, flag, item) => {
        // console.log("id - - ", id);
        console.log("flag - - - ", flag);
        // console.log("item.room_id - - - ", item.room_id);
        console.log("room - - - ", room);
        if (flag === 0) {
            // leaveRoomEmit(item)
        }

        if (room === "") {
            setRoom(item.room_id);
            joinRoomEmit(item);
        } else {
            if (room === item.room_id) {
            } else {
                leaveRoomEmit(item, room);
                setTimeout(() => {
                    setRoom(item.room_id);
                    joinRoomEmit(item);
                }, 100);
            }
        }

        // SocketConst.mySocketRef?.emit(
        //     "joinBIDRoom",
        //     {
        //         vehicle_id:
        //             item?.id,
        //         room_id:
        //             item?.room_id.trim(),
        //         type: "user",
        //         user_id: JSON.parse(
        //             localStorage.getItem(
        //                 "userData"
        //             )
        //         )?.data?.id,
        //     }
        // );

        axios({
            method: "GET",
            url: constant.GETBIDS,
            params: {
                vehicle_lead_id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                if (response.data.status === true) {
                    // console.log(response.data.data);
                    setAllBids(response.data.data);
                    if (flag === 1) {
                        setShow(true);
                    }
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");

                console.log("error call ", error);
            });
    };
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => setShow(false);

    const getVehicleLeadData = async () => {
        setShimmerEffect("shimmerEffect");
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETVEHICLELEAD,
            params: {
                search: searchText,
                search_province: search_province,
                page: page,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                //  console.log("response ", response);
                if (response.data.status === true) {
                    if (page === 1) {
                        setAllVehicleLead(response.data.data);
                    } else {
                        setAllVehicleLead([
                            ...allVehicleLead,
                            ...response.data.data,
                        ]);
                    }

                    setLastPage(response.data.last_page);
                    setCurrentPage(response.data.current_page);

                    setPage(1 + response.data.current_page);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    const getProvince = async () => {
        setShimmerEffect("shimmerEffect");
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETPROVINCE,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                if (response.data.status === true) {
                    setProvince(response.data.data);
                    setDefaultProvince(response.data.my_province);
                    setSearchProvince(response.data.my_province);
                    setPage(1);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    const placeBid = (id, amount, item) => {
        let error = 0;
        if (amount === "") {
            error++;
            errAmount("Please Enter amount");

            toast.error("Please Enter amount", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


       

        if (error === 0) {
            const USERTOKEN = localStorage.getItem("user_token");
            setIsLoading(true);
            axios({
                method: "POST",
                url: constant.ADDBID,
                params: {
                    timezone: timezone,
                    vehicle_lead_id: id,
                    amount: amount,
                },
                headers: {
                    Authorization: USERTOKEN,
                },
            })
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.status === true) {
                        var header_data = {
                            name: response.data.name,
                            matched: response.data.matched,
                            fulfilled: response.data.fulfilled,
                            wallet: response.data.wallet,
                        };

                        localStorage.setItem(
                            "header_data",
                            JSON.stringify(header_data)
                        );

                        if (room === "") {
                            console.log("No room 1st time ");
                            setRoom(item.room_id);
                            // "Join Room Here"
                            joinRoomEmit(item);
                        } else {
                            if (room === item.room_id) {
                                console.log(
                                    "Allrady Join Room only emit send new bid "
                                );
                                // "Allrady Join Room only emit send new bid"
                                // newBidEmit(item);
                            } else {
                                console.log(
                                    "room chnage, join and newbid emit "
                                );
                                leaveRoomEmit(item, room);

                                setTimeout(() => {
                                    setRoom(item.room_id);
                                    joinRoomEmit(item);
                                }, 100);
                                // "room chnage, join and newbid emit"
                            }
                        }

                        newBidEmit(item, amount);

                        /* SET DEFULT AMOUNT */
                        var temp = [
                            ...allVehicleLead,
                        ];
                
                        for (
                            let index = 0;
                            index < temp.length;
                            index++
                        ) {
                            const element =
                                temp[index];
                            if (
                                element?.id === id
                            ) {
                                temp[index] = {
                                    ...temp[index],
                                    maxbid:
                                        temp[index]
                                            .maxbid ===
                                            null ||
                                        temp[index]
                                            .maxbid ===
                                            undefined
                                            ? { amount: `${amount}.00`,}
                                            : {
                                                  ...temp[
                                                      index
                                                  ]
                                                      .maxbid,
                                                  amount: `${amount}.00`,
                                              },
                                };
                            }
                        }
                
                        setAllVehicleLead(temp);
                        /* SET DEFULT AMOUNT */

                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log("error call ", error);
                });
        }
    };

    const leaveRoomEmit = async (item, room) => {
        // console.log("leaveRoomEmit", room);

        SocketConst.mySocketRef.emit("leaveRoom", item?.room_id.trim());
        SocketConst.mySocketRef.disconnect();
        SocketConst.mySocketRef = null;
        initiateSocket();
    };

    const joinRoomEmit = (item) => {
        console.log("join room succes fully", item?.room_id.trim());

        SocketConst.mySocketRef?.emit("joinBIDRoom", {
            vehicle_id: item?.id,
            room_id: item?.room_id.trim(),
            type: "user",
            user_id: JSON.parse(localStorage.getItem("userData"))?.data?.id,
        });
    };

    const newBidEmit = (item, amount) => {
        // console.log("New succes fully");
        SocketConst.mySocketRef?.emit("newBid", {
            vehicle_id: item?.id,
            room_id: item?.room_id.trim(),
            type: "user",
            amount: amount,
            user_id: JSON.parse(localStorage.getItem("userData"))?.data?.id,
        });
    };

    useEffect(() => {
        const getTimezone = () => {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setTimezone(timezone);
        };
        getTimezone();
    }, []);

    useEffect(() => {
        onDisconnect((data) => {
            console.log(`onDisconnect`, JSON.stringify(data));
        });
    }, []);

    //

    useEffect(() => {
        initiateSocket();
    }, []);

    // render(){
    return (
        <>
            <Header />

            <div className="contentCoverbox newsetfooter_addclass">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="marketplace_search">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="input-group">
                                    <img
                                        src={
                                            require("../../assets/svg/Iconly-Light-Search.svg")
                                                .default
                                        }
                                    />
                                    <input
                                        className="form-control border-end-0 border rounded-pill"
                                        type="search"
                                        id="example-search-input"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) =>
                                            leardSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                             <div className="col-lg-3">
                                <div className="input-group select_province_tag">
                                    <select
                                        className="form-control border-end-0 border"
                                        onChange={handleChange}
                                        value={search_province}
                                    >
                                        <option>Select Province</option>
                                        {province.map((item, index) => {
                                            return <option key={index}>{item}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="carslider_manboxdiv">
                                <div className="row">
                                
                                    

                                    {allVehicleLead === null ? (
                                        <NoLead shimmer={shimmerEffect} />
                                    ) : allVehicleLead?.length === 0 ? (
                                        <Nodata />
                                    ) : (
                                        <>
                                        {allVehicleLead?.length > 0 ? (
                                         
                                        allVehicleLead?.map((item, index) => (
                                            <VehicleLeadCell
                                                item={item}
                                                key={index}
                                                index={index}
                                                onPlaceBid={(amount) => {
                                                    placeBid(
                                                        item?.id,
                                                        amount,
                                                        item
                                                    );
                                                }}
                                                onShowDetail={() => {
                                                    showShowDetail(item?.id);
                                                }}
                                                onShowBids={() => {
                                                    showBids(item?.id, 1, item);
                                                }}
                                                getSingleBid={(msg) => {
                                                    console.log(
                                                        msg?.data?.data
                                                    );

                                                    var temp = [
                                                        ...allVehicleLead,
                                                    ];

                                                    for (
                                                        let index = 0;
                                                        index < temp.length;
                                                        index++
                                                    ) {
                                                        const element =
                                                            temp[index];
                                                        if (
                                                            element?.id ===
                                                            msg?.data?.data
                                                                ?.vehicle_id
                                                        ) {
                                                            temp[index] = {
                                                                ...temp[index],
                                                                maxbid:
                                                                    temp[index]
                                                                        .maxbid ===
                                                                        null ||
                                                                    temp[index]
                                                                        .maxbid ===
                                                                        undefined
                                                                        ? { amount: `${msg?.data?.data?.amount}.00`,}
                                                                        : {
                                                                              ...temp[
                                                                                  index
                                                                              ]
                                                                                  .maxbid,
                                                                              amount: `${msg?.data?.data?.amount}.00`,
                                                                          },
                                                            };
                                                        }
                                                    }

                                                    setAllVehicleLead(temp);
                                                }}
                                                onNewMessage={(msg) => {
                                                    // var data = [...msg,...setAllBids]
                                                    // console.log(`onNewMessage`,JSON.stringify(data))
                                                    // setAllVehicleLead(data)
                                                    // console.log(`get Cell onNewMessage * `,setallbids?.length())
                                                    // var dataTemp = [...msg?.data]
                                                    var data = [];
                                                    // setAllBids(data);
                                                    // data.push(msg?.data?.data);
                                                    // data = [
                                                    //     ...data,
                                                    //     ...setallbids,
                                                    // ];
                                                    showBids(
                                                        msg?.data?.data
                                                            ?.vehicle_id,
                                                        0,
                                                        item
                                                    );
                                                }}
                                            />
                                        ))
                                       
                                        ) : (
                                          <>
                                              {isNoData ? (
                                                  <NoLead shimmer={shimmerEffect} />
                                              ) : (
                                                  <Nodata />
                                              )}
                                          </>
                                      )}
                                        </>
                                    )}
                                   
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <span style={{ marginTop: "100px" }}>
                <Modal
                    className="bid_modal"
                    show={show}
                    onHide={handleClose}
                    style={{ marginTop: "40px" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bid Ledger</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <div className="transactionhistory_mandiv1">
                                <div className="history_titlebox"></div>
                                <div className="history_detilsbox">
                                    {setallbids === null ? (
                                        <NoLead shimmer={shimmerEffect} />
                                    ) : setallbids?.length === 0 ? (
                                        <Nodata />
                                    ) : (
                                        setallbids?.map((item, index) => (
                                            <ul key={index}>
                                                <li>
                                                    <p>{item?.fulldate}</p>
                                                </li>
                                                <li>
                                                    <span>{item?.name}</span>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <span className="">
                                                        {" "}
                                                        ${item?.amount}
                                                    </span>
                                                </li>
                                            </ul>
                                        ))
                                    )}
                                </div>
                            </div>
                        </>
                    </Modal.Body>
                </Modal>
            </span>

            {/* Detail Model start */}
            <span style={{ marginTop: "100px" }}>
                <Modal
                    className="detail_modal"
                    show={setdetailmodelshow}
                    onHide={handleDetailModelClose}
                    style={{ marginTop: "40px" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Lead Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <div className="leftcustomers_detils vehical_listmandiv">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="sliderinner_box">
                                            <div className="inner_matchtext">
                                                {leadData === null ? (
                                                    <NoLead
                                                        shimmer={shimmerEffect}
                                                    />
                                                ) : leadData?.length === 0 ? (
                                                    <Nodata />
                                                ) : (
                                                    <>
                                                        <div className="sliderparent">
                                                            {leadData?.sliderImages ===
                                                            null ? (
                                                                <></>
                                                            ) : leadData?.sliderImages ===
                                                              0 ? (
                                                                <></>
                                                            ) : (
                                                                <SimpleImageSlider
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    height={370}
                                                                    images={
                                                                        leadData?.sliderImages
                                                                    }
                                                                    showNavs={
                                                                        true
                                                                    }
                                                                    navMargin={
                                                                        0
                                                                    }
                                                                    bgColor={
                                                                        "#fff"
                                                                    }
                                                                />
                                                            )}
                                                        </div>

                                                        <div className="inner_matchtext">
                                                            <h3>
                                                                {
                                                                    leadData?.first_name
                                                                }{" "}
                                                                {
                                                                    leadData?.last_name
                                                                }
                                                            </h3>
                                                            {Object.keys(
                                                                singleLeadData
                                                            ).map((item) =>
                                                                singleLeadData?.[
                                                                    item
                                                                ] === null ||
                                                                singleLeadData?.[
                                                                    item
                                                                ] === "" ? (
                                                                    <></>
                                                                ) : (
                                                                    <p
                                                                        key={
                                                                            item
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                item
                                                                            }
                                                                            :
                                                                        </span>{" "}
                                                                        {
                                                                            singleLeadData?.[
                                                                                item
                                                                            ]
                                                                        }
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="inner_matchtext">
                                                            {Object.keys(
                                                                singleLeadData1
                                                            ).map((item) =>
                                                                singleLeadData1?.[
                                                                    item
                                                                ] === null ||
                                                                singleLeadData1?.[
                                                                    item
                                                                ] === "" ? (
                                                                    <></>
                                                                ) : (
                                                                    <p
                                                                        key={
                                                                            item
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                item
                                                                            }
                                                                            :
                                                                        </span>{" "}
                                                                        {
                                                                            singleLeadData1?.[
                                                                                item
                                                                            ]
                                                                        }
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="inner_matchtext">
                                                            {Object.keys(
                                                                singleLeadData2
                                                            ).map((item) =>
                                                                singleLeadData2?.[
                                                                    item
                                                                ] === null ||
                                                                singleLeadData2?.[
                                                                    item
                                                                ] === "" ? (
                                                                    <></>
                                                                ) : (
                                                                    <p
                                                                        key={
                                                                            item
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                item
                                                                            }
                                                                            :
                                                                        </span>{" "}
                                                                        {
                                                                            singleLeadData2?.[
                                                                                item
                                                                            ]
                                                                        }
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="leadimagediv">
                                                            <div className="row">
                                                                {Object.keys(
                                                                    pics
                                                                ).map((item) =>
                                                                    pics?.[
                                                                        item
                                                                    ] === null ||
                                                                    pics?.[
                                                                        item
                                                                    ] === "" ? (
                                                                        <></>
                                                                    ) : (
                                                                        <div
                                                                            className="col-sm-4"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    item
                                                                                }
                                                                                :
                                                                            </span>

                                                                            <img
                                                                                src={
                                                                                    pics?.[
                                                                                        item
                                                                                    ]
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal.Body>
                </Modal>
            </span>
            {/* Detail Model End */}
            <ToastContainer />
        </>
    );
    // }
};

export default VehicleLead;

const VehicleLeadCell = (props) => {
    const [amount, setAmount] = useState("");

    useEffect(() => {
        getBid((message) => {
            console.log(`get Cell`);
            props?.onNewMessage(message);
        });
    }, []);

    useEffect(() => {
        getSingleBid((message) => {
            console.log(`get single bid`);
            props?.getSingleBid(message);
        });
    }, []);

    return (
        <div className="col-lg-4" key={props?.index}>
            <div className="sliderinner_box">
           
                <SimpleImageSlider
                    width="95%"
                    height={250}
                    images={props?.item.sliderImages}
                    showNavs={true}
                    navMargin={0}
                    bgColor={"#fff"}
                />
                {/* {props?.item?.room_id} */}
                <div className="cartext_details">
                    <h3>
                        {props?.item?.year} {props?.item?.make}{" "}
                        {props?.item?.model}
                    </h3>

                    {/* {props?.item?.minimum_bid_amount !== null &&
                    props?.item?.minimum_bid_amount !== "" ? (
                        <p>
                            Minimum Bid:
                            <span> ${parseFloat(props?.item?.minimum_bid_amount || "0").toFixed(2)}</span>
                        </p>
                    ) : null} */}

                   
                    {props?.item?.maxbid?.amount !== null &&
                    props?.item?.maxbid?.amount !== "" ? (
                        <p>
                            Latest Bid:
                            <span> ${parseFloat(props?.item?.maxbid?.amount || "0").toFixed(2)}</span>
                        </p>
                    ) : null}

                    {props?.item?.kms !== null && props?.item?.kms !== "" ? (
                        <p>
                            Odometer:
                            <span> {props?.item?.kms} KMS</span>
                        </p>
                    ) : null}

                    {props?.item?.street !== null &&
                    props?.item?.street !== "" ? (
                        <p>
                            Location:
                            <span> {props?.item?.street}</span>
                        </p>
                    ) : null}

                    {props?.item?.vin !== null && props?.item?.vin !== "" ? (
                        <p>
                            VIN:
                            <span> {props?.item?.vin}</span>
                        </p>
                    ) : null}


                    {props?.item?.city !== null && props?.item?.city !== "" ? (
                        <p>
                            City:
                            <span> {props?.item?.city}</span>
                        </p>
                    ) : null}
                </div>
                <div className="preview_btndiv">
                    <a
                        href="javascript:void(0)"
                        onClick={() => props?.onShowDetail(props?.item?.id)}
                    >
                        Preview More
                    </a>
                    {/* <Link to={`/vehiclelead/${props?.item?.id}`}>
                    Preview More
                </Link> */}

                    <a
                        href="javascript:void(0)"
                        onClick={() => props?.onShowBids(props?.item?.id)}
                    >
                        Bid Ledger
                    </a>
                </div>
                <div className="pickup_inputbox">
                    {/* <h4>
                        Pick-up or Drop-off: <span>Drop off</span>
                    </h4> */}
                    <input
                        type="number"
                        name=""
                        pattern="[0-9]*"
                        placeholder="$"
                        // value={amount}
                        onChange={(e) => {
                            setAmount(e.target.value);
                        }}
                    />

                    <a
                        href="javascript:void(0)"
                        onClick={() => {
                            props?.onPlaceBid(amount);
                            // setAmount("")
                        }}
                    >
                        Place Bid
                    </a>
                </div>

                <TimmerVehicleView
                    date={props?.item?.end_timer_date}
                    onRenderUi={(time) => {
                        <p>{time}</p>;
                    }}
                />
            </div>
        </div>
    );
};
