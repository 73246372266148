import React, { useEffect, useState } from 'react';
import { BrowserRouter,Routes,Route } from "react-router-dom";
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css';
import RoutesModule from './routes/route'
import { constant } from './utils/constant';

const  App = props => {
  const [visible,setVisible] = useState(false);
  useEffect(()=>{
    let token = localStorage.getItem("user_token");
    setVisible(token != null && token != "")
    constant.USERTOKEN = token;
  },[props]);

  //staging : basename="/dealerhop_web/"  remove in live
  return (
      <BrowserRouter> 
          <>
              <RoutesModule/>
          </>
      </BrowserRouter>
  );
}

export default App;
