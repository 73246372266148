const NoLead = props => {
    const numbers = [1, 2, 3, 4, 5];

    return (
        numbers.map((number,index) =>
            <div className="col-lg-4" key={index}>
                <div className={`match_boxmandiv ${props?.shimmer}`}>
                    <h3></h3>
                    <span></span>						
                    <div className="inner_matchtext">
                    
                    </div>
                    <div className="inner_matchtext">
                       
                    </div>
                    <div className="inner_matchtext">
                    
                    </div>
                    <div className="inner_matchtext">
                    
                    </div>
                   
                </div>
            </div>
        )

    );
}
export default NoLead;