import React, { useState, useEffect } from "react";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import SubHeader from "../../views/my_account/header";
import axios from "axios";
import { AppImages, constant,checkAuth } from "../../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../views/layouts/header";
import Loader from "../layouts/loader";
import NoHistory from "./no-history";
import Nodata from "./nodatafound";
import { Toastify } from '../../utils/Common';
import { Modal,Button } from "react-bootstrap";
import InputMask from 'react-input-mask';



// class AccountSetting extends Component{
const AccountSetting = () => {
    // render() {
    const [title, setTitle] = useState("Dealerhop | LINK");
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    // const [isLoading, setIsLoading] = useState(false);
    const [applylink, setApplyLink] = useState("");
    const [sellLink, setSellLink] = useState("");
    const [isNoData, setIsNoData] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [dealerLogo, setDealerLogo] = useState("");
    const [isScubscribe, setIsSubscribe] = useState(true);
    const [isScubscribeCancelled, setIsScubscribeCancelled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCardAdded, setIsCardAdded] = useState(0);

    const [show, setShow] = useState(false);


    const [card_number, setCardNumber] = useState("");
    const [errMsgCardNumber, setErrMsgCardNumber] = useState("");
    const [exp_date, setExpDate] = useState("");
    const [errMsgExpDate, setErrMsgExpDate] = useState("");
    const [cvv, setCvv] = useState("");
    const [errMsgCvv, setErrMsgCvv] = useState("");
    const [amount, setAmount] = useState(3500);
    const [errMsgAmount, setErrMsgAmount] = useState("");
    const [mywallet, setMyWallet] = useState("");


    useEffect(() => {
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
    }, []);

    const handleClose = () => setShow(false);
    

    useEffect(() => {
        getProfile();
        document.title = title;
    }, []);

    const handleShow = () => {
        navigator.clipboard.writeText(applylink);
        toast.success("Link copied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleShowsell = () => {
        navigator.clipboard.writeText(sellLink);
        toast.success("Link copied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const getProfile = async () => {
        setShimmerEffect("shimmerEffect");
        // const USERTOKEN =  localStorage.getItem('user_token');
        axios({
            method: "GET",
            url: constant.GETDEALERLINK,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                if (response.data.status === true) {
                 console.log('response?.data?.isScubscribe')
                 console.log(response?.data?.isScubscribe)
                    setIsNoData(false);
                    setApplyLink(response.data.apply_link);
                    setSellLink(response.data.sell_link);
                    setDealerLogo(response.data.dealer_logo);
                    setIsCardAdded(response.data.is_card_add);
                    setIsSubscribe(
                        response?.data?.isScubscribe != undefined
                            ? response?.data?.isScubscribe
                            : true
                    );
                    setIsScubscribeCancelled(
                        response?.data?.isScubscribeCancelled != undefined
                            ? response?.data?.isScubscribeCancelled
                            : true
                    );
                }
                setShimmerEffect("");
            })
            .catch((error) => {
                setIsNoData(false);
                setShimmerEffect("");
                console.log("error call ", error);
            });
    };

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Update the state with the selected image
            setSelectedImage(file);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        handleImageUpload(event.target.files[0]);
    };

    const handleImageUpload = async (selectedFile) => {
        setIsUploading(true);
        if (!selectedFile) {
            setIsUploading(false);
            toast.error("Please select an image to upload.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        try {
            const formData = new FormData();
            formData.append("image", selectedFile);

            axios
                .post(constant.CHNAGEDEALERLOGO, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: localStorage.getItem("user_token"),
                    },
                })
                .then((response) => {
                    if (response.data.status) {
                        //   console.log(response.data.dealer_logo)
                        setDealerLogo(response.data.dealer_logo);
                        //   toast.success(response.data.message, {
                        //       position: "top-right",
                        //       autoClose: 5000,
                        //       hideProgressBar: false,
                        //       closeOnClick: true,
                        //       pauseOnHover: true,
                        //       draggable: true,
                        //       progress: undefined,
                        //   });
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                    setIsUploading(false);
                })
                .catch((error) => {
                    setIsUploading(false);
                    throw error;
                });
        } catch (error) {
            console.error("Error uploading image:", error);
            setIsUploading(false);
        }
    };
    
    
    const startSubscritpion = async() => {
        setIsLoading(true);
      const res = await axios({
        method: 'POST',
        url: constant.STARTSUBSCRIPTION,
        headers: {
          Authorization: localStorage.getItem('user_token'),
        },
      }).then((response) => {
        if(response.data.status == true){
            getProfile();
            setIsLoading(false);
        }else {
          Toastify("Something went wrong please try again",'warn');
          setIsLoading(false);
        }
      }).catch(err => {
        Toastify("Something went wrong please try again",'error');
        setIsLoading(false);
        console.log(err);
      });
    }
   
    const cancelSubscritpion = async() => {
        setIsLoading(true);
      const res = await axios({
        method: 'POST',
        url: constant.CANCELSUBSCRIPTION,
        headers: {
          Authorization: localStorage.getItem('user_token'),
        },
      }).then((response) => {
        if(response.data.status == true){
            getProfile();
            setIsLoading(false);
        }else {
          Toastify("Something went wrong please try again",'warn');
          setIsLoading(false);
        }
      }).catch(err => {
        Toastify("Something went wrong please try again",'error');
        setIsLoading(false);
        console.log(err);
      });
    }

    //ADD CARD
    const addPaymentCard = async() => {
        var i = 0;
        setErrMsgCardNumber('');
        setErrMsgExpDate('');
        setErrMsgCvv('');

        if(card_number.length == 0 ){
            i++;
            setErrMsgCardNumber('This field is required.');
        }

        if(cvv.length == 0 ){
            i++;
            setErrMsgCvv('This field is required.');
        }

        if(exp_date.length == 0 ){
            i++;
            setErrMsgExpDate('This field is required.');
        }

        if(i == 0){
            setIsLoading(true)
            const res = await axios({
                method: 'POST',
                url: constant.ADDCARD,
                data:{
                    'card_number':card_number,
                    'exp_date':exp_date,
                    'cvv':cvv
                },
                headers: {
                  Authorization: localStorage.getItem('user_token'),
                },
              }).then((response) => {
                setIsLoading(false);
                checkAuth(response);
                if(response.data.status == true){

                    let hd = JSON.parse(localStorage.getItem('header_data'));
                    var header_data={
                     name: hd.name,
                     matched: hd.matched,
                     fulfilled: hd.fulfilled,
                     wallet: response.data.total_credits,
                 };
                 setMyWallet(response.data.total_credits);
                 localStorage.setItem("header_data",JSON.stringify(header_data));

                    getProfile();
                    Toastify(response.data.message,'success');
                    setCardNumber('');
                    setCvv('');
                    setShow(false);


                  

                } else {
                    // ErrorManage(response);
                    Toastify(response.data.message,'warn');  
                    // console.log(response.data);
                }
            }).catch(err => {
                checkAuth(err);
                Toastify("Something went wrong please try again",'error');
                console.log(err);
            });
        }
    }

   
    return (
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass1">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <SubHeader />
                    <div className="myaccount_mandiv">
                        {/* Check is user has subscribe */}
                        {!isScubscribe ? (
                            <div class="create_innerboxdiv">
                                <h2>Subscribe Now!</h2>
                                <p class="accountbalance_textbox">
                                Subscribe and generate leads by white-labelling our application forms with your logo.
                                </p>
                                <div className="coverdivsubscribe">
                                <div className="dayfree_text">
                                    <h3>
                                        Start Exploring with 30 Days Free, Then
                                        $299 Monthly
                                    </h3>
                                    <p>
                                        $299/<span>Month</span>
                                    </p>
                                </div>

                                <div className="createaccount_btn">
                                {isCardAdded ? (

                                    <a href={void 0} onClick={()=>startSubscritpion()}>
                                        Make A Payment
                                        {isLoading == true ? (
                                    <Loader />
                                ) : null}
                                    </a>
                                 
                                 ) : (
                                        <a href={void(0)} onClick={() => setShow(true)}>
                                            Make A Payment
                                        </a>
                                    )}

                                </div>

                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="create_detailsbox">
                                    <div className="create_innerboxdiv">
                                        <div className="refer_textbox">
                                            <h3>Your Brand, Our Strategy</h3>
                                            <p>
                                                Upload your logo and take
                                                advantage of the high-intent
                                                quality of a Dealerhop lead form
                                                using the power of Two-Factor
                                                Authentication.
                                            </p>
                                        </div>

                                        <div className="account_inputbox">
                                            {/* <p className={shimmerEffect}>Dealer Logo</p> */}
                                        </div>

                                        <div className="customelogo">
                                            <input
                                                id="file"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                            <label for="file">
                                                Click to uplod logo
                                            </label>
                                            {}
                                            {dealerLogo != "" ? (
                                                <img
                                                    className={`greentik ${shimmerEffect}`}
                                                    src={AppImages.greentik}
                                                />
                                            ) : null}
                                        </div>

                                        <div className="referral_linkbox setselltrade_box">
                                            <h3>Get Approved link:</h3>
                                            <p>
                                                Use the link below to generate
                                                Finance Leads using your
                                                website, social media profiles,
                                                and more.
                                            </p>
                                            <a
                                                onClick={handleShow}
                                                className={shimmerEffect}
                                                href="javascript:void(0)"
                                            >
                                                {applylink}
                                            </a>
                                        </div>

                                        <div className="referral_linkbox setselltrade_box">
                                            <h3>Sell/Trade link:</h3>
                                            <p>
                                                Use the link below to generate
                                                Sell/Trade Leads using your
                                                website, social media profiles,
                                                and more.
                                            </p>
                                            <a
                                                onClick={handleShowsell}
                                                className={shimmerEffect}
                                                href="javascript:void(0)"
                                            >
                                                {sellLink}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {/* Button for cancel subscription */}

                                {(dealerLogo != '' && isScubscribe && !isScubscribeCancelled) && <> <div class="cancelsubscritpion"><a href={void 0} onClick={()=>cancelSubscritpion()}>Cancel Subscription
                                                
                                {isLoading == true ? (
                                    <Loader />
                                ) : null}
                                </a></div> </>}
                            </>
                        )}
                    </div>
                </div>
                <Footer />
            </div>



            <div className="addpayment_modalmandiv">
                        <Modal show={show} onHide={handleClose} size="md">
                            <Modal.Header closeButton>
                                {/* <button type="button" className="btn-close" data-dismiss="modal">
                                <img src={ require('../../assets/svg/hamburger-close.svg').default } />
                            </button> */}
                                <h4 className="modal-title">
                                    Add Payment Method
                                </h4>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div id="newCardBox">
                                        <div className="card_typeinputbox">
                                            <p>Card Number* </p>
                                            <InputMask
                                                name="card_number"
                                                value={card_number}
                                                mask="9999-9999-9999-9999"
                                                onChange={(e) =>
                                                    setCardNumber(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="XXXX - XXXX - XXXX - XXXX - XXXX"
                                            />
                                            <img
                                                src={
                                                    require("../../assets/svg/Credit-Card-icon.svg")
                                                        .default
                                                }
                                            />
                                            <span className="text-danger">
                                                {errMsgCardNumber}
                                            </span>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card_typeinputbox">
                                                    <p>CVV * </p>
                                                    <InputMask
                                                        type="text"
                                                        className="form-control mask"
                                                        name="cvv"
                                                        mask="9999"
                                                        value={cvv}
                                                        onChange={(e) =>
                                                            setCvv(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="e.g. 123"
                                                    />
                                                    <img
                                                        src={
                                                            require("../../assets/svg/Credit-Card-icon.svg")
                                                                .default
                                                        }
                                                    />
                                                    <span className="text-danger">
                                                        {errMsgCardNumber}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card_typeinputbox">
                                                    <p>Expiry Date* </p>
                                                    <InputMask
                                                        type="text"
                                                        className="form-control mask"
                                                        name="exp_date"
                                                        value={exp_date}
                                                        mask="99/99"
                                                        placeholder="MM/YY"
                                                        onChange={(e) =>
                                                            setExpDate(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <img
                                                        src={
                                                            require("../../assets/svg/Credit-Card-icon.svg")
                                                                .default
                                                        }
                                                    />
                                                    <span className="text-danger">
                                                        {errMsgExpDate}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="savepayment_divbox">
                                        <a href={void 0} onClick={()=>addPaymentCard()}>
                                            SAVE PAYMENT METHOD
                                            {isLoading == true ? (
                                            <Loader />
                                        ) : null}
                                        </a>
                                        {/* <Button type="button">SAVE PAYMENT METHOD</Button> */}
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="secured_paytextbox">
                                    <p>
                                        {" "}
                                        <img
                                            src={
                                                require("../../assets/svg/secured.svg")
                                                    .default
                                            }
                                        />{" "}
                                        All payments are powered and secured by
                                        Stripe
                                    </p>
                                    <img
                                        className="Stripeicon_div"
                                        src={
                                            require("../../assets/svg/Stripe-icon.svg")
                                                .default
                                        }
                                    />
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
            <ToastContainer />
        </>
    );
    // }
};

export default AccountSetting;

const RefferalHistoryCell = (props) => {
    return (
        <ul>
            <li className="cmnwidthref">
                <span>{props?.item?.refer_date}</span>
            </li>
            <li className="cmnwidthmsg">
                <span>{props?.item?.message}</span>
            </li>
            <li className="cmnwidthamount">
                <span>${props?.item?.amount}</span>
            </li>
        </ul>
    );
};
