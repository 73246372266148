import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { AppImages, checkAuth, constant } from "../../utils/constant";
import Header from "../layouts/header";
import Loader from "../layouts/loader";
import { Toastify } from "../../utils/Common";
import { ToastContainer, toast } from "react-toastify";

// import { verifyOtp } from './api';
// import Input from './Input';

// export default function Verify({ enabled }) {
const Verify = (props) => {
    const [QRCode, setQRCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [verificatioCode, setVerificatioCode] = useState("");
    const [errMsgVerificatioCode, setErrMsgVerificatioCode] = useState("");
    const [redirectPath, setRedirectPath] = useState("");
    const [userData, setUserData] = useState("");


    useEffect(() => {
        // getProfile();
        let userData = JSON.parse(localStorage.getItem("userData")).data;
        setUserData(userData);
    }, []);

    const addSecrectKey = async () => {
        var i = 0;
        setErrMsgVerificatioCode('');
        
        if(verificatioCode.length == 0 ){
          i++;
          setErrMsgVerificatioCode('This field is required.');
        }

        if(i == 0){
          setIsLoading(true);
          const res = await axios({
            method: "POST",
              url: constant.OTPVERIFY,
              data: {
                otp: verificatioCode,
              },
              headers: {
                  Authorization: localStorage.getItem("user_token"),
              },
          })
          .then((response) => {            
            //   setIsLoading(false);
              if (response.data.status == true) {
                  localStorage.setItem("login_verify",'true');
                    // setIsRedirect(true);
                    const step = userData.step;
                    if(step == 2 || step == 1){
                        setRedirectPath("/step/contact_info");
                        setIsRedirect(true);
                    } else if(step == 3){
                        setRedirectPath("/step/account_balance");
                        setIsRedirect(true);
                    } else if(step == 4){
                        // alert(step);
                        setRedirectPath("/available_customers");
                        setIsRedirect(true);
                    }
              } else {
                  setErrMsgVerificatioCode(response.data.message);
                  setIsLoading(false);
              }
          })
          .catch((error) => {
                setIsLoading(false);
                Toastify("Something went wrong please try again", "error");
          });
        }
    };

    return (
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass">
                {isRedirect == true ? <Navigate to={redirectPath} /> : null}

                <div className="vertical_logobox">
                     <img src={ AppImages.logo_white }  />                    
                </div>
                <div className="rught_sidebox">
                    <div className="create_detailsbox mg-t170">
                        <div className="create_innerboxdiv">
                            <h2>Login Verification Code.</h2>
                            {/* <p>Please enter the  <strong>OTP</strong> generated on your Authenticator App. <br/> Ensure you submit the current one because it refreshes every 30 seconds.</p> */}
                            <p>Verification code has been sent to your email and mobile number</p>
                            <div className="account_inputbox">
                                <p>Verification Code*</p>
                                <input type="number" 
                                  name="verificatio_code"
                                  placeholder="Enter verification code"
                                  onChange={(e) => {
                                    setVerificatioCode(e.target.value);
                                  }}
                                  value={verificatioCode}
                                />
                                <span className="text-danger">{errMsgVerificatioCode}</span>
                            </div>
                            <div className="createaccount_btn">
                                <a
                                    href={void 0}
                                    onClick={() => addSecrectKey()}
                                >
                                    Submit
                                    {isLoading == true ? <Loader /> : null}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );

    // return (<>
    //     <div>
    //       {QRCode}
    //     </div>
    //   </>
    // );
};

export default Verify;
