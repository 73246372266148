import { Alert, Button } from "react-bootstrap";
import React, { Component} from "react";
import { Link,Navigate } from "react-router-dom";
import axios from "axios";
import Loader from './../layouts/loader'
import { AppImages, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paper from "@material-ui/core/Paper";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import Header from "../layouts/header";

class ProfileStatus extends Component {

            constructor(props) {
                super(props);
                this.state = {
                    status:  "",
                    referral_dealerhip_name:  "",
                    referal_from_user: "",
                    shimmerEffect:"shimmerEffect",
                };

    
                this.getContactData();        
    }
    getContactData = async () => {
  
        const USERTOKEN =  localStorage.getItem('user_token');       
      axios({
            method: 'GET',
            url: constant.GETPROFILE,
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
          })
           .then((response) => {
               
                console.log('response ', response);
               
                                                   
                setTimeout(() => {
                    this.setState({shimmerEffect:''});        
                  }, 300);
               
                if(response.data.status == true){  
                    
                
                this.setState({status:response.data.data.status});                            
                localStorage.setItem("userData", JSON.stringify(response.data));
                    
                }
                
            })
            .catch((error) => {        
               
                this.setState({shimmerEffect:''});                                            
                console.log('error call ', error);                  
            });
    }
 
 

    render() {
        document.title = "Dealerhop | Profile Status";

        // if (this.state.isRedirect) {
        //     return <Navigate  to="/" />;
        // }

        
        const userdata1 = localStorage.getItem("userData");
        const userdata =JSON.parse(userdata1);    
        
        
        let step =0;
        let is_approved_by_admin =0;

        // console.log(userdata)
        if(userdata){
            step = (userdata.data.step);    
            is_approved_by_admin = (userdata.data.is_approved_by_admin);  
            
        }

        // if(step == 2 || step == 1){
        //     return <Navigate  to="/step/contact_info" />;
        // }else if(step == 3){
        //     return <Navigate  to="/step/account_balance" />;
        // }else if(step == 4){
        //     return <Navigate  to="/available_customers" />;
        // }else{}

        // if (this.state.redirect) {
        //     return <Navigate  to="/available_customers" />;
        //   }
        // const login = localStorage.getItem("isLoggedIn");
      
        // if (login) {
        //   return <Navigate to="/available_customers" />;
        // }

        if(is_approved_by_admin == 1){
              return <Navigate to="/available_customers" />;
        }
      
        

        return (
            <>
            
            <Header />
                <div className="vertical_logobox">
                        <img src={ AppImages.logo_white }  />
                </div>
                <div className="rught_sidebox">
                    <div className="create_detailsbox">
                        <div className="create_innerboxdiv account_profileset">

                        

                        

                        {this.state.status == "Reject"
                         ?    
                         <>
                         <img src={ require('../../assets/svg/sad.svg').default } />
                            <h3 className={this.state.shimmerEffect}>Your account has been  rejected.</h3>
                         <p className={this.state.shimmerEffect}>
                            Your profile has been rejected. Please contact administrator for further information.
                         </p>
                         </>
                            :
                            <>
                            <img src={ require('../../assets/svg/smile.svg').default } />
                            <h3 className={this.state.shimmerEffect}>Your account is pending.</h3>
                            <p className={this.state.shimmerEffect}>Your account has been submitted for approval. You will receive a notification by email when your account is approved. We may contact you if we have any further questions regarding the information provided. 
                            </p>
                            </>
                        
                        }

                        <a className={this.state.shimmerEffect} href="https://www.inculeader.com/book-a-call-with-andrew-hutchinson-from-inculeader" target="_blank" >Book a call </a>
                        
                         {/* {this.state.status 
                         ?
                         <h2 className={this.state.shimmerEffect}>Profile Status : {(this.state.status == "Reject") ? "Rejected" : this.state.status }</h2>
                         :
                         <h2 className={this.state.shimmerEffect}></h2>
                        } */}
                            
                               
                            
                        </div>
                       
                        <ToastContainer />
                    </div>
                </div>
            </>
        );
    }
}

export default ProfileStatus;
