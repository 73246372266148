import React,{ Component } from "react";
import {Link } from 'react-router-dom';
import { AppImages, checkAuth, constant } from "../../utils/constant";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Navigate } from 'react-router-dom';
import Header from "../layouts/header";
import Loader from "../layouts/loader";


class ContactInfo extends Component{
    
    constructor(props) {
        
        super(props);
        this.state = {
        
         phone: "",
         dealership_phone: "",
         dealership_first_name: "",
         dealership_last_name: "",              
         err_phone_msg: "",
         err_dealership_phone_msg: "",
         err_dealership_first_name_msg: "",
         err_dealership_last_name_msg: "",
          
         isLoading: false,
         redirect: false,
          
        };
        
        this.onChangehandler =this.onChangehandler.bind(this);
      }


       //on chnage event 
       onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        
        
      };
    
    
    onContactInfoHandler = (e) => {
        
       let error = 0;
        e.preventDefault();
        this.setState({
            err_phone_msg: "",
            err_dealership_phone_msg: "",
            err_dealership_first_name_msg: "",
            err_dealership_last_name_msg: "",
          }); 

        if(this.state.phone ===""){
            error++;
            this.setState({
                err_phone_msg: "The phone field is required",
              });                    
        }        
        if(this.state.dealership_phone===""){
            error++;
            this.setState({
                err_dealership_phone_msg: "The dealership phone field is required",
            });                    
        }
        if(this.state.dealership_first_name===""){
            error++;
            this.setState({
                err_dealership_first_name_msg: "The dealership first name field is required",
              });                    
        }
        if(this.state.dealership_last_name===""){
            error++;
            this.setState({
                err_dealership_last_name_msg: "The dealership last name field is required",
              });                    
        }
      
         
        
       
        if(error === 0){
            
        this.setState({isLoading:true});
            
            // return <Navigate  to="/step/account_balance" />;
          
          const USERTOKEN =  localStorage.getItem('user_token');
       
          axios({
                method: 'POST',
                url: constant.UPDATECONTACTINFO,
                params: {
                    phone: this.state.phone,
                    dealership_phone: this.state.dealership_phone,
                    dealership_first_name: this.state.dealership_first_name,
                    dealership_last_name: this.state.dealership_last_name,  
                },
                headers: {
                  Authorization: USERTOKEN,
                },
              })
               .then((response) => {
                    checkAuth(response);

                    this.setState({isLoading:false});
                    console.log('response ', response);
                    if(response.data.status === true){  
                        localStorage.setItem("userData", JSON.stringify(response.data));
                        this.setState({redirect:true})                      
                        // this.state.redirect = true;
                    }

                })
                .catch((error) => {     
                    checkAuth(error);
                    this.setState({isLoading:false});
                    console.log('error call ', error);                  
                });
      
        }
        
    
    }


    render() {
        document.title = "Dealerhop | Contact Information";
       
        if(this.state.redirect) {       
            
            return (
                <Navigate to="/step/account_balance" replace={true} />
            )    
        }

        return (
            <>
                <Header/>
                <div className="contentCoverbox setfooter_addclass">

                    <div className="vertical_logobox">
                    <img src={ AppImages.logo_black }  />
                    </div>
                    <div className="rught_sidebox">
                        <div className="create_detailsbox">
                            <div className="create_innerboxdiv">
                                <h2>Your Contact Info.</h2>
                                <div className="account_inputbox">
                                    <p>Your Mobile Number*</p>
                                    <input type="number" name="phone" placeholder="Enter Mobile Number" onChange={this.onChangehandler} />
                                    <span className="text-danger">{this.state.err_phone_msg}</span>
                                </div>
                                <div className="account_inputbox">
                                    <p>Dealership Phone Number*</p>
                                    <input type="number" name="dealership_phone" placeholder="Enter Dealership Phone Number" onChange={this.onChangehandler}/>
                                    <span className="text-danger">{this.state.err_dealership_phone_msg}</span>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>First Name* </p>
                                            <input type="text" name="dealership_first_name" placeholder="Enter First Name" onChange={this.onChangehandler}/>

                                            <span className="text-danger">{this.state.err_dealership_first_name_msg}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>Last Name*</p>
                                            <input type="text" name="dealership_last_name" placeholder="Enter Last Name" onChange={this.onChangehandler}/>
                                            <span className="text-danger">{this.state.err_dealership_last_name_msg}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="createaccount_btn">
                                    <Link to="/" onClick={this.onContactInfoHandler}>
                                        Next{
                                            this.state.isLoading ?
                                            <Loader/>
                                            : <></> 
                                        }
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>

        )
    }
}

export default ContactInfo;