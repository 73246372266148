import React, { useEffect, useState } from 'react';
import { Routes,Route, Navigate, Outlet } from "react-router-dom";
import Login from './../views/auth/login';
import Register from './../views/auth/register';
import RefRegister from './../views/auth/refregister';
import ForgotPassword from './../views/auth/forgot_password';
import ResetPassword from './../views/auth/reset_password';
import StepContactInfo from './../views/step/contact_info';
import DealershipInfo from './../views/my_account/dealership_information';
import StepAccountBalance from './../views/step/account_balance';
import AccountBalance from './../views/my_account/account_balance';
import ProfileStatus from './../views/my_account/profilestatus';
import TransactionHistory from './../views/my_account/transaction_history';
import ContactInfoUpdate from './../views/my_account/contact_info_update';
import ReferAndEarn from './../views/my_account/referandearn';
import AccountSetting from './../views/my_account/account-setting';
import NotificationSettings from './../views/my_account/notifiationcity';
import ConnectCrm from './../views/my_account/connectcrm';
import OpenMarketPlace from './../views/leads/open-marketplace';
import OpenChat from './../views/leads/openchat';
import VehicleLead from './../views/leads/vehiclelead';
import VehicleLeadDetail from '../views/leads/vehicledetail';
import WonleadDetail from '../views/leads/wonledetail';
import WonLead from './../views/leads/wonlead';
import Dragged from './../views/allmach/dragged';
import DraggedVehicle from './../views/allmachvehicle/draggedvehicle';
import CurrentCustomers from './../views/leads/current-customers';
import Fulfilled from './../views/leads/fulfilled';
import AllLeads from './../views/leads/all-leads';
import Notification from './../views/notification/notification';
import LeadDetail from '../views/leads/current-customer-add';
import GoogleSignup from '../views/google/signup';
import UserList from '../views/user/list';
import AddUser from '../views/user/add';
import EditUser from '../views/user/edit'; 
import GenerateLeadLink from './../views/my_account/generateleadlink';




// import GoogleVerify from '../views/google/verify';
import Verify from '../views/auth/verify';



const RouteModule = props => {
  // const token = localStorage.getItem('user_token');
   
   const [user, setUser] = useState("");
   
   useEffect(()=>{
        const token1 = localStorage.getItem('user_token');
       setUser(token1);
    
},[])


   const ProtectedRoute = ({
    user,
    redirectPath = '/login',
    children,
  }) => {
    if (!user) {
        setTimeout(() => {            
            return <Navigate to={redirectPath} replace />;
        }, 100);
    }
    
  
    return children ? children : <Outlet />;
  };

  return (
        <>
            <Routes>
                   
                     <Route element={<ProtectedRoute user={user} />}>
                        <Route path='/google/signup' element={<GoogleSignup />}></Route>
                        {/* <Route path='/google/verify' element={<GoogleVerify />}></Route> */}
                        <Route path='/verify' element={<Verify />}></Route>
                        <Route path='/step/contact_info' element={<StepContactInfo />}></Route>
                        <Route path='/step/account_balance' element={<StepAccountBalance />}></Route>                   
                        
                        

                        <Route path='/account_balance' element={<AccountBalance />}></Route>
                        <Route path='/transaction_history' element={<TransactionHistory />}></Route>
                        <Route path='/account_settings' element={<AccountSetting />}></Route>
                        <Route path='/available_customers' element={<OpenMarketPlace />}></Route>
                        <Route path='/aiassistant' element={<OpenChat />}></Route>
                        <Route path='/vehiclelead' element={<VehicleLead />}></Route>
                        <Route path='/wonlead' element={<WonLead />}></Route>

                        <Route path='/crm' element={<Dragged />}></Route>
                        <Route path='/crmvehicle' element={<DraggedVehicle />}></Route>
                        
                        <Route path='/referandearn' element={<ReferAndEarn />}></Route>
                        <Route path='/generateleadlink' element={<GenerateLeadLink/>}></Route>
                        {/* <Route path='/open_marketplace' element={<OpenMarketPlace />}></Route> */}
                        <Route path='/current_customers' element={<CurrentCustomers />}></Route>
                        <Route path='/fulfilled' element={<Fulfilled />}></Route>
                        <Route path='/all_leads' element={<AllLeads />}></Route>
                        <Route path='/contact_information' element={<ContactInfoUpdate />}></Route>
                        <Route path='/dealership_information' element={<DealershipInfo />}></Route>
                        <Route path='/notifications' element={<Notification />}></Route>
                        <Route path='/lead/:type/:id' element={<LeadDetail />}></Route>
                        <Route path='/user/list' element={<UserList />}></Route>
                        <Route path='/user/add' element={<AddUser />}></Route>
                        <Route path='/user/edit/:id' element={<EditUser />}></Route>

                        <Route path='/vehiclelead/:id' element={<VehicleLeadDetail />}></Route>
                        <Route path='/wonlead/:id' element={<WonleadDetail />}></Route>
                        
                    </Route>
                   
                        <Route path='/' element={<Login />}></Route>
                        <Route path='/login' element={<Login />}></Route>
                        <Route path='/register' element={<Register />}></Route>
                        <Route path='/:dealershipname/:dealerid' element={<RefRegister />}></Route>
                        <Route path='/forgot_password' element={<ForgotPassword />}></Route>
                        <Route path='/reset_password' element={<ResetPassword />}></Route>
                        <Route path='/profilestatus' element={<ProfileStatus />}></Route>
                        <Route path='/notificationsettings' element={<NotificationSettings />}></Route>
                        <Route path='/connectcrm' element={<ConnectCrm />}></Route>
                   
                
                
                
                
            </Routes>
        </>
    );
}


export default RouteModule;
