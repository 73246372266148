import React,{ useEffect, useState } from "react";
import {Link,Navigate} from 'react-router-dom';
import $ from 'jquery';
import { AppImages } from "../../utils/constant";

const Header = props =>{

    const [name, setName] = useState("");
    const [matched, setMatched] = useState("");
    const [fulfilled, setFulfilled] = useState("");
    const [wallet, setWallet] = useState("");
    const [isStep, setIsStep] = useState(0);
    const [isRedirect, setIsRedirect] = useState(false);
    const [redirectPath, setRedirectPath] = useState("");
    const [hideheader, setHideHeader] = useState("");

    useEffect(() => {
        if(JSON.parse(localStorage.getItem("userData")) == null){
            setIsRedirect(true);
            setRedirectPath("/login");
        } else {
            

            let header_data = JSON.parse(localStorage.getItem("header_data"));
            let userData = JSON.parse(localStorage.getItem("userData")).data;
            setName(header_data.name);
            setMatched(header_data.matched);
            setFulfilled(header_data.fulfilled);
            setWallet(header_data.wallet);
            setIsStep(userData.step);
            const step = userData.step;
            const is_skip_payment = userData.is_skip_payment;
            
            const pathname = window.location.pathname;
            
            

            if(localStorage.getItem("login_verify") == "false" && pathname != '/verify' && pathname != '/signup'){
                // check2faManage((value)=> {
                //     if(value){
                //         setIsRedirect(true);
                //     }
                // })
                //  
                setRedirectPath("/verify")
            } else if((step == 2 || step == 1) && localStorage.getItem("login_verify") == "true" && pathname != '/step/contact_info'){
                setRedirectPath("/step/contact_info");
                setIsRedirect(true);
            } else if(step == 3  && localStorage.getItem("login_verify") == "true" && pathname != '/step/account_balance'){
                setRedirectPath("/step/account_balance");
                setIsRedirect(true);
            } else if(step == 4  && localStorage.getItem("login_verify") == "true"){
                // alert(step);
                // setRedirectPath("/available_customers");
                // setIsRedirect(true);
            }
            if(pathname == "/verify"){
                setHideHeader('verify');
            }else if(pathname == "/profilestatus"){
                setHideHeader('verify');
            }else if(pathname == "/dealerhop_web/profilestatus"){
                setHideHeader('verify');
            }
            else{
                setHideHeader('');
            }
            
        }
        
    },[]);

    const handleLogout = () => {
        localStorage?.clear();
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        localStorage.removeItem("user_token");
        localStorage.removeItem("header_data");
        localStorage.removeItem("login_verify");
        return <Navigate to="/login" />;
        // window.location.href="login"
    };

    const responsiveMenu = () => {
        $('.sidebar-toggle').toggleClass("is-closed");
        $('.sidebarmenu').toggleClass("is-closed");
    }


    return (
        <>
            {
                isRedirect == true ?
                    <Navigate to={redirectPath} /> :
                null
            }
            <button className="sidebar-toggle is-closed" onClick={ () => responsiveMenu() }>
                <img
                    className="menuiconset"
                    src={require("../../assets/svg/hamburger.svg").default}
                    alt=""
                />
                <img
                    className="menuiconcloseset"
                    src={require("../../assets/svg/hamburger-close.svg").default}
                    alt=""
                />
            </button>
            
            
            <header>
                <div className="top_headerdiv ai_header">
               
                    <div className={`top_logodiv ${(hideheader == 'verify') ? 'hidden1' : '' }`}>
                        <Link to='/available_customers'>
                            <img src={ AppImages.logo_black }  />
                        </Link>
                    </div>
                    
                    <div className={`header_lefttext ${(hideheader == 'verify') ? 'hidden1' : '' }`}>
                        
                    </div>
                    <div className="header_righttext">
                        <div className="header_balancetext">
                            {/* <p>
                            <Link className="show_balance_btn" to='/account_balance'>
                                
                                Available balance: <span>${
                                (wallet)
                                 ? (wallet)
                                 : 0
                                  }</span>
                            </Link>
                            </p> */}

                            <Link to="/login" onClick={handleLogout}>
                                Log Out
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;