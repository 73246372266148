/* eslint-disable no-unused-vars */
import React,{ useEffect, useState } from "react";

import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import axios from "axios";
import { checkAuth, constant } from "../../utils/constant";
import Header from "../layouts/header";
import { Link,Navigate } from "react-router-dom";
import DeleteConfirmation from "./DeleteConfirmation";
import { ToastContainer, toast } from 'react-toastify';
// class UserList extends Component {
const UserList = (props) => {
    const [title, setTitle] = useState("Dealerhop | User List");
    const [userList,setUserData] = useState([]);
    const [isNoData,setIsNoData] = useState(true)
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [id, setId] = useState(null);

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         histrylist: [],
    //         isNoData: true,
    //     };

    //     this.getMyUserList();
    // }
    useEffect(() => {
        setTimeout(() => {            
            document.querySelector('body').scrollTo(0,0);
        }, 100);
      }, []);
    useEffect(()=>{
        document.title = title;
        setTitle(title);
        var interval = setTimeout(() => {
                        getMyUserList();
                    }, 300);
        return () => clearInterval(interval);
    },[])


     // Handle the displaying of the modal based on type and id
  const showDeleteModal = (id) => {
    setId(id);
    setDisplayConfirmationModal(true);
  };

 // Hide the modal
 const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

//   Handle the actual deletion of the item
  const submitDelete = (id) => {
    
    setIsLoading(true);
    const USERTOKEN = localStorage.getItem("user_token");
    axios({
        method: "GET",
        url: constant.REMOVEUSER,
        headers: {
            Authorization: USERTOKEN,
        },
        params: {
            id: id,
        },
    })
        .then((response) => {
            setIsLoading(false);
            if (response.data.status === true) {
                getMyUserList();
                setDisplayConfirmationModal(false);
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((error) => {
             console.log("error call ", error);
        });

    setDisplayConfirmationModal(false);
  }


    const getMyUserList = async () => {
        setIsLoading(true);
        const USERTOKEN = localStorage.getItem("user_token");
        axios({
            method: "GET",
            url: constant.GETMYUSERLIST,
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                checkAuth(response);
                setIsNoData(false);
                setIsLoading(false);
                

                if (response.data.status === true) {
                    setLastPage(response.data.last_page)
                    setCurrentPage(response.data.current_page)
                    setUserData(response.data.data);
                    
                }
            })
            .catch((error) => {
                checkAuth(error);
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };
    
    const userdata1 = localStorage.getItem("userData");
    const userdata =JSON.parse(userdata1);    
    
    if(userdata.data.is_main_account === 0){
        return <Navigate  to="/" />;
    }

    if(userdata.data.is_approved_by_admin === 0){
        return <Navigate to="/available_customers" />;
    }

    return (

        
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass">
                <LeftSiderbar />
                <div
                    className="rightsidecontent">

                    <Link className="add_btn_user" to="/user/add">ADD</Link> 

                    <div className="myaccount_mandiv userlisttop_mandiv">
                        <div className="userlist_mandiv">
                            <div className="history_titlebox">
                                <h3>User List </h3>
                                
                            </div>
<table className="table">
    <thead>
        
    <tr>
    <th>Name</th>
    <th>Email</th>
    <th>Phone</th>
    <th>Action</th>
    </tr>
    </thead>
    <tbody>
        
    {userList.map((item, index) => {
return(
<tr>
<td>{item.name}</td>
<td>{item.email}</td>
<td>{item.phone}</td>
<td>
    
<Link to={`/user/edit/${item?.id}`}>
<img src={ require('../../assets/svg/edit.svg').default } alt="" />
</Link>
<a href="javascript:void(0)" className="text-danger cursor deletebtn" onClick={() => showDeleteModal(item?.id)}>
<img src={ require('../../assets/svg/delete.svg').default }  alt="" />
</a>


</td>
</tr>
)
                                   
                                })}

    </tbody>
</table>
<DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal}  id={id}   />
<ToastContainer />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default UserList;