/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import { Component, useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { checkAuth, constant } from "../../utils/constant";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import Header from "../../views/layouts/header";
import Nodata from "../layouts/nodatafound";
import NoLead from "./no-lead";
import LoadMore from "./loadMore";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import SimpleImageSlider from "react-simple-image-slider";

// class VehicleDetails extends Component{
const VehicleDetails = (props) => {
    const [title, setTitle] = useState("Dealerhop | Vehicle Lead Detail");
    const [customerLeads, setCustomerLeads] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isNoData, setIsNoData] = useState(true);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [province, setProvince] = useState([]);
    const [search_province, setSearchProvince] = useState([]);
    const params = useParams();
    const [id, setId] = useState(params.id);
    const [leadData, setLeadData] = useState(null);
    const [singleLeadData, setSingleLeadData] = useState(null);
    const [singleLeadData1, setSingleLeadData1] = useState(null);
    const [singleLeadData2, setSingleLeadData2] = useState(null);
    const [pics, setPics] = useState(null);

    const [show, setShow] = useState(false);
    const [emailModelshow, setEmailShow] = useState(false);
    
    useEffect(() => {
        setId(params.id);
        getCustomerLeadData();
        
    }, []);
    useEffect(() => {
        
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
    }, []);

    
    const getCustomerLeadData = async () => {
        setShimmerEffect("shimmerEffect");
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.VEHICLELEADDETAIL,
            params: {
                vehicle_lead_id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);

                // console.log("response ", response);
                if (response.data.status == true) {
                    
                    setLeadData(response.data.data);
                    setSingleLeadData(response.data.single_lead_data);
                    setSingleLeadData1(response.data.single_lead_data_1);
                    setSingleLeadData2(response.data.single_lead_data_2); 
                    setPics(response.data.pics);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    

    const handleShow = () => {
        setShow(true);
    };
    const handleEmailShow = () => {
        setEmailShow(true);
    };
    const handleClose = () => setShow(false);
    const handleEmailClose = () => setEmailShow(false);
 

    return (
        <>
            <Header />
            <div className="contentCoverbox newsetfooter_addclass">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="customers_boxdiv">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="customer_btndiv">
                                    <a
                                        href="javascript:void(0)"
                                        onClick={handleShow}
                                    >
                                        Text Customer
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={handleEmailShow}
                                    >
                                        Email Customer
                                    </a>

                                    <a
                                        href="javascript:void(0)"
                                        onClick={handleEmailShow}
                                    >
                                        Add Task
                                    </a>
                                
                                </div>
                               
                            </div>

                            
                                {leadData == null ? (
                                    <div className="col-md-12">
                                    <div className="leftcustomers_detils">
                                        <h3></h3>
                                        <span></span>

                                        <div className="row">
                                            <div className="col-lg-6" >
                                                <div className={`inner_matchtext shimmerEffect`}>
                                                    <p>&nbsp;</p>
                                                </div>
                                                
                                                <div className={`inner_matchtext shimmerEffect`} >
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>

                                                </div>

                                                <div className={`inner_matchtext shimmerEffect`}>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                    <p>&nbsp;</p>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-lg-6" >
                                                <div className={`inner_matchtext shimmerEffect`} >
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>
                                                <p>&nbsp;</p>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                          
                                </div>
                                ) :                            

                          
                                <div className="col-md-12">

                                    <div className="leftcustomers_detils vehical_listmandiv">
                                       
                                      

                                        <div className="row">
                                            
                                            <div className="col-lg-12">
                                            <div className="sliderinner_box">
                                            <div className="inner_matchtext sliderparent">
                                 <SimpleImageSlider
                    style={{width: '100%' }}                      
                    height={500}
                    images={leadData?.sliderImages}
                    showNavs={true}
                    navMargin={0}
                    bgColor={"#fff"}                     
                    />   
                                     
                                    
                    </div>
                  
                                           
                                            <div className="inner_matchtext">
                                            <h3>
                                            {leadData?.first_name}{" "}
                                            {leadData?.last_name}
                                        </h3>
                                            {
                                                Object.keys(singleLeadData).map(item=> singleLeadData?.[item] == null || singleLeadData?.[item] == '' ? <></> :
                                                    <p key={item}>
                                                        <span>{item}:</span> {singleLeadData?.[item]}
                                                    </p>
                                                )
                                            } 
                                            </div>
                                             
                                            <div className="inner_matchtext">
                                            {
                                                Object.keys(singleLeadData1).map(item=> singleLeadData1?.[item] == null || singleLeadData1?.[item] == '' ? <></> :
                                                    <p key={item}>
                                                        <span>{item}:</span> {singleLeadData1?.[item]}
                                                    </p>
                                                )
                                            } 
                                            </div>

                                            {/* </div>

                                            <div className="col-lg-6"> */}
                                            
                                            
                                            <div className="inner_matchtext">
                                            {
                                                Object.keys(singleLeadData2).map(item=> singleLeadData2?.[item] == null || singleLeadData2?.[item] == '' ? <></> :
                                                    <p key={item}>
                                                        <span>{item}:</span> {singleLeadData2?.[item]}
                                                    </p>
                                                )
                                            } 
                                            </div>


                                            <div className="leadimagediv">
                                            <div className="row">
                                            {
                                                Object.keys(pics).map(item=> pics?.[item] == null || pics?.[item] === '' ? <></> :
                                                       
                                                    <div className="col-sm-4" key={item}>
                                                        <span>{item}:</span> 

                                                        <img src={pics?.[item]} />
                                                        
                                                    </div>
                                                )
                                            } 
                                            </div>
                                            </div>

                                            </div>
                                            </div>

                                      
                                        </div>
                                    </div>

                                   
                                </div>
                        }
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
            <ToastContainer />
        </>
    );
};

export default VehicleDetails;

