
import { Component, useEffect, useState } from "react";
import { checkAuth, constant } from "../../utils/constant";
import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import Header from "../../views/layouts/header";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { Toastify } from "../../utils/Common";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Nodata from "../layouts/nodatafound";
import NoLead from "./no-lead";
import LoadMore from "./loadMore";
import { ToastContainer, toast } from "react-toastify";
// class AllLeads extends Component{
const AllLeads = props =>{
    const [title, setTitle] = useState("Dealerhop | All Leads");
    const [allLead,setAllLead] = useState([]); 
    const [searchText, setSearchText] = useState('');
    const [isNoData, setIsNoData] = useState(true);
    const [shimmerEffect, setShimmerEffect] = useState('shimmerEffect');
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [province, setProvince] = useState([]);
    const [search_province, setSearchProvince] = useState([]);

    useEffect(() => {
        getProvince();        
    },[province == null]);

    useEffect(() => {
        document.title = title;
        let interval = setTimeout(() => {
            getLeadData();
        }, 300);
        return () => clearInterval(interval);
    }, [searchText,search_province]);

    const leardSearch = async(e) => {
        setSearchText(e);
        setPage(1);
        setAllLead([]);
        setCurrentPage(0);
        setLastPage(0);
        getLeadData();        
    }
   
    const handleChange = event => {
        setPage(1)
        if(event.target.value == "Select Province"){            
            setSearchProvince("");
        }else{
            setSearchProvince(event.target.value);
        }

        
      };
    const getLeadData = async () => {
        setShimmerEffect('shimmerEffect');
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETALLLEAD,
            params: {
                search: searchText,
                search_province: search_province,
                page: page,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
              //  console.log("response ", response);
                if (response.data.status == true) {

                    if(page == 1){
                        setAllLead(response.data.data);                        
                     }else{
                        setAllLead([...allLead,...response.data.data]);
                     }

                    setLastPage(response.data.last_page)
                    setCurrentPage(response.data.current_page)
                    
                    setPage(1+response.data.current_page);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    const getProvince = async () => {
        setShimmerEffect("shimmerEffect");
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETPROVINCE,
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                if (response.data.status == true) {
                    
                    setProvince(response.data.data);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };


        // render(){
        return (
            <>
                <Header />

                <div className="contentCoverbox newsetfooter_addclass">
                    <LeftSiderbar />
                    <div className="rightsidecontent">
                        <div className="marketplace_search">
                            
                        <div className="row">
                            <div className="col-lg-9">
                            <div className="input-group">
                                <img
                                    src={
                                        require("../../assets/svg/Iconly-Light-Search.svg")
                                            .default
                                    }
                                />
                                <input
                                    className="form-control border-end-0 border rounded-pill"
                                    type="search"
                                    id="example-search-input"
                                    placeholder="Search..."
                                    value={searchText}
                                    onChange={(e) => leardSearch(e.target.value)}
                                />
                            </div>
                            </div>
                            <div className="col-lg-3">

                            <div className="input-group select_province_tag">
                            <select className="form-control border-end-0 border" onChange={handleChange}>
                                <option>Select Province</option>
                                {province.map((item, index) => {
                                   return <option>{item}</option>;
                                })}
                            </select>
                        </div>
                        </div>

                    </div>

                        </div>
                        <div className="marketplace_boxdiv">
                            <div className="row">
                                {allLead.length > 0 ? (
                                    allLead.map((item, index) => {
                                        return (
                                            <LeadCell
                                                item={item}
                                                key={index}
                                                shimmer={shimmerEffect}
                                            />
                                        );
                                    })
                                ) : (
                                    <>
                                    {
                                        isNoData ? 
                                        <NoLead shimmer={shimmerEffect} />
                                        :
                                        <Nodata />
                                    }
                                    </>
                                )}
                            </div>
                            <LoadMore
                                currentPage={currentPage}
                                lastPage={lastPage}
                                isLoading={isLoading}
                                onClick={()=>getLeadData()}
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
                <ToastContainer />
            </>
        );
        // }

}

export default AllLeads;

const LeadCell = props => {

    const options = {
        labels: {
          confirmable: "Confirm",
          cancellable: "Cancel"
        }
      }

    const [isLoading, setIsLoading] = useState(false);
    const [isRedirect, setRedirect] = useState(false);

    const openLeadCustomer = (id) => {
        
        const res =  axios({
            method: 'POST',
            url: constant.SAVELEADCUSTOMER,
            data:{
                'lead_id':id
            },
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
        }).then((response) => {
            if(response.data.status == true){
                setIsLoading(false);
                
                let hd = JSON.parse(localStorage.getItem('header_data'));
                var header_data={
                 name: hd.name,
                 matched: response.data.matched,
                 fulfilled: hd.fulfilled,
                 wallet: hd.wallet,
             };

             
             localStorage.setItem("header_data",JSON.stringify(header_data));

                
               Toastify(response.data.message,'success');
               setTimeout(() => {                            
                   setRedirect(true);
               }, 1000);
                
                
            } else {
                setIsLoading(false);
                setTimeout(() => {                            
                    setRedirect(false);
                }, 100);
                Toastify(response.data.message,'warn');  
               // console.log(response.data);
            }
        }).catch(err => {
            Toastify("Something went wrong please try again",'error');
            console.log(err);
        });
        // confirmAlert({
        //     title: 'Confirm to submit',
        //     message: 'Are you sure to do this.',
        //     buttons: [
        //       {
        //         label: 'Yes',
        //         onClick: () => {
        //             const res =  axios({
        //                 method: 'POST',
        //                 url: constant.SAVELEADCUSTOMER,
        //                 data:{
        //                     'lead_id':id
        //                 },
        //                 headers: {
        //                   Authorization: constant.USERTOKEN,
        //                 },
        //             }).then((response) => {
        //                 if(response.data.status == true){
        //                     setIsLoading(false);
                           
        //                     Toastify(response.data.message,'success');
        //                    setTimeout(() => {                            
        //                        setRedirect(true);
        //                    }, 1000);
                            
                            
        //                 } else {
        //                     setIsLoading(false);
        //                     setTimeout(() => {                            
        //                         setRedirect(false);
        //                     }, 100);
        //                     Toastify(response.data.message,'warn');  
        //                     console.log(response.data);
        //                 }
        //             }).catch(err => {
        //                 Toastify("Something went wrong please try again",'error');
        //                 console.log(err);
        //             });
        //         }
        //       },
        //       {
        //         label: 'No',
        //         // onClick: () => alert('Click No')
        //       }
        //     ]
        // });

    }

    if(isRedirect == true){
        return <Navigate to={`/lead/all/${props?.item?.id}`} />
    }
    
    return (
        <div className="col-lg-3">
                                    <div className="match_boxmandiv">
                                    { 
                                        (props?.item?.is_save ==1) ?
                                        <h3>{props?.item?.first_name}  {props?.item?.last_name}</h3>
                                        :
                                        <h3>{props?.item?.first_name} ***</h3>
                                    }
                                        <span>{props?.item?.date_submited} 
                                        {/* ( {props?.item?.day_on_site} ) */}
                                        </span>
                                        <div className="inner_matchtext">
                                            <p>{props?.item?.delivery_type}</p>
                                        </div>                                     
                                        <div className="inner_matchtext">
                                            <p>${props?.item?.price}</p>
                                            <p>{props?.item?.vehicle_type}</p>
                                            <p>{props?.item?.vehicle_make}</p>
                                            <p>{props?.item?.condition}</p>
                                            <p>{props?.item?.budget}</p>
                                            <p>{props?.item?.price_range}</p>
                                        </div>
                                        <div className="inner_matchtext">
                                            <p>{props?.item?.address}</p>
                                            <p>{props?.item?.housing_type}</p>
                                            <p>{props?.item?.housing_payment}</p>
                                            <p>{props?.item?.province}</p>
                                        </div>                                
                                        <div className="inner_matchtext">
                                            <p>{props?.item?.employment_type}</p>
                                            <p>{props?.item?.income}</p>
                                            <p>{props?.item?.position_or_title}</p>
                                        </div>
                                        { 
                                        (props?.item?.is_save ==1) ?
                                            <Link to={`/lead/all/${props?.item?.id}`}>View Profile</Link>
                                        :
                                            // <Link to={`/lead/all/${props?.item?.id}`}>Open Profile</Link>
                                            <a className="link_a_btn" href={void 0} onClick={()=>openLeadCustomer(props?.item?.id)}>
                                                Match with Customer                                           
                                            </a>
                                            
                                        }
                                    
                                    </div>
                                </div>
    )
}