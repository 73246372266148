import { Component, useEffect, useState } from "react";
import { checkAuth, constant } from "../../utils/constant";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import Header from "../../views/layouts/header";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { Toastify } from "../../utils/Common";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Nodata from "../layouts/nodatafound";
import NoLead from "./no-lead";
import LoadMore from "./loadMore";
import { ToastContainer, toast } from "react-toastify";
import { TimmerVehicleView } from "../../utils/DateVehicleTimeUtils";
import SimpleImageSlider from "react-simple-image-slider";
import { Modal, Button, Form } from "react-bootstrap";
import io from "socket.io-client";
import {
    getBid,
    initiateSocket,
    onDisconnect,
    SocketConst,
} from "../../socketUtils/MySocket";

// class VehicleLead extends Component{
const VehicleLead = (props) => {
    const [title, setTitle] = useState("Dealerhop | Won Leads");
    const [allVehicleLead, setAllVehicleLead] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [isNoData, setIsNoData] = useState(true);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [province, setProvince] = useState([]);
    const [search_province, setSearchProvince] = useState([]);
    const [setallbids, setAllBids] = useState(null);
    const [show, setShow] = useState(false);
    const [err_amount, errAmount] = useState("");
    const [timezone, setTimezone] = useState("");
    const [room, setRoom] = useState("");
    // const [setamount, setAmount] = useState(0);
    const [setdetailmodelshow, setDetailModelShow] = useState(false);
    const [leadData, setLeadData] = useState(null);
    const [singleLeadData, setSingleLeadData] = useState(null);
    const [singleLeadData1, setSingleLeadData1] = useState(null);
    const [singleLeadData2, setSingleLeadData2] = useState(null);
    const [pics, setPics] = useState(null);

    useEffect(() => {
        getProvince();
    }, [province == null]);

    useEffect(() => {
        document.title = title;
        let interval = setTimeout(() => {
            getVehicleLeadData();
        }, 300);
        return () => clearInterval(interval);
    }, [searchText, search_province]);

    const handleChange = (event) => {
        setPage(1);
        if (event.target.value == "Select Province") {
            setSearchProvince("");
        } else {
            setSearchProvince(event.target.value);
        }
    };

    

    const showBids = (id, flag, item) => {
       

        axios({
            method: "GET",
            url: constant.GETBIDS,
            params: {
                vehicle_lead_id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                if (response.data.status == true) {
                    // console.log(response.data.data);
                    setAllBids(response.data.data);
                    if (flag == 1) {
                        setShow(true);
                    }
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");

                console.log("error call ", error);
            });
    };
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => setShow(false);

    const getVehicleLeadData = async () => {
        setShimmerEffect("shimmerEffect");
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETWONLEAD,
            params: {
                search: searchText,
                search_province: search_province,
                page: page,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                //  console.log("response ", response);
                if (response.data.status == true) {
                    if (page == 1) {
                        setAllVehicleLead(response.data.data);
                    } else {
                        setAllVehicleLead([
                            ...allVehicleLead,
                            ...response.data.data,
                        ]);
                    }

                    setLastPage(response.data.last_page);
                    setCurrentPage(response.data.current_page);

                    setPage(1 + response.data.current_page);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    const getProvince = async () => {
        setShimmerEffect("shimmerEffect");
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETPROVINCE,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                if (response.data.status == true) {
                    setProvince(response.data.data);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    
    
    useEffect(() => {
        const getTimezone = () => {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setTimezone(timezone);
        };
        getTimezone();
    }, []);

    const showShowDetail = (id) => {
        
        
        axios({
            method: "GET",
            url: constant.VEHICLELEADDETAIL,
            params: {
                vehicle_lead_id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                // console.log("response ", response);
                if (response.data.status == true) {                    
                    setLeadData(response.data.data);
                    setSingleLeadData(response.data.single_lead_data);
                    setSingleLeadData1(response.data.single_lead_data_1);
                    setSingleLeadData2(response.data.single_lead_data_2);
                    setPics(response.data.pics);
                   
                    setTimeout(() => {
                        
                        setDetailModelShow(true);
                    }, 100);
                   
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
            
                console.log("error call ", error);
            });


        
    };

    const handleDetailModelClose = () => setDetailModelShow(false);
    

    return (
        <>
            <Header />

            <div className="contentCoverbox newsetfooter_addclass">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="marketplace_search">
                        <div className="row">
                            {/* <div className="col-lg-9">
                                <div className="input-group">
                                    <img
                                        src={
                                            require("../../assets/svg/Iconly-Light-Search.svg")
                                                .default
                                        }
                                    />
                                    <input
                                        className="form-control border-end-0 border rounded-pill"
                                        type="search"
                                        id="example-search-input"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) =>
                                            leardSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input-group select_province_tag">
                                    <select
                                        className="form-control border-end-0 border"
                                        onChange={handleChange}
                                    >
                                        <option>Select Province</option>
                                        {province.map((item, index) => {
                                            return <option>{item}</option>;
                                        })}
                                    </select>
                                </div>
                            </div> */}

                            <div className="carslider_manboxdiv">
                                <div className="row">
                                    {allVehicleLead == null ? (
                                        <NoLead shimmer={shimmerEffect} />
                                    ) : allVehicleLead?.length == 0 ? (
                                        <Nodata />
                                    ) : (
                                        allVehicleLead?.map((item, index) => (
                                            <VehicleLeadCell
                                                item={item}
                                                key={index}
                                                index={index}
                                                onShowDetail={() => {
                                                    showShowDetail(item?.id);
                                                 }}
                                                onShowBids={() => {
                                                   showBids(item?.id, 1, item);
                                                }}
                                                onNewMessage={(msg) => {
                                                 
                                                    showBids(
                                                        msg?.data?.data
                                                            ?.vehicle_id,
                                                        0,
                                                        item
                                                    );
                                                }}
                                            />
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <span style={{ marginTop: "100px" }}>
                <Modal
                    className="bid_modal"
                    show={show}
                    onHide={handleClose}
                    style={{ marginTop: "40px" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bid Ledger</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <div className="transactionhistory_mandiv1">
                                <div className="history_titlebox"></div>
                                <div className="history_detilsbox">
                                    {setallbids == null ? (
                                        <NoLead shimmer={shimmerEffect} />
                                    ) : setallbids?.length == 0 ? (
                                        <Nodata />
                                    ) : (
                                        setallbids?.map((item, index) => (
                                            <ul key={index}>
                                                <li>
                                                    <p>{item?.fulldate}</p>
                                                </li>
                                                <li>
                                                    <span>{item?.name}</span>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <span className="">
                                                        {" "}
                                                        ${item?.amount}
                                                    </span>
                                                </li>
                                            </ul>
                                        ))
                                    )}
                                </div>
                            </div>
                        </>
                    </Modal.Body>
                    
                </Modal>
            </span>



             {/* Detail Model start */}
             <span style={{ marginTop: "100px" }}>
                <Modal
                    className="detail_modal"
                    show={setdetailmodelshow}
                    onHide={handleDetailModelClose}
                    style={{ marginTop: "40px" }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Lead Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                        <div className="leftcustomers_detils vehical_listmandiv">
                                       
                                      

                                       <div className="row">
                                           
                                           <div className="col-lg-12">
                                           <div className="sliderinner_box">
                                           <div className="inner_matchtext">
                                           
                                           {leadData == null ? (
                                        <NoLead shimmer={shimmerEffect} />
                                    ) : leadData?.length == 0 ? (
                                        <Nodata />
                                    ) : (
                   
                                        <>
                                        
                                        <div className="sliderparent">
                                        {leadData?.sliderImages == null ? (
                                            <></>
                                        ) : leadData?.sliderImages == 0 ? (
                                            <></>
                                        ) : (
                                            <SimpleImageSlider
                                            style={{width: '100%' }}                      
                                            height={370}
                                            images={leadData?.sliderImages}
                                            showNavs={true}
                                            navMargin={0}
                                            bgColor={"#fff"}                     
                                            />    
                          
                                        )} 
                                        </div>

                                            <div className="inner_matchtext">
                                            <h3>
                                            {leadData?.first_name}{" "}
                                            {leadData?.last_name}
                                        </h3>
                                            {
                                                Object.keys(singleLeadData).map(item=> singleLeadData?.[item] == null || singleLeadData?.[item] == '' ? <></> :
                                                    <p key={item}>
                                                        <span>{item}:</span> {singleLeadData?.[item]}
                                                    </p>
                                                )
                                            } 
                                            </div> 

                                              <div className="inner_matchtext">
                                            {
                                                Object.keys(singleLeadData1).map(item=> singleLeadData1?.[item] == null || singleLeadData1?.[item] == '' ? <></> :
                                                    <p key={item}>
                                                        <span>{item}:</span> {singleLeadData1?.[item]}
                                                    </p>
                                                )
                                            } 
                                            </div>

                                             <div className="inner_matchtext">
                                            {
                                                Object.keys(singleLeadData2).map(item=> singleLeadData2?.[item] == null || singleLeadData2?.[item] == '' ? <></> :
                                                    <p key={item}>
                                                        <span>{item}:</span> {singleLeadData2?.[item]}
                                                    </p>
                                                )
                                            } 
                                            </div>


                                            <div className="leadimagediv">
                                            <div className="row">
                                            {
                                                Object.keys(pics).map(item=> pics?.[item] == null || pics?.[item] == '' ? <></> :
                                                       
                                                    <div className="col-sm-4" key={item}>
                                                        <span>{item}:</span> 

                                                        <img src={pics?.[item]} />
                                                        
                                                    </div>
                                                )
                                            } 
                                            </div>
                                            </div>

                                        </>
                      
                                    )}
                                         
                                
                   </div>
                   </div>
                   </div>
                   </div>
                   </div>


                          
                        </>
                    </Modal.Body>
                    
                </Modal>
            </span>
            {/* Detail Model End */}

            <ToastContainer />
        </>
    );
    // }
};

export default VehicleLead;

const VehicleLeadCell = (props) => {
    const [amount, setAmount] = useState("");

    useEffect(() => {
        getBid((message) => {
            console.log(`get Cell`);
            props?.onNewMessage(message);
        });
    }, []);

    return (
        <div className="col-lg-4" key={props?.index}>
            <div className="sliderinner_box">
                <SimpleImageSlider
                    width={329}
                    height={250}
                    images={props?.item.sliderImages}
                    showNavs={true}
                    navMargin={0}
                    bgColor={"#fff"}
                />
              
                <div className="cartext_details">
                    <h3>
                        {props?.item?.year} {props?.item?.make}{" "}
                        {props?.item?.model}
                    </h3>

                    {props?.item?.kms != null && props?.item?.kms != "" ? (
                        <p>
                            Odometer:
                            <span> {props?.item?.kms} KMS</span>
                        </p>
                    ) : null}

                    {props?.item?.street != null &&
                    props?.item?.street != "" ? (
                        <p>
                            Location:
                            <span> {props?.item?.street}</span>
                        </p>
                    ) : null}

                    {props?.item?.vin != null && props?.item?.vin != "" ? (
                        <p>
                            VIN:
                            <span> {props?.item?.vin}</span>
                        </p>
                    ) : null}
                </div>
                <div className="pickup_inputbox">
                    {/* <h4>
                        Pick-up or Drop-off: <span>Drop off</span>
                    </h4> */}
                    <h4>
                        Bid Amount: <span>${props?.item?.bid_amount}</span>
                    </h4>
                  
                </div>
                <div className="preview_btndiv">

                <a
                        href="javascript:void(0)"
                        onClick={() => props?.onShowDetail(props?.item?.id)}
                    >
                        Preview More
                       </a> 

                {/* <Link to={`/wonlead/${props?.item?.id}`}>
                    Preview More
                </Link> */}

                    <a href='javascript:void(0)' onClick={() => props?.onShowBids(props?.item?.id)}> Bid Ledger
                    </a>
                </div>
               

               
            </div>
        </div>
    );
};
