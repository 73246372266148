import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import Header from "../../views/layouts/header";
import axios from "axios";
import { Component, useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Loader from "../layouts/loader";
import { TimmerView } from "../../utils/DateTimeUtils";

import { checkAuth, constant } from "../../utils/constant";
import { Toastify, ErrorManage } from "../../utils/Common";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Nodata from "../layouts/nodatafound";
import NoLead from "./no-lead";
import LoadMore from "./loadMore";
import $ from "jquery";

// class OpenMarketPlace extends Component{
const OpenMarketPlace = (props) => {
    const [title, setTitle] = useState("Dealerhop | Open Marketplace");
    const [openMarketLeads, setOpenMarketLeads] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [default_province, setDefaultProvince] = useState("");
    const [scrollTop, setScrollState] = useState(0);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    const [isNoData, setIsNoData] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [province, setProvince] = useState([]);
    const [search_province, setSearchProvince] = useState("Select Province");
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {

    //     getOpenMarketLeadData();
    // }, []);

    useEffect(() => {
        getProvince();
    }, [province == null]);

    useEffect(() => {
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
    }, []);

    useEffect(() => {
        document.title = title;

        let interval = setTimeout(() => {
            setPage(1);
            getOpenMarketLeadData();
        }, 300);
        return () => clearInterval(interval);
    }, [searchText, search_province]);

    const getOpenMarketLeadData = async () => {
        if (page == 1) {
            setShimmerEffect("shimmerEffect");
        } else {
            setShimmerEffect("");
        }
        setIsLoading(true);
        setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETOPENMARKETLEADS,
            params: {
                search: searchText,
                search_province: search_province,
                page: page,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                if (response.data.status == true) {
                    if (page == 1) {
                        setOpenMarketLeads(response.data.data);
                    } else {
                        setOpenMarketLeads([
                            ...openMarketLeads,
                            ...response.data.data,
                        ]);
                    }
                    setLastPage(response.data.last_page);
                    setCurrentPage(response.data.current_page);

                    setPage(1 + response.data.current_page);
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                setIsNoData(false);
                setIsLoading(false);
                console.log("error call ", error);
            });
    };

    //

    const getProvince = async () => {
        setShimmerEffect("shimmerEffect");
        // setIsLoading(true);
        // setIsNoData(true);
        axios({
            method: "GET",
            url: constant.GETPROVINCE,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                checkAuth(response);
                setShimmerEffect("");
                // setIsNoData(false);
                // setIsLoading(false);
                if (response.data.status === true) {
                    setProvince(response.data.data);
                    if (response.data.my_province !== "") {
                        setSearchProvince(response.data.my_province);
                        setPage(1);
                        // setDefaultProvince(response.data.my_province);
                    }
                }
            })
            .catch((error) => {
                checkAuth(error);
                setShimmerEffect("");
                // setIsNoData(false);
                // setIsLoading(false);
                console.log("error call ", error);
            });
    };

    //
    const handleChange = (event) => {
        setPage(1);
        if (event.target.value == "Select Province") {
            setSearchProvince(event.target.value);
            setDefaultProvince(event.target.value);
        } else {
            setSearchProvince(event.target.value);
            setDefaultProvince(event.target.value);
        }
        // getOpenMarketLeadData();
    };

    const leardSearch = async (e) => {
        setSearchText(e);
        setPage(1);
        setOpenMarketLeads([]);
        setCurrentPage(0);
        setLastPage(0);
        // getOpenMarketLeadData();
    };

    const userdata1 = localStorage.getItem("userData");
    const userdata = JSON.parse(userdata1);

    let step = 0;
    let is_approved_by_admin = 0;

    // console.log(userdata)
    if (userdata) {
        step = userdata.data.step;
        is_approved_by_admin = userdata.data.is_approved_by_admin;
    }

    // console.log(is_approved_by_admin);
    if (is_approved_by_admin == 0) {
        return <Navigate to="/profilestatus" />;
    }
    return (
        <>
            <Header />
            <div className="contentCoverbox newsetfooter_addclass">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="marketplace_search">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="input-group">
                                    <img
                                        src={
                                            require("../../assets/svg/Iconly-Light-Search.svg")
                                                .default
                                        }
                                    />

                                    <input
                                        className="form-control border-end-0 border rounded-pill"
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={(e) =>
                                            leardSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input-group select_province_tag">
                                    <select
                                        className="form-control border-end-0 border"
                                        onChange={handleChange}
                                        value={search_province}
                                    >
                                        <option>Select Province</option>
                                        {province.map((item, index) => {
                                            return <option>{item}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="marketplace_boxdiv">
                        <div className="row">
                            {openMarketLeads.length > 0 ? (
                                openMarketLeads.map((item, index) => {
                                    return (
                                        <OpenMarketLeadCell
                                            item={item}
                                            key={index}
                                            shimmer={shimmerEffect}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    {isNoData ? (
                                        <NoLead shimmer={shimmerEffect} />
                                    ) : (
                                        <Nodata />
                                    )}
                                </>
                            )}
                        </div>
                        <LoadMore
                            currentPage={currentPage}
                            lastPage={lastPage}
                            isLoading={isLoading}
                            onClick={() => getOpenMarketLeadData()}
                        />
                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </>
    );
};

export default OpenMarketPlace;

const OpenMarketLeadCell = (props) => {
    const options = {
        labels: {
            confirmable: "Confirm",
            cancellable: "Cancel",
        },
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isRedirect, setRedirect] = useState(false);
    const [isRedirectAddCart, setIsRedirectAddCart] = useState(false);
    const [saveLeadLoading, setSaveLeadLoading] = useState(false);

    const listInnerRef = useRef();
    const openLeadCustomer = (id) => {
        setSaveLeadLoading(true);
        const res = axios({
            method: "POST",
            url: constant.SAVELEADCUSTOMER,
            data: {
                lead_id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                if (response.data.status == true) {
                    let hd = JSON.parse(localStorage.getItem("header_data"));
                    var header_data = {
                        name: response.data.name,
                        matched: response.data.matched,
                        fulfilled: response.data.fulfilled,
                        wallet: response.data.wallet,
                    };

                    localStorage.setItem(
                        "header_data",
                        JSON.stringify(header_data)
                    );

                    setIsLoading(false);
                    setSaveLeadLoading(false);

                    Toastify(response.data.message, "success");
                    setTimeout(() => {
                        setRedirect(true);
                    }, 1000);
                } else {
                    if (response.data.is_skip_payment == 1) {
                        setIsLoading(false);
                        setSaveLeadLoading(false);
                        setTimeout(() => {
                            setIsRedirectAddCart(true);
                            setRedirect(false);
                        }, 100);
                    } else {
                        setIsLoading(false);
                        setSaveLeadLoading(false);
                        setTimeout(() => {
                            setRedirect(false);
                        }, 100);
                        Toastify(response.data.message, "warn");
                    }

                    //console.log(response.data);
                }
            })
            .catch((err) => {
                Toastify("Something went wrong please try again", "error");
                console.log(err);
                setSaveLeadLoading(false);
            });

        // confirmAlert({
        //     title: 'Confirm to submit',
        //     message: 'Are you sure to do this.',
        //     buttons: [
        //       {
        //         label: 'Yes',
        //         onClick: () => {
        //             const res =  axios({
        //                 method: 'POST',
        //                 url: constant.SAVELEADCUSTOMER,
        //                 data:{
        //                     'lead_id':id
        //                 },
        //                 headers: {
        //                   Authorization: constant.USERTOKEN,
        //                 },
        //             }).then((response) => {
        //                 if(response.data.status == true){
        //                     setIsLoading(false);

        //                     Toastify(response.data.message,'success');
        //                    setTimeout(() => {
        //                        setRedirect(true);
        //                    }, 1000);

        //                 } else {
        //                     setIsLoading(false);
        //                     setTimeout(() => {
        //                         setRedirect(false);
        //                     }, 100);
        //                     Toastify(response.data.message,'warn');
        //                     console.log(response.data);
        //                 }
        //             }).catch(err => {
        //                 Toastify("Something went wrong please try again",'error');
        //                 console.log(err);
        //             });
        //         }
        //       },
        //       {
        //         label: 'No',
        //         // onClick: () => alert('Click No')
        //       }
        //     ]
        // });
    };

    if (isRedirectAddCart == true) {
        return <Navigate to="/account_balance" />;
    }

    if (isRedirect == true) {
        return <Navigate to={`/lead/marketplace/${props?.item?.id}`} />;
    }

    return (
        <div className="col-lg-4 " key={props?.index}>
            {/* {props?.item?.vehicle_make != null &&
            props?.item?.vehicle_make != "" ? (
                <div className={`newbtn_set ${props?.shimmer}`}>
                    <h4>Make</h4>
                    <p>{props?.item?.vehicle_make}</p>
                </div>
            ) : null}

            {props?.item?.vehicle_type != null &&
            props?.item?.vehicle_type != "" ? (
                <div className={`newbtn_set newbtn_set1 ${props?.shimmer}`}>
                    <h4>Body Style</h4>
                    <p>{props?.item?.vehicle_type}</p>
                </div>
            ) : null}
            */}

            <div className={`match_boxmandiv ${props?.shimmer}`}>
                <h3>{props?.item?.first_name} ***</h3>
                {/* <span> */}
                {/* {props?.item?.date_submited} */}
                {/* ( {props?.item?.day_on_site} ) */}
                {/* </span> */}

                <div className="inner_matchtext">
                    {props?.item?.vehicle_make != null &&
                    props?.item?.vehicle_make != "" ? (
                        <p>
                            <span>Make:</span>
                            {props?.item?.vehicle_make}
                        </p>
                    ) : null}

                    {props?.item?.vehicle_type != null &&
                    props?.item?.vehicle_type != "" ? (
                        <p>
                            <span>Body Style:</span>
                            {props?.item?.vehicle_type}
                        </p>
                    ) : null}

                    {/* <p>${props?.item?.price}</p> */}

                    {props?.item?.delivery_type != null &&
                    props?.item?.delivery_type != "" ? (
                        <p>
                            <span>Delivery Type:</span>
                            {props?.item?.delivery_type}
                        </p>
                    ) : null}

                    {props?.item?.budget != null &&
                    props?.item?.budget != "" ? (
                        <p>
                            <span>Payment:</span>
                            {props?.item?.budget}
                        </p>
                    ) : null}

                    {/* {(props?.item?.vehicle_type != null && props?.item?.vehicle_type != "") 
             ?
                    <p>
                        <span>Body Style:</span>
                        {props?.item?.vehicle_type}
                    </p>
                     :
                     null
                     } */}

                    {/* {(props?.item?.vehicle_make != null && props?.item?.vehicle_make != "") 
             ?
                    <p>
                        <span>Make:</span>
                        {props?.item?.vehicle_make}
                    </p>
                    :
                    null
                    } */}

                    {props?.item?.condition != null &&
                    props?.item?.condition != "" ? (
                        <p>
                            <span>New or Used:</span>
                            {props?.item?.condition}
                        </p>
                    ) : null}

                    {props?.item?.price_range != null &&
                    props?.item?.price_range != "" ? (
                        <p>
                            <span>Price Range:</span>
                            {props?.item?.price_range}
                        </p>
                    ) : null}
                </div>
                <div className="inner_matchtext">
                    {props?.item?.address != null &&
                    props?.item?.address != "" ? (
                        <p>
                            <span>Address:</span>
                            ***
                            {/* {props?.item?.address} */}
                        </p>
                    ) : null}

                    {props?.item?.housing_type != null &&
                    props?.item?.housing_type != "" ? (
                        <p>
                            <span>Housing Type:</span>
                            {props?.item?.housing_type}
                        </p>
                    ) : null}

                    {props?.item?.housing_payment != null &&
                    props?.item?.housing_payment != "" ? (
                        <p>
                            <span>Housing Payment:</span>
                            {props?.item?.housing_payment}
                        </p>
                    ) : null}

                    {props?.item?.province != null &&
                    props?.item?.province != "" ? (
                        <p>
                            <span>Province:</span>
                            {props?.item?.province}
                        </p>
                    ) : null}

                    {props?.item?.city != null && props?.item?.city != "" ? (
                        <p>
                            <span>City:</span>
                            {props?.item?.city}
                        </p>
                    ) : null}

                    {props?.item?.income_length != null &&
                    props?.item?.income_length != "" ? (
                        <p>
                            <span>Income Length:</span>
                            {props?.item?.income_length}
                        </p>
                    ) : null}
                    {props?.item?.monthly_income != null &&
                    props?.item?.monthly_income != "" ? (
                        <p>
                            <span>Monthly Income:</span>
                            {props?.item?.monthly_income}
                        </p>
                    ) : null}
                </div>
                <div className="inner_matchtext">
                    {props?.item?.employment_type != null &&
                    props?.item?.employment_type != "" ? (
                        <p>
                            <span>Employment:</span>
                            {props?.item?.employment_type}
                        </p>
                    ) : null}

                    {props?.item?.position_or_title != null &&
                    props?.item?.position_or_title != "" ? (
                        <p>
                            <span>Title:</span>
                            {props?.item?.position_or_title}
                        </p>
                    ) : null}

                    {props?.item?.dob != null && props?.item?.dob != "" ? (
                        <p>
                            <span>Date Of Birth:</span>
                            {props?.item?.dob}
                        </p>
                    ) : null}

                    {props?.item?.credit_estimate != null &&
                    props?.item?.credit_estimate != "" ? (
                        <p>
                            <span>Credit Estimate:</span>
                            {props?.item?.credit_estimate}
                        </p>
                    ) : null}

                    {/* {props?.item?.dl_notify != null &&
                    props?.item?.dl_notify != "" ? (
                        <p>
                            <span>Drivers License:</span>
                            <span>{`Yes`}</span>
                        </p>
                    ) : null} */}

                    {/* Driving lincense */}
                    <div className="maindivdoc">
                        <p>Documents saved on file:</p>
                        {
                            <div className="subdivdoc">
                                <img
                                    src={
                                        require("../../assets/svg/dldoc.svg")
                                            .default
                                    }
                                />
                                <span>Driver's Licence</span>
                                <p>{`${
                                    props?.item?.dl_link != null &&
                                    props?.item?.dl_link != ""
                                        ? "Yes"
                                        : "No"
                                }`}</p>
                            </div>
                        }
                        {
                            <div className="subdivdoc">
                                <img
                                    src={
                                        require("../../assets/svg/incomedoc.svg")
                                            .default
                                    }
                                />
                                <span>Proof id income</span>
                                <p>{`${
                                    props?.item?.income_certificate != null &&
                                    props?.item?.income_certificate != ""
                                        ? "Yes"
                                        : "No"
                                }`}</p>
                            </div>
                        }
                    </div>

                    {props?.item?.notes != null && props?.item?.notes != "" ? (
                        <div className="notMainDiv">
                            <span>Notes:</span>
                            <p>{props?.item?.notes}</p>
                        </div>
                    ) : null}
                </div>

                        
                <a
                    className="link_a_btn"
                    href={void 0}
                    onClick={() => {
                        if (!saveLeadLoading) openLeadCustomer(props?.item?.id);
                    }}
                >
                    Match with Customer
                    {saveLeadLoading && <Loader />}

                   
                </a>

                <div>
                    <TimmerView
                        date={props?.item?.end_timer_date}
                        onRenderUi={(time) => {
                            <p>{time}</p>;
                        }}
                    />
                </div>
                {/* <Link to={`/lead/marketplace/${props?.item?.id}`}>Match with Customer</Link> */}
            </div>
        </div>
    );
};
