import React,{ Component } from "react";
import {Link} from 'react-router-dom';
import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import Header from '../../views/my_account/header';

class Notification extends Component{
    render() {
        return (
            <>
                <div className="contentCoverbox setfooter_addclass">
                    <LeftSiderbar/>
                    <div className="rightsidecontent" style={{background: '#F1F1F1', border: '1px solid #D1D1D1'}}>
                        <Header/>
                        <div className="myaccount_mandiv">
                            <div className="transactionhistory_mandiv">
                                <div className="history_titlebox">
                                    <h3>Transaction History</h3>
                                    <p>Below is the history of the amount you deposited in your account and payments made to match with customers.</p>
                                </div>
                                <div className="history_detilsbox">
                                    <ul>
                                        <li><p>15 Nov,2022</p></li>
                                        <li><span>James</span></li>
                                        <li><span>- $175.00</span></li>
                                    </ul>
                                    <ul>
                                        <li><p>15 Nov,2022</p></li>
                                        <li><span>James</span></li>
                                        <li><span>- $175.00</span></li>
                                    </ul>
                                    <ul>
                                        <li><p>15 Nov,2022</p></li>
                                        <li><span>James</span></li>
                                        <li><span>- $175.00</span></li>
                                    </ul>
                                    <ul>
                                        <li><p>15 Nov,2022</p></li>
                                        <li><span>Deposit</span></li>
                                        <li><span className="deposit_color">$1,500.00</span></li>
                                    </ul>
                                    <ul>
                                        <li><p>15 Nov,2022</p></li>
                                        <li><span>James</span></li>
                                        <li><span>- $175.00</span></li>
                                    </ul>
                                    <ul>
                                        <li><p>15 Nov,2022</p></li>
                                        <li><span>James</span></li>
                                        <li><span>- $175.00</span></li>
                                    </ul>
                                    <ul>
                                        <li><p>15 Nov,2022</p></li>
                                        <li><span>James</span></li>
                                        <li><span>- $175.00</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
                
            </>

        )
    }
}

export default Notification;