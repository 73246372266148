import React from "react";
import {Link,Navigate} from 'react-router-dom';
import { useLocation } from "react-router-dom";


const SubHeader = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const header_route_is =splitLocation[1];
    let is_main = 0;
    let is_approved_by_admin = 0;

    const userdata1 = localStorage.getItem("userData");
    let userdata =JSON.parse(userdata1);    
    is_main =  userdata.data.is_main_account;
    is_approved_by_admin =  userdata.data.is_approved_by_admin;
    
    
  
    
    if(is_approved_by_admin === 0){
        return <Navigate to="/available_customers" />;
    }

        return (
            <>
                <div className="account_categorybox">
                    <ul>
                        <li className={header_route_is === "dealership_information" ? "active" : ""}>
                            <Link to="/dealership_information">Dealership Info </Link>
                        </li>
                        
                        <li className={header_route_is === "contact_information" ? "active" : ""}>
                        
                            <Link to="/contact_information">Contact Info</Link>
                        </li>
                      
                        {(is_main === 1)?
                        <li className={header_route_is === "account_balance" ? "active" : ""}>
                            <Link to="/account_balance">Account Balance</Link>
                        </li>
                            :
                            null}
                        {(is_main === 1)?
                        <li className={header_route_is === "transaction_history" ? "active" : ""}>
                            <Link to="/transaction_history">Transaction History</Link>
                        </li>
                        :
                        null}

                        <li className={header_route_is === "account_settings" ? "active" : ""}>
                        <Link to="/account_settings">Account Settings</Link>                            
                        </li>


                        <li className={header_route_is === "generateleadlink" ? "active" : ""}>
                        <Link to="/generateleadlink">Self-Serve Forms</Link>                            
                        </li>

                        {(is_main === 1)?
                        <li className={header_route_is === "referandearn" ? "active" : ""}>
                        <Link to="/referandearn">Refer And Earn</Link>                            
                        </li>
                        :
                        null}

                        <li className={header_route_is === "notificationsettings" ? "active" : ""}>
                        <Link to="/notificationsettings">Notification Settings</Link>                            
                        </li>
                        
                        <li className={header_route_is === "connectcrm" ? "active" : ""}>
                        <Link to="/connectcrm">Connect CRM</Link>                            
                        </li>

                    </ul>
                </div>
            </>

        )
    }


export default SubHeader;