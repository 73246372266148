import React,{ Component } from "react";


class login extends Component{
    render() {
        return (
            <>
                <div className="footer_logodiv setfooter_border">
                   <img className="logfirst" src={ require('../../assets/svg/footlogo.svg').default } />                                                          
                 
                
                {/* <img className="newfootlogo" src={ require('../../assets/svg/footlogo.svg').default } /> */}
                    
                
                {/* <div className="company_footr">
                    <p>Designed & Developed by <a href="https://foremostdigital.com/" target="_blank">Foremost Digital Inc.</a></p>
                </div> */}
                </div>
                

            </>

        )
    }
}

export default login;