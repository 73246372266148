import React,{ Component } from "react";

class Nodata extends Component{
    render() {
        return (
            <>
             <div className="nodatafound">
             <img className="nofounfimg"
                        src={
                            require("../../assets/svg/no.svg")
                                .default
                        }
                    />
                    <h6>No data found</h6>
             </div>
            </>

        )
    }
}

export default Nodata;