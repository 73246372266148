import { Alert, Button } from "react-bootstrap";
import React, { Component} from "react";
import { Link,Navigate } from "react-router-dom";
import axios from "axios";
import Loader from './../layouts/loader'
import { AppImages, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paper from "@material-ui/core/Paper";
import MapboxAutocomplete from "react-mapbox-autocomplete";

class Register extends Component {

    mapAccess = {
        mapboxApiAccessToken:
          "pk.eyJ1IjoiZGV2ZWxvcGVyYWJpZGFsaSIsImEiOiJjbDF3MG16NzUxNGg0M2txdnNtcDgzbmR2In0.BIJcWX7_Z30IXudESTAbbw"
      };

    // function Register(){
        // const Register = props =>{
            constructor(props) {
                super(props);
                this.state = {
                    email: "",
                    errMsgEmail: "",
                    password: "",
                    errMsgPassword: "",
                    confirm_password: "",
                    errMsgConfirmPassword: "",
                    first_name: "",
                    errMsgFirstName: "",
                    last_name: "",
                    errMsgLastName: "",
                    dealership_name: "",
                    errMsgDealershipName: "",
                    dealership_location: "",
                    errMsgDealershipLocation: "",
                    isLoading: false,
                    isRedirect: false,
                    button: "button",
                    longitude: "",
                    latitude: "",
                    zoom: 9,
                    province: "",
                    errMsgProvince: "",
                    referral_dealerhip_name:  "",
                    referal_from_user: "",
                };

        this.onChangehandler = this.onChangehandler.bind(this);
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        // this.setState({dealership_location : e})
    };

    suggestionSelect(result, lat, long, text) {
            axios({
                method: "GET",
                url: constant.GEOCODING,
                params: {
                    latitude: lat,
                    longitude: long,
                },
            })
            .then((response) => {
                this.setState({
                    province: response.data.province,
                });
            });
        this.setState({
            dealership_location: result,
            latitude: lat,
            longitude: long,
        });
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();

        var i = 0;
        
        // alert($('[name="confirm_password"]').val());
        this.setState({
            errMsgEmail: "",
            errMsgPassword: "",
            errMsgconfirm_password: "",
            errMsgFirstName: "",
            errMsgLastName: "",
            errMsgDealershipName: "",
            errMsgDealershipLocation: "",
            errMsgProvince: "",
        });
        if (this.state.email.trim().length == 0) {
            i++;
            this.setState({ errMsgEmail: "This field is required" });
        }
        if (this.state.password.trim().length == 0) {
            i++;
            this.setState({ errMsgPassword: "This field is required" });
        }

        if (this.state.confirm_password.trim().length == 0) {
            i++;
            this.setState({
                errMsgconfirm_password: "This field is required",
            });
        } else if (this.state.confirm_password != this.state.password) {
            i++;
            this.setState({
                errMsgconfirm_password: "The confirm password and password must match.",
            });
        }

        if (this.state.first_name.trim().length == 0) {
            i++;
            this.setState({
                errMsgFirstName: "This field is required",
            });
        }

        if (this.state.last_name.trim().length == 0) {
            i++;
            this.setState({
                errMsgLastName: "This field is required",
            });
        }

        if (this.state.dealership_name.trim().length == 0) {
            i++;
            this.setState({
                errMsgDealershipName: "This field is required",
            });
        }

        if (this.state.dealership_location.trim().length == 0) {
            i++;
            this.setState({
                errMsgDealershipLocation:
                    "This field is required",
            });
        }

        if (this.state.province.trim().length == 0) {
            i++;
            this.setState({
                errMsgProvince:
                    "This field is required",
            });
        }

        if (i == 0) {
            if(JSON.parse(localStorage.getItem("referral_data"))){
              
                let ref_data = JSON.parse(localStorage.getItem("referral_data"));
                  this.state.referral_dealerhip_name=  ref_data.dealerhip_name;
                  this.state.referal_from_user =  ref_data.referal_from_user;
               }else{
                   this.state.referral_dealerhip_name=  "";
                   this.state.referal_from_user = "";
   
           }


            this.setState({
                isLoading: true,
            });
            const res = await axios.post(constant.REGISTER,this.state).then((response) => {
                this.setState({
                    isLoading: false,
                });
                if(response.data.status == true){
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("userData", JSON.stringify(response.data));
                    localStorage.setItem("user_token", "Bearer " + response.data.token);
                    var header_data={
                        name: response.data.data.name,
                        matched: response.data.data.matched,
                        fulfilled: response.data.data.fulfilled,
                        wallet: response.data.data.wallet,
                    };
              
                    localStorage.setItem("header_data",JSON.stringify(header_data));
                    localStorage.setItem("login_verify","true");
                    
                    localStorage.setItem("is_refferal_user", 0); 
                    var refferal_data=
                        {
                             "referral_dealerhip_name":"",
                              "referal_from_user":""
                        };        
                    localStorage.setItem("referral_data",JSON.stringify(refferal_data));

                    this.setState({ isRedirect: true });

                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    const errors = Object.entries(response.data.errors); 
                    errors.forEach((error) => {
                        const [key, value] = error;
                        const index =  'errMsg'+(ucwords(key.replace("_", " ")).replace(" ", ""));
                        this.setState({
                            [index]: value[0],
                        });
                    });

                    function ucwords (str) {
                        return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                            return $1.toUpperCase();
                        });
                    }                    
                }
            }).catch(err => {
                console.log(err);
                this.setState({
                    isLoading: false,
                });
                toast.error("Something went wrong please try again", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        }
    };

    iAgree = (e) => {
        if (e.target.checked == true) {
            this.setState({ button: "submit" });
        } else {
            this.setState({ button: "button" });
        }
        // alert(e.target.value);
    };

    // useEffect(()=>{
    //     // setEmail('abidali');
    //     // setEmailError('asdasd');
    // },[]);

    render() {
        document.title = "Dealerhop | Register";

        if (this.state.isRedirect) {
            return <Navigate  to="/" />;
        }

        
        const userdata1 = localStorage.getItem("userData");
        const userdata =JSON.parse(userdata1);    
        
        
        let step =0;

        // console.log(userdata)
        if(userdata){
            step = (userdata.data.step);    
        }

        if(step == 2 || step == 1){
            return <Navigate  to="/step/contact_info" />;
        }else if(step == 3){
            return <Navigate  to="/step/account_balance" />;
        }else if(step == 4){
            return <Navigate  to="/available_customers" />;
        }else{}

        if (this.state.redirect) {
            return <Navigate  to="/available_customers" />;
          }
        const login = localStorage.getItem("isLoggedIn");
      
        if (login) {
          return <Navigate to="/available_customers" />;
        }
      
        

        return (
            <>

                <div className="vertical_logobox">
                    
                    <img src={ AppImages.logo_white }  />
                </div>
                <div className="rught_sidebox">
                    <div className="create_detailsbox">
                        <div className="create_innerboxdiv">
                            <form onSubmit={this.handleSubmit}>
                                <div ref={this.mapContainer} className="map-container" />
                                <h2>Create Your Account</h2>
                                <div className="account_inputbox">
                                    <p>Email Address* </p>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Enter Email Address"
                                        onChange={this.onChangehandler}
                                    />
                                    <span className="text-danger">
                                        {this.state.errMsgEmail}
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>Password*</p>
                                            <input
                                                type="password"
                                                name="password"
                                                placeholder="Enter Password"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgPassword}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>Confirm Password*</p>
                                            <input
                                                type="password"
                                                name="confirm_password"
                                                placeholder="Enter Confirm Password"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgconfirm_password}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>First Name* </p>
                                            <input
                                                type="text"
                                                name="first_name"
                                                placeholder="Enter First Name"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgFirstName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>Last Name*</p>
                                            <input
                                                type="text"
                                                name="last_name"
                                                placeholder="Enter Last Name"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgLastName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="account_inputbox">
                                    <p>Dealership Name*</p>
                                    <input
                                        type="text"
                                        name="dealership_name"
                                        placeholder="Enter Dealership Name"
                                        onChange={this.onChangehandler}
                                    />
                                    <span className="text-danger">
                                        {this.state.errMsgDealershipName}
                                    </span>
                                </div>
                                <div className="account_inputbox">
                                    <p>Dealership Location*</p>
                                    {/* <input
                                        type="text"
                                        name="dealership_location"
                                        placeholder="Enter Dealership Location"
                                        onChange={this.onChangehandler}
                                    /> */}
                                    {
                                        <Paper
                                        sx={{
                                            padding: "2px 4px",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}>
                                        
                                        <MapboxAutocomplete
                                            publicKey={
                                                this.mapAccess.mapboxApiAccessToken
                                            }
                                            onSuggestionSelect={
                                                (result,lat,long,text) => this.suggestionSelect(result,lat,long,text)    }
                                            country="ca"
                                            resetSearch={false}
                                            placeholder="Enter Dealership Location"
                                        />
                                    </Paper>
                                    }
                                    <span className="text-danger">
                                        {this.state.errMsgDealershipLocation}
                                    </span>
                                </div>
                                <div className="account_inputbox">
                                    <p>Primary Province of Service*</p>
                                    <input
                                        type="text"
                                        name="province"
                                        placeholder="Enter Primary Province"
                                        onChange={this.onChangehandler}
                                        value={this.state.province}
                                    />
                                    <span className="text-danger">
                                        {this.state.errMsgProvince}
                                    </span>
                                </div>
                                <div className="custam_checkbox">
                                    <label className="checkcontainer">
                                        Yes, I understand and agree to the
                                        Dealerhop's{" "}
                                        <Link to="/">Terms of Service</Link> ,
                                        and <Link to="/">Privacy Policy.</Link>
                                        <input
                                            type="checkbox"
                                            onClick={this.iAgree}
                                            name="agree"
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="createaccount_btn">
                                    <Button type={this.state.button}>
                                        Create Account
                                        {this.state.isLoading == true ? (
                                            <Loader />
                                        ) : null}
                                    </Button>
                                </div>
                            </form>
                        </div>
                        <div className="alreadyaccount_boxset">
                            <p>
                                Already have an account?{" "}
                                <Link to="/login">Login Here</Link>
                            </p>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </>
        );
    }
}

export default Register;
