import React,{ Component,useEffect,useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import SubHeader from '../../views/my_account/header';
import axios from "axios";
import { checkAuth, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Toastify, ErrorManage } from "../../utils/Common";
import { Modal,Button } from "react-bootstrap";
import InputMask from 'react-input-mask';
import Loader from "../layouts/loader";
import Header from '../../views/layouts/header';

// class AccountBalance extends Component{
const AccountBalance = props =>{
    const [title, setTitle] = useState("Dealerhop | Account Balance");
    const [cardsList, setCardsList] = useState([])
    const [show, setShow] = useState(false);
    const [card_number, setCardNumber] = useState("");
    const [errMsgCardNumber, setErrMsgCardNumber] = useState("");
    const [exp_date, setExpDate] = useState("");
    const [errMsgExpDate, setErrMsgExpDate] = useState("");
    const [cvv, setCvv] = useState("");
    const [errMsgCvv, setErrMsgCvv] = useState("");
    const [amount, setAmount] = useState(3500);
    const [errMsgAmount, setErrMsgAmount] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const [card_id, setCardId] = useState(false);
    const [isPaymentDone, setisPaymentDone] = useState(false);
    const [mywallet, setMyWallet] = useState("");
    const [shimmerEffect, setShimmerEffect] = useState('shimmerEffect');

    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setTimeout(() => {            
            document.querySelector('body').scrollTo(0,0);
        }, 100);
      }, []);

    useEffect(()=>{
        document.title = title;

        getProfile();
        getCards();
        setTimeout(() => {        
            
            setMyWallet(JSON.parse(localStorage.getItem('header_data')).wallet);
        }, 100);
    },[title])


    const getProfile = async(e) => {
        setShimmerEffect('shimmerEffect');
        const res = await axios({
            method: 'GET',
            url: constant.GETPROFILE,
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
          }).then((response) => {
            checkAuth(response);
            setShimmerEffect('');
            if(response.data.status == true){
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("userData", JSON.stringify(response.data));
                localStorage.setItem("user_token", "Bearer " + response.data.token);
            } else {
                console.log(response);
            }
        }).catch(err => {
            checkAuth(err);
            setShimmerEffect('');
            console.log(err);
        });
    }


    const getCards = async(e) => {
        setShimmerEffect('shimmerEffect');
        const res = await axios({
            method: 'GET',
            url: constant.GETCARDS,
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
          }).then((response) => {
            setShimmerEffect('');
            if(response.data.status == true){
                setCardsList(response.data.data);
                console.log(response.data.data);
            } else {
                console.log(response);
            }
        }).catch(err => {
            checkAuth(err);
            setShimmerEffect('');
            console.log(err);
        });
    }

    const updateCard = async(id) => {
        const res = await axios({
            method: 'POST',
            url: constant.UPDATECARD,
            data:{
                'card_id':id
            },
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
          }).then((response) => {
            checkAuth(response);
            setShimmerEffect('');
            if(response.data.status == true){
                setCardId(id)
                setCardsList([]);
                getCards();
                Toastify(response.data.message,'success');
                
            } else {
                Toastify(response.data.message,'warn');  
                console.log(response.data);
            }
        }).catch(err => {
            checkAuth(err);
            setShimmerEffect('');
            Toastify("Something went wrong please try again",'error');
            console.log(err);
        });
    }

    const options = {
        labels: {
          confirmable: "Confirm",
          cancellable: "Cancel"
        }
      }

    const cardDelete = (id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    const res =  axios({
                        method: 'POST',
                        url: constant.DELETECARD,
                        data:{
                            'card_id':id
                        },
                        headers: {
                          Authorization: localStorage.getItem('user_token'),
                        },
                    }).then((response) => {
                        checkAuth(response);
                        if(response.data.status == true){
                            setCardsList([]);
                            getCards();
                            Toastify(response.data.message,'success');
                            
                        } else {
                            Toastify(response.data.message,'warn');  
                            console.log(response.data);
                        }
                    }).catch(err => {
                        checkAuth(err);
                        Toastify("Something went wrong please try again",'error');
                        console.log(err);
                    });
                }
              },
              {
                label: 'No',
                // onClick: () => alert('Click No')
              }
            ]
        });
    }

    const changeAmount = (amount) => {
        console.log(amount);
        setAmount(amount);
    }

    const addPaymentCard = async() => {
        var i = 0;
        setErrMsgCardNumber('');
        setErrMsgExpDate('');
        setErrMsgCvv('');

        if(card_number.length == 0 ){
            i++;
            setErrMsgCardNumber('This field is required.');
        }

        if(cvv.length == 0 ){
            i++;
            setErrMsgCvv('This field is required.');
        }

        if(exp_date.length == 0 ){
            i++;
            setErrMsgExpDate('This field is required.');
        }

        if(i == 0){
            setIsLoading(true)
            const res = await axios({
                method: 'POST',
                url: constant.ADDCARD,
                data:{
                    'card_number':card_number,
                    'exp_date':exp_date,
                    'cvv':cvv
                },
                headers: {
                  Authorization: localStorage.getItem('user_token'),
                },
              }).then((response) => {
                setIsLoading(false);
                checkAuth(response);
                if(response.data.status == true){

                    let hd = JSON.parse(localStorage.getItem('header_data'));
                    var header_data={
                     name: hd.name,
                     matched: hd.matched,
                     fulfilled: hd.fulfilled,
                     wallet: response.data.total_credits,
                 };
                 setMyWallet(response.data.total_credits);
                 localStorage.setItem("header_data",JSON.stringify(header_data));

                    setCardsList([]);
                    getCards();
                    Toastify(response.data.message,'success');
                    setCardNumber('');
                    setCvv('');
                    setShow(false);


                  

                } else {
                    // ErrorManage(response);
                    Toastify(response.data.message,'warn');  
                    // console.log(response.data);
                }
            }).catch(err => {
                checkAuth(err);
                Toastify("Something went wrong please try again",'error');
                console.log(err);
            });
        }
    }

    const addPaymentMothed = async() => {
        setIsLoading(true)
        setErrMsgAmount('');
        const res = await axios({
            method: 'POST',
            url: constant.ADDPAYMENT,
            data:{
                'card_id':card_id,
                'amount':amount
            },
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
          }).then((response) => {
            setIsLoading(false);
            if(response.data.status == true){
               
               
                let hd = JSON.parse(localStorage.getItem('header_data'));
                var header_data={
                 name: hd.name,
                 matched: hd.matched,
                 fulfilled: hd.fulfilled,
                 wallet: response.data.total_credits,
             };
             setMyWallet(response.data.total_credits);
             localStorage.setItem("header_data",JSON.stringify(header_data));

                setCardsList([]);
                getCards();
                setAmount('');
                Toastify(response.data.message,'success');
                window.location.reload();
            } else {
                
                ErrorManage(response);
                Toastify(response.data.message,'warn');  
                // console.log(response.data);
            }
        }).catch(err => {
            Toastify("Something went wrong please try again",'error');
            console.log(err);
        })
    }

    const userdata1 = localStorage.getItem("userData");
        const userdata = JSON.parse(userdata1);

        if (userdata.data.is_main_account == 0) {
            return <Navigate to="/" />;
        }

       
         
       
        return (
            <>
                    <Header/>
                    <div className="contentCoverbox setfooter_addclass1">
                        <LeftSiderbar />
                        <div
                            className="rightsidecontent"                            
                        >
                            <SubHeader />
                            <div className="myaccount_mandiv">
                                <div className="create_detailsbox">
                                    <div className="create_innerboxdiv">
                                        <h2>Account Balance</h2>
                                        <p className="accountbalance_textbox">
                                            Your current account balance is
                                            ${
                                               (mywallet)
                                               ?
                                               (mywallet)
                                               :
                                               0.00
                                               
                                            }. {
                                                parseInt(mywallet) > 0 ? 
                                                <></> 
                                                : 
                                                'Please add money into your account to match with customers.' 
                                            }
                                        </p>
                                        <div className="balance_textbox">
                                            <ul>
                                                <li className={amount == 3500 ? 'active' : ''}>
                                                    <a href={void 0} onClick={()=>changeAmount(3500)}>$3,500</a>
                                                </li>
                                                <li className={amount == 5000 ? 'active' : '' }>
                                                    <a href={void 0} onClick={()=>changeAmount(5000)}>$5,000</a>
                                                </li>
                                                <li className={amount == 7500 ? 'active' : '' }>
                                                    <a href={void 0} onClick={()=>changeAmount(7500)}>$7,500</a>
                                                </li>
                                            </ul>
                                            <span className="text-danger">
                                                {errMsgAmount}
                                            </span>
                                            <h3>Your Credit Card Details</h3>
                                            <p>
                                                Your credit card details are
                                                stored securely by Stripe.
                                            </p>
                                        </div>

                                            
                                        {cardsList.length > 0
                                            ? cardsList.map((card, index) => (
                                                  <div
                                                      key={index}
                                                      className={`Payment_checkboxdiv ${shimmerEffect}`}
                                                  >
                                                      <p>{card.card_type}</p>
                                                      <label className="container">
                                                          Card Ending in{" "}
                                                          {card.last4}
                                                          <img
                                                              src={
                                                                  require("../../assets/svg/Payment.svg")
                                                                      .default
                                                              }
                                                          />
                                                          {card.is_selected ==
                                                          true ? (
                                                              <>
                                                                  <input
                                                                      type="radio"
                                                                      name="card_id"
                                                                      value="card.card_id"
                                                                      defaultChecked={
                                                                          true
                                                                      }
                                                                      onClick={() =>
                                                                          updateCard(
                                                                              card.card_id
                                                                          )
                                                                      }
                                                                  />
                                                              </>
                                                          ) : (
                                                              <input
                                                                  type="radio"
                                                                  name="card_id"
                                                                  value={
                                                                      card.card_id
                                                                  }
                                                                  onClick={() =>
                                                                      updateCard(
                                                                          card.card_id
                                                                      )
                                                                  }
                                                              />
                                                          )}
                                                          <span className="checkmark"></span>
                                                      </label>
                                                      {card.is_selected ==
                                                      true ? (
                                                          <a href={void 0}
                                                                style={{
                                                                    color: "#E6E6E6",
                                                                    fontSize:"12px"
                                                                }}>
                                                              Delete Payment
                                                              Method
                                                          </a>
                                                      ) : (
                                                          <a
                                                              href={void 0}
                                                              style={{
                                                                  color: "#FF4141",
                                                                  fontSize:"12px"
                                                              }}
                                                              onClick={() =>
                                                                  cardDelete(
                                                                      card.card_id
                                                                  )
                                                              }
                                                          >
                                                              Delete Payment
                                                              Method
                                                          </a>
                                                      )}
                                                  </div>
                                              ))
                                            : cardsList.length == 0 ? <></>
                                            :
                                            [1,2,3].map((index)=>(
                                                <div key={index} className="Payment_checkboxdiv">
                                                    <p className="shimmerEffect">Visa</p>
                                                    <label className="container shimmerEffect">Card Ending in 4242
                                                        <input type="radio" name="card_id" value="card.card_id" defaultChecked="true"/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <a className="shimmerEffect">Delete Payment Method</a>
                                                </div>
                                            ))
                                        }
                                        <br />
                                        <br />
                                        <a className="setbtncls" href={void(0)} onClick={() => setShow(true)}>Add Card</a>
                                        <div className="createaccount_btn">
                                            <a href={void 0} onClick={()=>addPaymentMothed()}>
                                                Make A Payment
                                                {isLoading == true ? (
                                                <Loader />
                                            ) : null}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer />
                        </div>
                        <Footer />
                    </div>
                    <div className="addpayment_modalmandiv">
                        <Modal show={show} onHide={handleClose} size="md">
                            <Modal.Header closeButton>
                                {/* <button type="button" className="btn-close" data-dismiss="modal">
                                <img src={ require('../../assets/svg/hamburger-close.svg').default } />
                            </button> */}
                                <h4 className="modal-title">
                                    Add Payment Method
                                </h4>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div id="newCardBox">
                                        <div className="card_typeinputbox">
                                            <p>Card Number* </p>
                                            <InputMask
                                                name="card_number"
                                                value={card_number}
                                                mask="9999-9999-9999-9999"
                                                onChange={(e) =>
                                                    setCardNumber(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="XXXX - XXXX - XXXX - XXXX - XXXX"
                                            />
                                            <img
                                                src={
                                                    require("../../assets/svg/Credit-Card-icon.svg")
                                                        .default
                                                }
                                            />
                                            <span className="text-danger">
                                                {errMsgCardNumber}
                                            </span>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card_typeinputbox">
                                                    <p>CVV * </p>
                                                    <InputMask
                                                        type="text"
                                                        className="form-control mask"
                                                        name="cvv"
                                                        mask="9999"
                                                        value={cvv}
                                                        onChange={(e) =>
                                                            setCvv(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="e.g. 123"
                                                    />
                                                    <img
                                                        src={
                                                            require("../../assets/svg/Credit-Card-icon.svg")
                                                                .default
                                                        }
                                                    />
                                                    <span className="text-danger">
                                                        {errMsgCardNumber}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card_typeinputbox">
                                                    <p>Expiry Date* </p>
                                                    <InputMask
                                                        type="text"
                                                        className="form-control mask"
                                                        name="exp_date"
                                                        value={exp_date}
                                                        mask="99/99"
                                                        placeholder="MM/YY"
                                                        onChange={(e) =>
                                                            setExpDate(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <img
                                                        src={
                                                            require("../../assets/svg/Credit-Card-icon.svg")
                                                                .default
                                                        }
                                                    />
                                                    <span className="text-danger">
                                                        {errMsgExpDate}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="savepayment_divbox">
                                        <a href={void 0} onClick={()=>addPaymentCard()}>
                                            SAVE PAYMENT METHOD
                                            {isLoading == true ? (
                                            <Loader />
                                        ) : null}
                                        </a>
                                        {/* <Button type="button">SAVE PAYMENT METHOD</Button> */}
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="secured_paytextbox">
                                    <p>
                                        {" "}
                                        <img
                                            src={
                                                require("../../assets/svg/secured.svg")
                                                    .default
                                            }
                                        />{" "}
                                        All payments are powered and secured by
                                        Stripe
                                    </p>
                                    <img
                                        className="Stripeicon_div"
                                        src={
                                            require("../../assets/svg/Stripe-icon.svg")
                                                .default
                                        }
                                    />
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                
            </>
        );
}

export default AccountBalance;