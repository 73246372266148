import { Button } from "react-bootstrap";
import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Loader from './../layouts/loader'
import { AppImages, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeftSiderbar from '../layouts/left-sidebar';
import Header from '../layouts/header';
import $ from 'jquery';


class AddUser extends Component {

    mapAccess = {
        mapboxApiAccessToken:
            "pk.eyJ1IjoiZGV2ZWxvcGVyYWJpZGFsaSIsImEiOiJjbDF3MG16NzUxNGg0M2txdnNtcDgzbmR2In0.BIJcWX7_Z30IXudESTAbbw"
    };

    
    // function Register(){
    // const Register = props =>{
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            user_id: "",
            errMsgEmail: "",
            password: "",
            errMsgPassword: "",
            confirm_password: "",
            errMsgConfirmPassword: "",
            first_name: "",
            errMsgFirstName: "",
            last_name: "",
            errMsgLastName: "",
            dealership_name: "",
            errMsgDealershipName: "",
            dealership_location: "",
            errMsgDealershipLocation: "",
            isLoading: false,
            isRedirect: false,
            button: "button",
            longitude: "",
            latitude: "",
            zoom: 9,
            province: "",
            errMsgProvince: "",
        };

        this.onChangehandler = this.onChangehandler.bind(this);
        this.getContactData();
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        // this.setState({dealership_location : e})
    };

    suggestionSelect(result, lat, long, text) {
        axios({
            method: "GET",
            url: constant.GEOCODING,
            params: {
                latitude: lat,
                longitude: long,
            },
        })
            .then((response) => {
                this.setState({
                    province: response.data.province,
                });
            });
        this.setState({
            dealership_location: result,
            latitude: lat,
            longitude: long,
        });
    }


    handleSubmit = async (e) => {

        e.preventDefault();

        var i = 0;

        // alert($('[name="confirm_password"]').val());
        this.setState({
            errMsgEmail: "",
            errMsgPassword: "",
            errMsgconfirm_password: "",
            errMsgFirstName: "",
            errMsgLastName: "",
            errMsgDealershipName: "",
            errMsgPhone: "",
            errMsgDealershipLocation: "",
            errMsgProvince: "",
        });
        if (this.state.email.trim().length === 0) {
            i++;
            this.setState({ errMsgEmail: "This field is required" });
        }
      

        if (this.state.first_name.trim().length === 0) {
            i++;
            this.setState({
                errMsgFirstName: "This field is required",
            });
        }

        if (this.state.last_name.trim().length === 0) {
            i++;
            this.setState({
                errMsgLastName: "This field is required",
            });
        }

        // if (this.state.dealership_name.trim().length === 0) {
        //     i++;
        //     this.setState({
        //         errMsgDealershipName: "This field is required",
        //     });
        // }

        if (this.state.phone.trim().length === 0) {
            i++;
            this.setState({
                errMsgPhone: "This field is required",
            });
        }

        // if (this.state.dealership_location.trim().length === 0) {
        //     i++;
        //     this.setState({
        //         errMsgDealershipLocation:"This field is required",
        //     });
        // }

        // if (this.state.province.trim().length === 0) {
        //     i++;
        //     this.setState({
        //         errMsgProvince:
        //             "This field is required",
        //     });
        // }   

    
        if (i === 0) {
            this.setState({
                isLoading: true,
            });
    
                axios({
                      method: 'POST',
                      url: constant.UPDATEUSER,
                      params: {
                        user_id: this.state.user_id,  
                        email: this.state.email,  
                        first_name: this.state.first_name,  
                        last_name: this.state.last_name,  
                        city: this.state.city,  
                        phone: this.state.phone,  
                        // dealership_name: this.state.dealership_name,  
                        // dealership_location: this.state.dealership_location,  
                        // country: this.state.country,  
                        // province: this.state.province,  
                        // latitude: this.state.latitude,  
                        // longitude: this.state.longitude,  
                     },
                      headers: {
                        Authorization: localStorage.getItem('user_token'),
                      },
                    })
                     .then((response) => {
                          this.setState({shimmerEffect:''});                                            
                          if(response.data.status === true){  
                              
                              this.setState({isRedirect:true})        
                              toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                          }
                          
                      })
                      .catch((error) => {        
                          
                          this.setState({shimmerEffect:''});                                            
                          console.log('error call ', error);                  
                      });

        }
    };


    getContactData = async () => {


        let data1  =window.location.pathname.split('/');
       let id = data1[data1.length-1];
        axios({
            method: 'GET',
            url: constant.GETUSERPROFILE,
            params: {
                id: id,
                
            },
            headers: {
                Authorization: localStorage.getItem('user_token'),
            },
        })
            .then((response) => {
                console.log('response ', response);
                this.setState({ shimmerEffect: '' });
                if (response.data.status === true) {

                    this.setState({ user_id: response.data.data.id });
                    this.setState({ email: response.data.data.email });
                    this.setState({ phone: response.data.data.phone });
                    this.setState({ dealership_name: response.data.data.dealership_name });
                    this.setState({ first_name: response.data.data.first_name });
                    this.setState({ last_name: response.data.data.last_name });
                    this.setState({ province: response.data.data.province });
                    this.setState({ dealership_location: response.data.data.dealership_location });

                    setTimeout(() => {    
                        $(".react-mapbox-ac-input").val(response.data.data.dealership_location)
                }, 100);
                }

            })
            .catch((error) => {
                this.setState({ shimmerEffect: '' });
                console.log('error call ', error);
            });
    }
    iAgree = (e) => {
        if (e.target.checked === true) {
            this.setState({ button: "submit" });
        } else {
            this.setState({ button: "button" });
        }
        // alert(e.target.value);
    };

    // useEffect(()=>{
    //     // setEmail('abidali');
    //     // setEmailError('asdasd');
    // },[]);

    render() {
        document.title = "Dealerhop | Edit User";

        if (this.state.isRedirect) {
            return <Navigate to="/user/list" />;
        }


        const userdata1 = localStorage.getItem("userData");
        const userdata = JSON.parse(userdata1);

        if (userdata.data.is_main_account === 0) {
            return <Navigate to="/" />;
        }
        if(userdata.data.is_approved_by_admin === 0){
            return <Navigate to="/available_customers" />;
        }
        let step = 0;

        // console.log(userdata)
        if (userdata) {
            step = (userdata.data.step);
        }



        return (
            <>
                <Header />
                <div className="vertical_logobox">
                    <img src={ AppImages.logo_black }  />                
                </div>
                <div className="rught_sidebox">
                    <LeftSiderbar />
                    <div className="create_detailsbox">
                        <div className="create_innerboxdiv">
                            <form onSubmit={this.handleSubmit}>
                                <div ref={this.mapContainer} className="map-container" />
                                <h2>Edit User</h2>
                               
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>First Name* </p>
                                            <input
                                                type="text"
                                                name="first_name"
                                                value={this.state.first_name}
                                                placeholder="Enter First Name"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgFirstName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="account_inputbox">
                                            <p>Last Name*</p>
                                            <input
                                                type="text"
                                                name="last_name"
                                                placeholder="Enter Last Name"
                                                value={this.state.last_name}
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgLastName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">


                                    {/* <div className="col-md-6">

                                        <div className="account_inputbox">
                                            <p>Dealership Name*</p>
                                            <input
                                                type="text"
                                                name="dealership_name"
                                                placeholder="Enter Dealership Name"
                                                value={this.state.dealership_name}
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgDealershipName}
                                            </span>
                                        </div>
                                    </div> */}

                                    <div className="col-md-6">
                                    <div className="account_inputbox">
                                    <p>Email Address* </p>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Enter Email Address"
                                        value={this.state.email}
                                        onChange={this.onChangehandler}
                                    />
                                    <span className="text-danger">
                                        {this.state.errMsgEmail}
                                    </span>
                                </div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="account_inputbox">
                                            <p>Phone*</p>
                                            <input
                                                type="text"
                                                name="phone"
                                                value={this.state.phone}
                                                placeholder="Enter phone"
                                                onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">
                                                {this.state.errMsgPhone}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="account_inputbox">
                                    <p>Dealership Location*</p>
                                    {
                                        <Paper
                                            sx={{
                                                padding: "2px 4px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}>

                                            <MapboxAutocomplete
                                                publicKey={
                                                    this.mapAccess.mapboxApiAccessToken
                                                }
                                                onSuggestionSelect={
                                                    (result, lat, long, text) => this.suggestionSelect(result, lat, long, text)}
                                                country="ca"
                                                resetSearch={false}
                                                placeholder="Enter Dealership Location"
                                                value={this.state.dealership_location}
                                            />
                                        </Paper>
                                    }
                                    <span className="text-danger">
                                        {this.state.errMsgDealershipLocation}
                                    </span>
                                </div>
                                <div className="account_inputbox">
                                    <p>Primary Province of Service*</p>
                                    <input
                                        type="text"
                                        name="province"
                                        placeholder="Enter Primary Province"
                                        onChange={this.onChangehandler}
                                        value={this.state.province}
                                    />
                                    <span className="text-danger">
                                        {this.state.errMsgProvince}
                                    </span>
                                </div> */}

                                <div className="createaccount_btn">
                                    <Button type="submit">
                                        Update
                                        {this.state.isLoading === true ? (
                                            <Loader />
                                        ) : null}
                                    </Button>
                                </div>
                            </form>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </>
        );
    }
}

export default AddUser;
