import React,{ Component } from "react";

class Loader extends Component{
    render() {
        return (
            <>
                <span style={{marginLeft:"7px"}}
                    className="spinner-border spinner-border-sm ml-5"
                    role="status"
                    aria-hidden="true"
                ></span>
            </>

        )
    }
}

export default Loader;