import React, { useState, useEffect } from "react";
import Footer from "../../views/layouts/footer";
import LeftSiderbar from "../../views/layouts/left-sidebar";
import SubHeader from "../../views/my_account/header";
import axios from "axios";
import {  constant } from "../../utils/constant";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../views/layouts/header";
import NoHistory from "./no-history";
import Nodata from "./nodatafound";
// class AccountSetting extends Component{
const AccountSetting = () => {
    // render() {
    const [title, setTitle] = useState("Dealerhop | Refer And Earn");
    const [refferalHistoryData, setRefferalHistoryData] = useState([]);
    const [shimmerEffect, setShimmerEffect] = useState("shimmerEffect");
    // const [isLoading, setIsLoading] = useState(false);
    const [reffraLlink, setReffraLlink] = useState("");
    const [isNoData, setIsNoData] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            document.querySelector("body").scrollTo(0, 0);
        }, 100);
    }, []);

    useEffect(() => {
        getProfile();
        document.title = title;
    }, []);

	const handleShow = () => {
        navigator.clipboard.writeText(reffraLlink);
        toast.success("Link copied", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };


    const getProfile = async () => {
        setShimmerEffect("shimmerEffect");
        // const USERTOKEN =  localStorage.getItem('user_token');
        axios({
            method: "GET",
            url: constant.GETREFFREALHISTORY,
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                if (response.data.status === true) {
                    setIsNoData(false);
                    setReffraLlink(response.data.refferal_link);
                    setRefferalHistoryData(response.data.data);
                }
                setShimmerEffect("");
            })
            .catch((error) => {
                setIsNoData(false);
                setShimmerEffect("");
                console.log("error call ", error);
            });
    };

    return (
        <>
            <Header />
            <div className="contentCoverbox setfooter_addclass1">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <SubHeader />
                    <div className="myaccount_mandiv">
                        <div className="create_detailsbox">
                            <div className="create_innerboxdiv">
                                <div className="refer_textbox">
                                    <h3>Refer New Dealers</h3>
                                    <p>
                                        Refer a partner and earn credits to
                                        match.
                                    </p>
                                </div>
                                <div className="price_coddiv">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="inner_pricebox">
                                                <p>$3,500</p>
                                                <h3>$1,500</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="inner_pricebox">
                                                <p>$5,000</p>
                                                <h3>$2,500</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="inner_pricebox">
                                                <p>$7,500</p>
                                                <h3>$3,750</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="referral_linkbox">
                                    <h3>Referral link:</h3>
                                    <p>
                                        Share the link below with your network
                                        to earn free matches based on the new
                                        dealer's initial prepayment.
                                    </p>
                                    <a onClick={handleShow} className={shimmerEffect} href="javascript:void(0)">                                        
                                        {reffraLlink}
                                    </a>
                                </div>
                                <div className="referral_history">
                                    <h3>Referral history</h3>
                                     <div className="scrollbar_boxdiv"> 
                                    {refferalHistoryData.length > 0 ? (
                                       
                                        refferalHistoryData.map((item, index) => {
                                            return (
                                                <RefferalHistoryCell
                                                    item={item}
                                                    key={index}
                                                />
                                            );
                                        })
                                        
                                        ) : (
                                            <>
                                                {isNoData ? (
                                                    <NoHistory shimmer={shimmerEffect} />
                                                ) : (
                                                    <Nodata />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </>
    );
    // }
};

export default AccountSetting;

const RefferalHistoryCell = (props) => {
    return (
        <ul>
            <li className="cmnwidthref">
                <span>{props?.item?.refer_date}</span>
            </li>
            <li className="cmnwidthmsg">
                <span>{props?.item?.message}</span>
            </li>
            <li className="cmnwidthamount">
                <span>${props?.item?.amount}</span>
            </li> 
                       
        </ul>
         
    );
};
