/* eslint-disable no-undef */
import axios from "axios";
import { Component, useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { AppImages, checkAuth, constant } from "../../utils/constant";
import Footer from "../layouts/footer";
import LeftSiderbar from "../layouts/left-sidebar";
// import Header from '../my_account/header';
import Header from "../../views/layouts/header";
import { Modal, Button, Form, FormCheck } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../layouts/loader";
import { message } from "laravel-mix/src/Log";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";

// class CurrentCustomers extends Component{
const LeadDetail = () => {
    const [leadData, setLeadData] = useState(null);
    const [taskData, setTaskData] = useState(null);
    const [singleLeadData, setSingleLeadData] = useState(null);
    const [singleLeadData1, setSingleLeadData1] = useState(null);
    const [singleLeadData2, setSingleLeadData2] = useState(null);
    const [singleLeadData3, setSingleLeadData3] = useState(null);
    const [show, setShow] = useState(false);

    const [emailModelshow, setEmailShow] = useState(false);
    const params = useParams();
    const [id, setId] = useState(params.id);

    const [note, setNote] = useState("");
    const [err_note, setErrNote] = useState("");
    const [is_sold, setIsSold] = useState(0);
    const [relation_id, setRelationId] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [err_phone, setErrPhone] = useState("");
    const [is_save_me, setIsSaveME] = useState(1);

    // MAIL
    const [email, setEmail] = useState("");
    const [mail_message, setMailMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [err_subject, setErrSubject] = useState("");
    const [err_mail_message, setErrMailMessage] = useState("");
    const [lead_id, setLeadId] = useState("");
    const [driver_license_link, setDlLink] = useState("");
    const [income_certificate, setIncomeLink] = useState("");

    const [current_module_open, setCurrentModuleOpen] = useState("text_customer");

    const [selectedDescription, setSelectedDescription] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedHourValue, setSelectedHourValue] = useState(1);
    const [selectedMinute, setSelectedMinute] = useState(0);
    const [isLoadingtask, setIsLoadingtask] = useState(false);

    const [err_description, setErrdescription] = useState("");
    const [err_due, setErrDueDate] = useState("");
    const [err_hour, setErrhour] = useState("");
    const [err_minut, setErrMinut] = useState("");

    useEffect(() => {
        setId(params.id);
        getCustomerLeadData();
        getTaskData();
        setTimeout(() => {
            setNumber(leadData?.phone);
        }, 100);
    }, []);
    setTimeout(() => {
        // document.querySelector("body").scrollTo(0, 0);
    }, 100);

    const handleShow = () => {
        setShow(true);
    };
    const handleEmailShow = () => {
        setEmailShow(true);
    };

    const setOpenModule = (e) => {
        console.log(e);
        setCurrentModuleOpen(e);
    };

    const handleClose = () => setShow(false);
    const handleEmailClose = () => setEmailShow(false);

    const getCustomerLeadData = async () => {
        axios({
            method: "GET",
            url: constant.GETSINGLELEAD,
            params: {
                id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                // console.log("response ", response.data.data);

                if (response.data.status == true) {
                    //
                    setIsSaveME(response.data.is_save_me);

                    // setTaskData(response.data.taskdata);
                    setLeadData(response.data.data);
                    setSingleLeadData(response.data.single_lead_data);
                    setSingleLeadData1(response.data.single_lead_data_1);
                    setSingleLeadData2(response.data.single_lead_data_2);
                    setSingleLeadData3(response.data.single_lead_data_3);
                    setDlLink(response.data.driver_license_link);
                    setIncomeLink(response.data.income_certificate);

                    setNote(response?.data?.data?.user_lead_data?.note);
                    setIsSold(response.data.data?.user_lead_data?.is_sold);
                    setRelationId(response.data.data?.relation_id);
                    setNumber(response.data.data?.phone);
                    setLeadId(response.data.data?.id);
                    setEmail(response.data.data?.email);
                    // document.querySelector("body").scrollTo(0, 0);
                }
            })
            .catch((error) => {
                console.log("error call ", error);
            });
    };

    const getTaskData = async () => {
        axios({
            method: "GET",
            url: constant.GETTASKTOLEAD,
            params: {
                id: id,
            },
            headers: {
                Authorization: localStorage.getItem("user_token"),
            },
        })
            .then((response) => {
                // console.log("response ", response.data.data);

                if (response.data.status == true) {
                    setTaskData(response.data.data);
                }
            })
            .catch((error) => {
                console.log("error call ", error);
            });
    };

    const updateNoteSoldStatus = async (e) => {
        var error = 0;
        setErrNote("");
        if (e == "note") {
            if (note.length <= 0) {
                error++;
                setErrNote("This field is required");
            }
        }

        const USERTOKEN = localStorage.getItem("user_token");
        var new_set = 1;
        if (is_sold == 1) {
            new_set = 0;
        } else if (is_sold == 0) {
            new_set = 1;
        }
        //alert(new_set)
        if (error <= 0) {
            setIsLoading(true);
            axios({
                method: "POST",
                url: constant.UPDATENOTESOLD,
                data: {
                    flag: e,
                    note: note,
                    is_sold: new_set,
                    relation_id: relation_id,
                },
                headers: {
                    Authorization: USERTOKEN,
                },
            })
                .then((response) => {
                    setIsLoading(false);
                    //  console.log('response ', response);
                    if (response.data.status == true) {
                        setIsSold(new_set);

                        var header_data = {
                            name: response.data?.name,
                            matched: response.data?.matched,
                            fulfilled: response.data?.fulfilled,
                            wallet: response.data.total_credits,
                        };

                        localStorage.setItem(
                            "header_data",
                            JSON.stringify(header_data)
                        );

                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((error) => {
                    console.log("error call ", error);
                });
        }
    };

    const sendMessage = async () => {
        var i = 0;
        setErrPhone("");
        if (leadData?.phone.length <= 0) {
            i++;
            setErrPhone("Invalid phone number");
        }

        if (message.length <= 0) {
            i++;
            setErrPhone("");
            setErrPhone("This field is required");
        }

        setIsLoading(true);
        const USERTOKEN = localStorage.getItem("user_token");
        axios({
            method: "POST",
            url: constant.SENDMESSAGE,
            data: {
                message: message,
                number: number,
            },
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                setIsLoading(false);
                //  console.log('response ', response);
                if (response.data.status == true) {
                    setShow(false);
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                if (response.data.status == false) {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log("error call ", error);
            });
    };

    const sendMail = async () => {
        var i = 0;
        setErrMailMessage("");
        setErrSubject("");
        if (leadData?.email.length <= 0) {
            i++;
            setErrMailMessage("");
            setErrMailMessage("Invalid email address");
        }

        if (mail_message.length <= 0) {
            i++;
            setErrMailMessage("");
            setErrMailMessage("This field is required");
        }

        if (subject.length <= 0) {
            i++;
            setErrSubject("This field is required");
        }

        setIsLoading(true);
        const USERTOKEN = localStorage.getItem("user_token");
        axios({
            method: "POST",
            url: constant.SENDMAIL,
            data: {
                email: email,
                message: mail_message,
                subject: subject,
                lead_id: lead_id,
            },
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                checkAuth(response);
                setIsLoading(false);
                //  console.log('response ', response);
                if (response.data.status == true) {
                    setEmailShow(false);
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                if (response.data.status == false) {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                checkAuth(error);
                setEmailShow(false);
                console.log("error call ", error);
            });
    };

    /* ADD TASK  */

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleHourChange = (event) => {
        setSelectedHourValue(event.target.value);
    };

    const options = [];
    for (let i = 1; i <= 23; i++) {
        if (i >= 1 && i <= 9) {
            i = "0" + i;
        }

        options.push(
            <option key={i} value={i}>
                {i}
            </option>
        );
    }

    const minoptions = [];
    for (let j = 0; j <= 59; j++) {
        if (j >= 0 && j <= 9) {
            j = "0" + j;
        }

        minoptions.push(
            <option key={j} value={j}>
                {j}
            </option>
        );
    }

    const handleMinuteChange = (event) => {
        const newMinute = event.target.value;
        setSelectedMinute(newMinute);
    };

    const AddTask = async (e) => {

        if(isLoadingtask) return
        var error = 0;

        setErrdescription("");
        setErrDueDate("");
        setErrhour("");
        setErrMinut("");

        if (selectedDescription?.length <= 0) {
            error++;
            setErrdescription("This field is required");
        }

        if (selectedDate?.length <= 0) {
            error++;
            setErrDueDate("This field is required");
        }

        if (selectedHourValue?.length <= 0) {
            error++;
            setErrhour("This field is required");
        }

        if (selectedMinute?.length <= 0) {
            error++;
            setErrMinut("This field is required");
        }

        const USERTOKEN = localStorage.getItem("user_token");
        if (error <= 0) {
            setIsLoadingtask(true);
            axios({
                method: "POST",
                url: constant.ADDTASKTOLEAD,
                data: {
                    description: selectedDescription,
                    due_date: moment(selectedDate).format("YYYY-MM-DD"),
                    hours: selectedHourValue,
                    minute: selectedMinute,
                    lead_id: id,
                },
                headers: {
                    Authorization: USERTOKEN,
                },
            })
                .then((response) => {
                    setIsLoadingtask(false);
                    //  console.log('response ', response);
                    if (response.data.status == true) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        setSelectedDescription("");
                        setSelectedDate("");
                        setSelectedHourValue(1);
                        setSelectedMinute(0);
                        getTaskData();
                    }
                })
                .catch((error) => {
                    console.log("error call ", error);
                });
        }
    };

    const taskUpdateLead = async (e, flag) => {
        console.log(e);
        const USERTOKEN = localStorage.getItem("user_token");
        axios({
            method: "POST",
            url: constant.UPDATETASKLEAD,
            data: {
                id: e.id,
                flag: flag,
            },
            headers: {
                Authorization: USERTOKEN,
            },
        })
            .then((response) => {
                setIsLoadingtask(false);
                //  console.log('response ', response);
                if (response.data.status == true) {
                    setTaskData(response.data.data);
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log("error call ", error);
            });
    };

    /* ADD TASK */

    if (is_save_me == 0) {
        return <Navigate to="/available_customers" />;
    }

    return (
        <>
            <Header />
            <div className="contentCoverbox newsetfooter_addclass">
                <LeftSiderbar />
                <div className="rightsidecontent">
                    <div className="customers_boxdiv">
                        <div className="row">
                            <div className="col-md-12">
                            {leadData == null ? ( 
                                <>
                                <div className="customer_btndiv1">
                                    <a 
                                    className={`shimmerEffect`}
                                        href="javascript:void(0)"
                                        
                                    >
                                        Text Customer
                                    </a>
                                    <a
                                    className={`shimmerEffect`}
                                        href="javascript:void(0)"
                                        
                                    >
                                        Email Customer
                                    </a>
                                    <a
                                     className={`shimmerEffect`}
                                
                                        href="javascript:void(0)"
                                      
                                    >
                                        Add Task
                                    </a>                                 
                                    <input type="hidden" value={is_sold} />
                                    <label className={`checkcontainer shimmerEffect`}>
                                        Sold?
                                        <input
                                            type="checkbox"
                                            checked={is_sold == 1}
                                            
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                </>
                                ) : (
                                    
                                <div className="customer_btndiv">
                                    <a 
                                    className={`${
                                        current_module_open === "text_customer"
                                            ? "active"
                                            : ""
                                    }`}
                                        href="javascript:void(0)"
                                        onClick={() =>
                                            setOpenModule("text_customer")
                                        }
                                    >
                                        Text Customer
                                    </a>
                                    <a
                                    className={`${
                                        current_module_open === "email_customer"
                                            ? "active"
                                            : ""
                                    }`}
                                        href="javascript:void(0)"
                                        onClick={() =>
                                            setOpenModule("email_customer")
                                        }
                                    >
                                        Email Customer
                                    </a>
                                    <a
                                     className={`${
                                        current_module_open === "addtask"
                                            ? "active"
                                            : ""
                                    }`}
                                        href="javascript:void(0)"
                                        onClick={() => setOpenModule("addtask")}
                                    >
                                        Add Task
                                    </a>

                                    {/* <a
                                        href="javascript:void(0)"
                                        onClick={handleShow}
                                    >
                                        Text Customer
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={handleEmailShow}
                                    >
                                        Email Customer
                                    </a> */}
                                    {/*  <a href="javascript:void(0)">
                                           Set Follow-Up
                                         </a> */}
                                    <input type="hidden" value={is_sold} />
                                    <label className="checkcontainer">
                                        Sold?
                                        <input
                                            type="checkbox"
                                            checked={is_sold == 1}
                                            onClick={() =>
                                                updateNoteSoldStatus("sold")
                                            }
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                    
                                )}

                                
                            </div>
                            <div className="col-md-6">
                                {/* leftside */}
                                {leadData == null ? (
                                    <div className="col-md-12">
                                        <div className="leftcustomers_detils">
                                            <h3></h3>
                                            <span></span>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div
                                                        className={`inner_matchtext shimmerEffect`}
                                                    >
                                                        <p>&nbsp;</p>
                                                    </div>

                                                    <div
                                                        className={`inner_matchtext shimmerEffect`}
                                                    >
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                    </div>

                                                    <div
                                                        className={`inner_matchtext shimmerEffect`}
                                                    >
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div
                                                        className={`inner_matchtext shimmerEffect`}
                                                    >
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={`notescustomer_boxdiv shimmerEffect`}
                                        >
                                            <h3></h3>
                                            <div className="note_div">
                                                <textarea
                                                    id="note"
                                                    name="note"
                                                    rows="4"
                                                    cols="50"
                                                    placeholder="Type note here..."
                                                    onChange={(e) => {
                                                        setNote(e.target.value);
                                                    }}
                                                ></textarea>
                                            </div>
                                            <Button className="btn btn-success">
                                                Save Note
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    // {leadData?.length > 0 ? (
                                    <div className="col-md-12">
                                        <div className="leftcustomers_detils">
                                            {/* <span> */}
                                            {/* {leadData?.date_submited} */}
                                            {/* ( {leadData?.day_on_site} ) */}
                                            {/* </span> */}

                                            <div className="main_detail_div">
                                                <div className="leaddetail_coverdiv">
                                                    <div className="inner_matchtext">
                                                        <h3>
                                                            {
                                                                leadData?.first_name
                                                            }{" "}
                                                            {
                                                                leadData?.last_name
                                                            }
                                                        </h3>
                                                        <img
                                                            className="taskiconset"
                                                            src={
                                                                AppImages.p_details
                                                            }
                                                        />

                                                        {Object.keys(
                                                            singleLeadData
                                                        ).map((item) =>
                                                            singleLeadData?.[
                                                                item
                                                            ] == null ||
                                                            singleLeadData?.[
                                                                item
                                                            ] == "" ? (
                                                                <></>
                                                            ) : (
                                                                <p>
                                                                    <span>
                                                                        {item}:
                                                                    </span>{" "}
                                                                    {
                                                                        singleLeadData?.[
                                                                            item
                                                                        ]
                                                                    }
                                                                </p>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="leaddetail_coverdiv">
                                                    <div className="inner_matchtext">
                                                        <img
                                                            className="taskiconset"
                                                            src={
                                                                AppImages.delivery_icon
                                                            }
                                                        />
                                                        {Object.keys(
                                                            singleLeadData1
                                                        ).map((item) =>
                                                            singleLeadData1?.[
                                                                item
                                                            ] == null ||
                                                            singleLeadData1?.[
                                                                item
                                                            ] == "" ? (
                                                                <></>
                                                            ) : item ==
                                                              "Drivers License Link" ? (
                                                                <p className="dlLinkText">
                                                                    <span>
                                                                        <label>
                                                                            {
                                                                                item
                                                                            }
                                                                            :
                                                                        </label>{" "}
                                                                        <a
                                                                            href={
                                                                                singleLeadData1?.[
                                                                                    item
                                                                                ]
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            {
                                                                                singleLeadData1?.[
                                                                                    item
                                                                                ]
                                                                            }
                                                                        </a>
                                                                    </span>{" "}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    <span>
                                                                        {item}:
                                                                    </span>{" "}
                                                                    {
                                                                        singleLeadData1?.[
                                                                            item
                                                                        ]
                                                                    }
                                                                </p>
                                                            )
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="leaddetail_coverdiv">
                                                    <div className="inner_matchtext">
                                                        <img
                                                            className="taskiconset"
                                                            src={
                                                                AppImages.address_icon
                                                            }
                                                        />
                                                        {Object.keys(
                                                            singleLeadData2
                                                        ).map((item) =>
                                                            singleLeadData2?.[
                                                                item
                                                            ] == null ||
                                                            singleLeadData2?.[
                                                                item
                                                            ] == "" ? (
                                                                <></>
                                                            ) : (
                                                                <p>
                                                                    <span>
                                                                        {item}:
                                                                    </span>{" "}
                                                                    {
                                                                        singleLeadData2?.[
                                                                            item
                                                                        ]
                                                                    }
                                                                </p>
                                                            )
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="leaddetail_coverdiv">
                                                    <div className="inner_matchtext">
                                                        <img
                                                            className="taskiconset"
                                                            src={
                                                                AppImages.employ_icon
                                                            }
                                                        />
                                                        {Object.keys(
                                                            singleLeadData3
                                                        ).map((item) =>
                                                            singleLeadData3?.[
                                                                item
                                                            ] == null ||
                                                            singleLeadData3?.[
                                                                item
                                                            ] == "" ? (
                                                                <></>
                                                            ) : (
                                                                <p>
                                                                    <span>
                                                                        {item}:
                                                                    </span>{" "}
                                                                    {
                                                                        singleLeadData3?.[
                                                                            item
                                                                        ]
                                                                    }
                                                                </p>
                                                            )
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        {driver_license_link  === "" ? (
                                                                <></>
                                                            ) : (
                                            <div className="col-md-6">
                                                <div className="cirtificate_cover_div">
                                                    <h3>Drivers License</h3>
                                                    <a
                                                        href={
                                                            driver_license_link
                                                        }
                                                        target="_blank"
                                                    >
                                                        {
                                                          driver_license_link
                                                        }
                                                    </a>
                                                </div>
                                            </div>
                                            )}

                                          {income_certificate  === "" ? (
                                                                <></>
                                                            ) : (
                                            <div className="col-md-6">
                                                <div className="cirtificate_cover_div">
                                                    <h3>Proof of income</h3>

                                                    <a
                                                        href={
                                                            income_certificate
                                                        }
                                                        target="_blank"
                                                    >
                                                        {income_certificate}
                                                    </a>
                                                </div>
                                            </div>
                                              )}
                                        </div>
                                    </div>
                                )}
                            </div>



                            <div className="col-md-6">
                            {leadData == null ? (  
                                <>
                                <div
                                            className={`notescustomer_boxdiv shimmerEffect`}
                                        >
                                            <h3></h3>
                                            <div className="note_div">
                                                <textarea
                                                    id="note"
                                                    name="note"
                                                    rows="4"
                                                    cols="50"
                                                    placeholder="Type note here..."
                                                    onChange={(e) => {
                                                        setNote(e.target.value);
                                                    }}
                                                ></textarea>
                                            </div>
                                            <Button className="btn btn-success">
                                                Save Note
                                            </Button>
                                        </div>
                                
                                 </>   
                                ) : (
                                    <>                
                                <div
                                    className={`textcustomer ${
                                        current_module_open === "text_customer"
                                            ? "visible"
                                            : "hidden"
                                    }`}
                                >
                                    <p>Text Customer</p>
                                    <div className="account_inputbox">
                                        <p>Message</p>
                                        <input type="hidden" value={number} />
                                        <div className="sendmessage">
                                            <textarea
                                                id="w3review"
                                                name="message"
                                                rows="2"
                                                cols="30"
                                                onChange={(e) => {
                                                    setMessage(e.target.value);
                                                }}
                                                placeholder="Type message here..."
                                            ></textarea>
                                        </div>
                                        <span className="text-danger">
                                            {err_phone ? err_phone : null}
                                        </span>

                                        <Button
                                            className="btn btn-success"
                                            onClick={() => sendMessage()}
                                        >
                                            Send
                                            {isLoading == true ? (
                                                <Loader />
                                            ) : null}
                                        </Button>
                                    </div>
                                </div>

                                <div
                                    className={`emailcustomer ${
                                        current_module_open === "email_customer"
                                            ? "visible"
                                            : "hidden"
                                    }`}
                                >
                                    <div className="account_inputbox">
                                        <p>Email Customer</p>
                                        <div className="sendmessage">
                                            <div className="subject_input">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    onChange={(e) => {
                                                        setSubject(
                                                            e.target.value
                                                        );
                                                    }}
                                                    placeholder="Subject"
                                                />
                                                <span className="text-danger">
                                                    {err_subject
                                                        ? err_subject
                                                        : null}
                                                </span>
                                                <textarea
                                                    id="w3review"
                                                    name="mail_message"
                                                    rows="4"
                                                    cols="50"
                                                    onChange={(e) => {
                                                        setMailMessage(
                                                            e.target.value
                                                        );
                                                    }}
                                                    placeholder="Type message here..."
                                                ></textarea>
                                                <span className="text-danger">
                                                    {err_mail_message
                                                        ? err_mail_message
                                                        : null}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        className="btn btn-success"
                                        onClick={() => sendMail()}
                                    >
                                        Send
                                        {isLoading == true ? <Loader /> : null}
                                    </Button>
                                </div>

                                <div
                                    className={`addtaskcustomer ${
                                        current_module_open === "addtask"
                                            ? "visible"
                                            : "hidden"
                                    }`}
                                >
                                    <div className="maintaskdiv">
                                        <div className="task_borderdiv">
                                            <h2>Add a Task</h2>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>Description*</label>
                                                    <input
                                                        className="form-control custdescription"
                                                        type="text"
                                                        name="description"
                                                        onChange={(e) => {
                                                            setSelectedDescription(
                                                                e.target.value
                                                            );
                                                        }}
                                                        placeholder="Description"
                                                        value={
                                                            selectedDescription
                                                        }
                                                    />

                                                    <span className="text-danger">
                                                        {err_description
                                                            ? err_description
                                                            : null}
                                                    </span>
                                                </div>

                                                <div className="col-md-6">
                                                    <label>Due*</label>
                                                    <br />

                                                    <DatePicker
                                                        className="form-control datepickercust"
                                                        selected={selectedDate}
                                                        placeholderText="Select a date"
                                                        onChange={
                                                            handleDateChange
                                                        }
                                                        showYearDropdown // Enable year dropdown
                                                        scrollableYearDropdown // Make the year dropdown scrollable
                                                        yearDropdownItemNumber={
                                                            12
                                                        } // Number of years displayed in the dropdown
                                                        showMonthDropdown // Enable month dropdown
                                                    />

                                                    <br />
                                                    <span className="text-danger">
                                                        {err_due
                                                            ? err_due
                                                            : null}
                                                    </span>
                                                </div>

                                                <div className="col-md-3">
                                                    <label>Time</label>
                                                    <select
                                                        className="form-control hourdrop"
                                                        value={
                                                            selectedHourValue
                                                        }
                                                        onChange={
                                                            handleHourChange
                                                        }
                                                    >
                                                        {options}
                                                    </select>

                                                    <span className="text-danger">
                                                        {err_hour
                                                            ? err_hour
                                                            : null}
                                                    </span>
                                                </div>

                                                <div className="col-md-3">
                                                    <label> &nbsp; </label>
                                                    <select
                                                        className="form-control mindrop"
                                                        value={selectedMinute}
                                                        onChange={
                                                            handleMinuteChange
                                                        }
                                                    >
                                                        {minoptions}
                                                    </select>

                                                    <span className="text-danger">
                                                        {err_minut
                                                            ? err_minut
                                                            : null}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="addtask_btndiv">
                                            <Button
                                                className="btn btn-success"
                                                onClick={() =>{if(!isLoading) AddTask()}}
                                            >
                                                Add Task
                                                {isLoadingtask == true ? (
                                                    <Loader />
                                                ) : null}
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="pendingtask_mandiv">
                                        <h5>Pending Task</h5>
                                        <table className="custometable">
                                            <tbody>
                                                {taskData == null ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {taskData?.map(
                                                            (item) => (
                                                                <TaskCell
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    item={item}
                                                                    onCompleteClick={(
                                                                        item
                                                                    ) =>
                                                                        taskUpdateLead(
                                                                            item,
                                                                            "completed"
                                                                        )
                                                                    }
                                                                    onDelete={(
                                                                        item
                                                                    ) =>
                                                                        taskUpdateLead(
                                                                            item,
                                                                            "deleted"
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="notescustomer_boxdiv">
                                    <h3>Notes</h3>
                                    <div className="note_div">
                                        <textarea
                                            id="note"
                                            name="note"
                                            rows="4"
                                            cols="50"
                                            value={note}
                                            placeholder="Type note here..."
                                            onChange={(e) => {
                                                setNote(e.target.value);
                                            }}
                                        ></textarea>

                                        <span className="text-danger">
                                            {err_note}
                                        </span>
                                    </div>
                                    <Button
                                        className="btn btn-success"
                                        onClick={() =>
                                            updateNoteSoldStatus("note")
                                        }
                                    >
                                        Save Note
                                        {isLoading == true ? <Loader /> : null}
                                    </Button>
                                    {/* <p>
                                            The customer has only been employed
                                            for 1 month. If they cannot find a
                                            co-signer or down payment, will set
                                            a follow-up for 2 months.
                                        </p> */}
                                </div>
                                </>
                                    )}
                            </div>



                            {/* <div className={`textcustomer ${current_module_open === 'text_customer' ? 'visible' : 'hidden'}`}>
                                   
                                    <p>Text Customer</p>
                                    <div className="account_inputbox">
                                    <p>Message</p>
                                    <input type="hidden" value={number} />
                                    <div className="sendmessage">
                                        <textarea
                                            id="w3review"
                                            name="message"
                                            rows="2"
                                            cols="30"
                                            onChange={(e) => {
                                                setMessage(e.target.value);
                                            }}
                                            placeholder="Type message here..."
                                        ></textarea>
                                    </div>
                                    <span className="text-danger">
                                        {err_phone ? err_phone : null}
                                    </span>

                                    <Button
                                className="btn btn-success"
                                onClick={() => sendMessage()}
                            >
                                Send
                                {isLoading == true ? <Loader /> : null}
                            </Button>
                                </div>
                                    </div>
                                    
                        
                         <div className={`emailcustomer ${current_module_open === 'email_customer' ? 'visible' : 'hidden'}`}>
                                    <div className="account_inputbox">
                                    <p>Email Customer</p>
                                    <div className="sendmessage">
                                        <div className="subject_input">
                                            <input
                                                type="text"
                                                name="email"
                                                onChange={(e) => {
                                                    setSubject(e.target.value);
                                                }}
                                                placeholder="Subject"
                                            />
                                            <span className="text-danger">
                                                {err_subject
                                                    ? err_subject
                                                    : null}
                                            </span>
                                            <textarea
                                                id="w3review"
                                                name="mail_message"
                                                rows="4"
                                                cols="50"
                                                onChange={(e) => {
                                                    setMailMessage(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="Type message here..."
                                            ></textarea>
                                            <span className="text-danger">
                                                {err_mail_message
                                                    ? err_mail_message
                                                    : null}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                className="btn btn-success"
                                onClick={() => sendMail()}
                            >
                                Send
                                {isLoading == true ? <Loader /> : null}
                            </Button>
                                    </div>

                                    <div className={`addtaskcustomer ${current_module_open === 'addtask' ? 'visible' : 'hidden'}`}>

                                    <div className="maintaskdiv">
                                <h2>Add a Task</h2>

                                <div className="row">
                                    <div className="col-md-12">
                                        <input
                                            className="form-control custdescription"
                                            type="text"
                                            name="description"
                                            onChange={(e) => {
                                                setSelectedDescription(
                                                    e.target.value
                                                );
                                            }}
                                            placeholder="Description"
                                            value={selectedDescription}
                                        />

                                        <span className="text-danger">
                                            {err_description
                                                ? err_description
                                                : null}
                                        </span>
                                    </div>

                                    <div className="col-md-6">
                                        <label>Due*</label>
                                        <br />

                                        <DatePicker
                                            className="form-control datepickercust"
                                            selected={selectedDate}
                                            placeholderText="Select a date"
                                            onChange={handleDateChange}
                                            showYearDropdown // Enable year dropdown
                                            scrollableYearDropdown // Make the year dropdown scrollable
                                            yearDropdownItemNumber={12} // Number of years displayed in the dropdown
                                            showMonthDropdown // Enable month dropdown
                                        />

                                        <br />
                                        <span className="text-danger">
                                            {err_due ? err_due : null}
                                        </span>
                                    </div>

                                    <div className="col-md-3">
                                        <label>Time</label>
                                        <select
                                            className="form-control hourdrop"
                                            value={selectedHourValue}
                                            onChange={handleHourChange}
                                        >
                                            {options}
                                        </select>

                                        <span className="text-danger">
                                            {err_hour ? err_hour : null}
                                        </span>
                                    </div>

                                    <div className="col-md-3">
                                        <label> &nbsp; </label>
                                        <select
                                            className="form-control mindrop"
                                            value={selectedMinute}
                                            onChange={handleMinuteChange}
                                        >
                                            {minoptions}
                                        </select>

                                        <span className="text-danger">
                                            {err_minut ? err_minut : null}
                                        </span>
                                    </div>

                                    <div className="col-md-12">
                                        <Button
                                            className="btn btn-success"
                                            onClick={() => AddTask()}
                                        >
                                            Add Task
                                            {isLoadingtask == true ? (
                                                <Loader />
                                            ) : null}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div>
                                        <h5>Pending Task</h5>
                                        <table className="custometable">
                                            <tbody>
                                                {taskData == null ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        {taskData?.map(
                                                            (item) => (
                                                                <TaskCell
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    item={item}
                                                                    onCompleteClick={(
                                                                        item
                                                                    ) =>
                                                                        taskUpdateLead(
                                                                            item,
                                                                            "completed"
                                                                        )
                                                                    }
                                                                    onDelete={(
                                                                        item
                                                                    ) =>
                                                                        taskUpdateLead(
                                                                            item,
                                                                            "deleted"
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                                    </div> */}
                        </div>
                    </div>
                </div>
                <Footer />

                <span style={{ marginTop: "100px" }}>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        style={{ marginTop: "40px" }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Text Customer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                <div className="account_inputbox">
                                    <p>Message</p>
                                    <input type="hidden" value={number} />
                                    <div className="sendmessage">
                                        <textarea
                                            id="w3review"
                                            name="message"
                                            rows="2"
                                            cols="30"
                                            onChange={(e) => {
                                                setMessage(e.target.value);
                                            }}
                                            placeholder="Type message here..."
                                        ></textarea>
                                    </div>
                                    <span className="text-danger">
                                        {err_phone ? err_phone : null}
                                    </span>
                                </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                className="btn btn-success"
                                onClick={() => sendMessage()}
                            >
                                Send
                                {isLoading == true ? <Loader /> : null}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </span>

                <span style={{ marginTop: "100px" }}>
                    <Modal
                        show={emailModelshow}
                        onHide={handleEmailClose}
                        style={{ marginTop: "40px" }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Email Customer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                <div className="account_inputbox">
                                    <div className="sendmessage">
                                        <div className="subject_input">
                                            <input
                                                type="text"
                                                name="email"
                                                onChange={(e) => {
                                                    setSubject(e.target.value);
                                                }}
                                                placeholder="Subject"
                                            />
                                            <span className="text-danger">
                                                {err_subject
                                                    ? err_subject
                                                    : null}
                                            </span>
                                            <textarea
                                                id="w3review"
                                                name="mail_message"
                                                rows="4"
                                                cols="50"
                                                onChange={(e) => {
                                                    setMailMessage(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="Type message here..."
                                            ></textarea>
                                            <span className="text-danger">
                                                {err_mail_message
                                                    ? err_mail_message
                                                    : null}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleEmailClose}
                            >
                                Close
                            </Button>
                            <Button
                                className="btn btn-success"
                                onClick={() => sendMail()}
                            >
                                Send
                                {isLoading == true ? <Loader /> : null}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </span>
            </div>
            <ToastContainer />
        </>
    );
};

export default LeadDetail;

const TaskCell = (props) => {
    return (
        <>
            <tr>
                <td>
                    {moment(
                        props.item.full_due_date_time,
                        "yyyy-MM-DD HH:mm:ss"
                    ).format("DD MMM, YYYY")}
                </td>
                <td>
                    {moment(
                        props.item.full_due_date_time,
                        "yyyy-MM-DD HH:mm:ss"
                    ).format("hh:mm A")}
                </td>
                <td>{props.item.description}</td>
                <td>
                    <div className="actionicon">
                        <button
                            onClick={() => {
                                props?.onCompleteClick(props?.item);
                            }}
                        >
                            <img
                                className="taskiconset"
                                src={AppImages.completedtask.default}
                            />
                        </button>
                    </div>
                    <div className="actionicon">
                        <button
                            onClick={() => {
                                props?.onDelete(props?.item);
                            }}
                        >
                            <img
                                className="taskiconset"
                                src={AppImages.closetask.default}
                            />
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
};
