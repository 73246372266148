const NoLead = props => {
    const numbers = [1, 2, 3];

    return (
        numbers.map((number,index) =>
            
                        <div>        
						<ul key={index}>
							<li className={`${props?.shimmer}`}><span>November 11,2022</span></li>
							<li className={`${props?.shimmer}`}><span>November 11,2022</span></li>
							<li className={`${props?.shimmer}`}><span>November 11,2022</span></li>						
						</ul>						
                        </div>
					
        
        )

    );
}
export default NoLead;